'use strict';

import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../common/handle-input';

export default class Step4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sites:props.getStore().sites,
            company:props.getStore().company,
            username:props.getStore().username,
            name:"",
            description:"",
            address:"",
            area:"",
            province:"",
            suburd:"",
            raduis:100,
            latitude:"",
            longitude:"",
            startwork:"",
            endwork:'',
            NumberOfWorker:0,
        };

        this.store = [];

        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);
        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.addSite = this.addSite.bind(this);
        this.deleteSite = this.deleteSite.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }


    // not required as this component has no forms or user entry
    // isValidated() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        this.setState(Object.assign(userInput, validateNewInput));

        /*// if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getStore().startdate !== userInput.startdate ||
                this.props.getStore().enddate !== userInput.enddate ) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }*/

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput));
        this.props.updateStore(userInput);
    }

    _validateData(data) {
        /**
         * loop through the rates and find if any element have zero value and make validation to true
         */

        let boo = true;

        return  {
            sitesVal: boo, // required: anything besides N/A
        }
    }

    _validationErrors(val) {

        const errMsgs = {
            sitesValMsg: val.sitesVal ? '' : 'A valid start date of contract  is required',
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            sites: this.state.sites,
        };
    }

    deleteSite(e,row){
       // let str=JSON.stringify(e);
        console.log("DELETE ROW TABLe > ",e,row);
        let tmp = [];
        const data = this.state.sites;
        for(let i in data){
            const row = data[i];
            if(row.name !==e[0]){
                tmp.push(row);
            }
        }

        let state = this.state;
        state.sites = tmp;
        this.setState(tmp);
        this.validationCheck();

    }
    addSite(row){

        row.NumberOfWorker = parseInt(row.NumberOfWorker);
        row.raduis = parseInt(row.raduis);

        let newRowStr = "";
        for (const prop in row) {
            newRowStr += prop + ': ' + row[prop] + ' \n';
        }
        alert('The new site is:\n ' + newRowStr);

        let tmp = this.state;
        tmp.sites.push(row);
        this.setState(tmp);
        this.validationCheck();
    }

    render() {
        let _this = this;
        let data = [];
        if(typeof this.state.sites !=="undefined"){
            data = this.state.sites;
        }

        const selectRowProp = {
            mode: 'radio',
            clickToSelect: true  // enable click to select
        };

        const options = {
            defaultSortName: 'name',
            defaultSortOrder: 'desc',
            paginationSize: 5,
           /* actionViewEmployee: this.actionViewEmployee,*/
            addSite:this.addSite,
            deleteSite:this.deleteSite,
            onRowClick: function (row, columnIndex, rowIndex, e) {
                console.log(`You click row id: ${row.EmployeeCode}, column index: ${columnIndex}, row index: ${rowIndex}`);
               /* this.actionViewEmployee(row);*/
                console.log(e);
            },
            onRowDoubleClick: function (row, e) {
                alert(`You double click row id: ${row.id}`);
                console.log(e);
            },
            clearSearch: true,
           /* clearSearch: true,*/
           /* searchField: (props) => (<MySearchField {...props}/>),*/
            onAddRow: this.addSite,
            onDeleteRow: this.deleteSite,
            /*insertModal:this.createCustomModal,*/
        };



        return (
            <div className="step step4">
                <div className="row">
                    <form id="Form" className="form-horizontal">
                        <div className="form-group">
                            <label className="col-md-12 control-label">
                                <h1 className={"companyList"}>Step 4: Site</h1>
                            </label>


                            <div className="Card-Container" >
                                <BootstrapTable
                                    data={data}
                                    striped={false}
                                    hover={true}
                                    pagination
                                    selectRow={selectRowProp}
                                    options={options}
                                    insertRow deleteRow exportCSV
                                    search
                                >
                                    <TableHeaderColumn dataField="sitename" isKey={true} dataSort={true} >Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField="description" >Description</TableHeaderColumn>
                                    <TableHeaderColumn dataField="address" >Address</TableHeaderColumn>
                                    <TableHeaderColumn dataField="area" >Area</TableHeaderColumn>
                                    <TableHeaderColumn dataField="province" >Province</TableHeaderColumn>
                                    <TableHeaderColumn dataField="city" >City</TableHeaderColumn>
                                    <TableHeaderColumn dataField="suburb" >Suburb</TableHeaderColumn>
                                    <TableHeaderColumn dataField="raduis" >Raduis</TableHeaderColumn>
                                    <TableHeaderColumn dataField="latitude" >Latitude</TableHeaderColumn>
                                    <TableHeaderColumn dataField="longitude" >Longitude</TableHeaderColumn>
                                    <TableHeaderColumn dataField="startwork" >Start Work</TableHeaderColumn>
                                    <TableHeaderColumn dataField="endwork" >End Work</TableHeaderColumn>
                                    <TableHeaderColumn dataField="NumberOfWorker" >Workers</TableHeaderColumn>


                                </BootstrapTable>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const OptionProvince =[
    {label:"Western Cape",val:"western cape"},
    {label:"Guateng",val:"guateng"},
];

const OptionMunicipality =[
    {label:"City of cape town",val:"cape town"},
    {label:"Stellembosch",val:"stellembosch"},
];

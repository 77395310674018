import React from 'react';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {List, ListItem} from 'material-ui/List';
import Subheader from 'material-ui/Subheader';
import Divider from 'material-ui/Divider';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import DatePicker from 'material-ui/DatePicker';
import {Tabs, Tab} from 'material-ui/Tabs';
import Slider from 'material-ui/Slider';
import Badge from 'material-ui/Badge';
import moment from 'moment';


import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';

import MultipleDatePicker from 'react-multiple-datepicker';

/**
 * LEt try this to make it work
 */

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const serverEpwp = eConfig.serverEpwp;

const styles = {
    customWidth: {
        width: 150,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};
const style = {
    margin: 12,
};


class MyContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},

            selectedContract: "",
            selectedSite: "",
            selectedYear:"",
            selectedMonth:"",
            selectedDate: "",
            selectedDates:[],

            selectedSiteReport: "",
            selectedPaycycleReport: "",

            Contracts: [],
            Sites: [],
            Employees: [],
            Clocks: [],
            ClockBasicReport: {},
            PayCycles: [],
            selectedEmployees: {},
            selected: [1],
            startDate: "",
            endDate: "",

            empClocks: [],
            empCurrentViewClock: {},
            isViewClock: false,

            downloadLink:"",

        };


        this.handelCheckEmployee = this.handelCheckEmployee.bind(this);
        this.handleChangeClockDate = this.handleChangeClockDate.bind(this);
        this.handleChangePaycycle = this.handleChangePaycycle.bind(this);
        this.handleChangeReportDate = this.handleChangeReportDate.bind(this);
        this.handleChangeContract = this.handleChangeContract.bind(this);
        this.handleChangeSite = this.handleChangeSite.bind(this);
        this.handleChangeSiteReport = this.handleChangeSiteReport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.fetchSites = this.fetchSites.bind(this);
        this.fetchEmployee = this.fetchEmployee.bind(this);
        this.fetchDailyClock = this.fetchDailyClock.bind(this);

        this.handleRowSelection = this.handleRowSelection.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.submitClock = this.submitClock.bind(this);


        this.renderContractOption = this.renderContractOption.bind(this);
        this.renderSiteOption = this.renderSiteOption.bind(this);
        this.renderSiteOptionReport = this.renderSiteOptionReport.bind(this);
        this.renderAvailableEmployeeList = this.renderAvailableEmployeeList.bind(this);
        this.renderDayClock = this.renderDayClock.bind(this);
        this.renderDayClockReport = this.renderDayClockReport.bind(this);

        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);
        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);
        this.isDateBetween = this.isDateBetween.bind(this);

        this.searchClock = this.searchClock.bind(this);
        this.fetchPayCycle = this.fetchPayCycle.bind(this);
        this.renderPaycycleOption = this.renderPaycycleOption.bind(this);
        this.showClock = this.showClock.bind(this);
        this.openShowClock = this.openShowClock.bind(this);
        this.closeShowClock = this.closeShowClock.bind(this);
        this.renderShowClock = this.renderShowClock.bind(this);
        this.showClockContent = this.showClockContent.bind(this);

        this.convertDateFormat_YYYYMMDD = this.convertDateFormat_YYYYMMDD.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.openDownloadLink = this.openDownloadLink.bind(this);


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        tmp.startDate = this.convertMomentDateToStringISO(moment());
        tmp.endDate = this.convertMomentDateToStringISO(moment());
        this.setState(tmp);

        window.$('.mui-body-table').css('overflow-x', 'auto');


        /**
         * Load default from api
         */
        this.fetchContracts();
        this.fetchSites();
        this.fetchEmployee();
        this.fetchPayCycle();
        //this.fetchDailyClock();
    }

    handleInputChange(e,index,val,key){
        this.handleChange(key, val)

        console.log("handleInputChange > ",key," > ",val," > ",e)
    }

    fetchPayCycle() {
        //  api/contracts/contract/paycycle-detail/list-contract
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/paycycle-detail/list-contract";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.contractName = storeContract;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["PayCycles"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchPayCycle List response ", responseData);
            });
    }

    searchClock() {
        /** Initialize clocks array **/
        var tmp = this.state;
        tmp["Clocks"] = [];
        this.setState(tmp);


        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/clock/report/basic";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = storeContract;
        hub.SiteName = this.state.selectedSiteReport;
        hub.start = this.state.startDate;
        hub.end = this.state.endDate;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Clocks"] = responseData.clocks;
                tmp["ClockBasicReport"] = responseData.report;
                this.setState(tmp);
                console.log("+++++>searchClock List response ", responseData);
            });
    }

    fetchSites() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/site/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Sites"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchSites List response ", responseData);
            });
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Contracts"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchContracts List response ", responseData);
            });
    }

    fetchEmployee() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Employees"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchEmployee List response ", responseData);
            });
    }

    fetchDailyClock() {
        let company = this.state.company;
        let endpoint = "/api/clock/list/company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                let dateMoment = moment();
                let dt = this.convertMomentDateToStringISO(dateMoment);
                var tmp = this.state;
                tmp.Clocks = responseData;
                this.setState(tmp);
                console.log("+++++>fetchDailyClock List response ", responseData);
            });
    }

    handleChange(key, val) {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("-->handleChange > ", key, " > ", val);
    }

    handleChangeContract(e, index, value) {
        let key = "selectedContract";
        this.handleChange(key, value)
    }

    handleChangeSite(e, index, value) {
        let key = "selectedSite";
        this.handleChange(key, value)
    }

    handleChangeSiteReport(e, index, value) {
        let key = "selectedSiteReport";
        this.handleChange(key, value)
    }

    handleChangePaycycle(e, index, value) {
        let arr = value.split("--");
        const startDate = arr[1];
        const endDate = arr[2];
        this.handleChange("startDate", startDate)
        this.handleChange("endDate", endDate);
        this.handleChange("selectedPaycycleReport", value);


    }

    handleChangeClockDate(e, eDate, phaseDate) {
        let key = "selectedDate";
        let val = this.convertMomentDateToStringISO(eDate);
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("handleChangeClockDate >> ", val, " > ", phaseDate);
    }

    handleChangeReportDate(e, eDate, phaseDate) {
        let key = phaseDate;
        let val = this.convertMomentDateToStringISO(eDate);
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("handleChangeReportDate >> ", val, " > ", phaseDate);
    }

    handelCheckEmployee(e, action, empCode) {
        let tmp = this.state;
        if (action) {
            tmp.selectedEmployees[empCode] = true;
        }
        if (!action) {
            delete tmp.selectedEmployees[empCode];
        }
        this.setState(tmp);
    }

    isSelected(index) {
        return this.state.selected.indexOf(index) !== -1;
    }

    handleRowSelection(selectedRows) {
        this.setState({
            selected: selectedRows,
        });
    }

    convertStringDateIntoMomentDate(dateString) {
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    convertMomentDateToStringISO(dt) {
        let momentObj = moment(dt);
        let momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        return momentString
        // let dttt = dt.format("YYYY-MM-DD");
        // console.log("!!!!!!!!!!---> ",momentString);
        // return dt.toISOString().slice(0, 10);
    }

    isDateBetween(target, start, end) {

        var startDate = moment(start)
            , endDate = moment(end)
            , date = moment(target)
        ;

        console.log("isDateBetween ####--> ", startDate.toString(), endDate.toString(), date.toString(), date.isBetween(startDate, endDate));

        return date.isBetween(startDate, endDate); // false
    }

    renderContractOption() {
        return this.state.Contracts.map((row,index) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name} key={index}/>
            )
        })
    }

    renderPaycycleOption() {
        let list = [];
        for (let i in this.state.PayCycles) {
            let row = this.state.PayCycles[i];
            let o = {
                val: row.Period + "--" + row.StartDate + "--" + row.EndDate + "--" + row.PayDate,
                label: "" + row.Period + ". -> Start: " + convertDateFormat_DDMMYYYY(row.StartDate) + " -> End: " + convertDateFormat_DDMMYYYY(row.EndDate) + " -> Pay date: " + convertDateFormat_DDMMYYYY(row.PayDate),
            };
            list.push(o)
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.val} primaryText={row.label}/>
            )
        });
    }

    renderSiteOption() {
        let list = [];
        for (let i in this.state.Sites) {
            let row = this.state.Sites[i];
            if (row.ContractName === this.state.selectedContract) {
                list.push({Name: row.SiteName})
            }
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name}/>
            )
        })
    }

    renderSiteOptionReport() {
        /**
         * Get under ground contract
         * @type {Array}
         */
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");

        let list = [];
        for (let i in this.state.Sites) {
            let row = this.state.Sites[i];
            if (row.ContractName === storeContract) {
                list.push({Name: row.SiteName})
            }
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name}/>
            )
        })
    }

    renderAvailableEmployeeList() {
        let contract = this.state.selectedContract;
        let site = this.state.selectedSite;
        let list = [];
        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            if (row.Contract === contract && row.Site === site) {
                //todo enable this in production (make sure that only active employee are rendered)
                //if (row.Status === "trial") {
                list.push({
                    code: row.EmployeeCode,
                    name: row.EmployeeCode + " -- " + row.Name + " " + row.Surname + " -- " + row.Designation,
                });
                //}

            }

        }

        return list.map((row) => {
            return (
                <ListItem primaryText={row.name} leftCheckbox={<Checkbox name={row.name}
                                                                         onCheck={(e, isInputChecked) => this.handelCheckEmployee(e, isInputChecked, row.code)}/>}/>
            )
        })

        //EmployeeCode: "ecare-10013" EmployeeIDNo: "9876543210123"
        // <ListItem primaryText="Notifications" leftCheckbox={<Checkbox />} />
    }

    openShowClock() {
        let tmp = this.state;
        tmp.isViewClock = true;
        this.setState(tmp);
    }

    closeShowClock() {
        let tmp = this.state;
        tmp.isViewClock = false;
        this.setState(tmp);
    }

    showClock(obj, typeOfDay) {
        //alert("Request for :" + typeOfDay + " Is not implemented yet");
        let ls = [];
        for (let i in this.state.Clocks) {
            let row = this.state.Clocks[i];
            //make sure that we are dealing with only selected employee
            if (row.EmployeeCode === obj.EmployeeCode) {
                if (typeOfDay === "week") {
                    if (row.ClockDay === "normal") {
                        ls.push(row);
                    }
                }
                if (typeOfDay === "weekend") {
                    if (row.ClockDay === "sunday" || row.ClockDay === "saturday") {
                        ls.push(row);
                    }
                }

                if (typeOfDay === "pph") {
                    if (row.ClockDay !== "normal" && row.ClockDay !== "sunday" && row.ClockDay !== "saturday") {
                        ls.push(row);
                    }
                }
                if (typeOfDay === "all") {
                    ls.push(row);
                }
            }
        }

        //let update our report data
        let tmp = this.state;
        tmp.empClocks = ls;
        tmp.empCurrentViewClock = obj;
        this.setState(tmp);

        this.openShowClock();
    }

    showClockContent() {
        let ls = this.state.empClocks;
        let index = 0;
        return (
            <table className="table"
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th width='20px'>#</th>
                    <th width='200px'>ClockDate</th>
                    <th width='200px'>DayType</th>
                    <th width='200px'>Site</th>
                    <th width='50px'>Period</th>
                    <th width='50px'>Hours</th>
                    <th width='200px'>Rate/Type</th>
                    <th width='200px'>Cost</th>
                    <th width='200px'>Pay Date</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='20px'>{index}</td>
                                <td width='200px'>{convertDateFormat_DDMMYYYY(row.ClockDate)}</td>
                                <td width='200px'>{row.ClockDay}</td>
                                <td width='200px'>{row.SiteName}</td>
                                <td width='50px'>{row.PayPeriodNumber}</td>
                                <td width='50px'>{row.HoursWorked}</td>
                                <td width='200px'>{"R " + row.CostRateEmployee + "/" + row.RateType[0]}</td>
                                <td width='200px'>{"R " + row.CostAmountEmployee}</td>
                                <td width='200px'>{row.PayDate}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        )
    }

    renderShowClock() {

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowClock}
            />,
        ];


        let ls = this.state.empClocks;
        let index = 0;
        return (
            <Dialog
                title={"Show Clocks Employee: " + this.state.empCurrentViewClock.EmployeeCode + " -> " + this.state.empCurrentViewClock.Fullname}
                actions={actions}
                modal={false}
                open={this.state.isViewClock}
                onRequestClose={this.openShowClock}
                autoScrollBodyContent={true}
            >

                <br/>

                <div className="col-lg-12">
                    {this.showClockContent()}
                </div>


            </Dialog>



        )

    }

    renderDayClockReport() {
        let ls = [];
        for (let i in this.state.ClockBasicReport) {
            let row = this.state.ClockBasicReport[i];
            ls.push(row);
        }
        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Clocking</p>)
        }

        let index = 0;


        return (
            <table className="table "
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th width='20px'>#</th>
                    <th width='200px'>Code</th>
                    <th width='400px'>Name</th>
                    <th width='150px'>Role</th>
                    <th width='200px'>Site</th>
                    <th width='50px'>WKDAY</th>
                    <th width='50px'>W/END</th>
                    <th width='50px'>PPH</th>
                    <th width='200px'>Hours</th>
                    <th width='200px'>GrossPay</th>
                    <th width='200px'>UIF</th>
                    <th width='200px'>NetPay</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='20px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "all")
                                    }}>
                                        <i className="badge">{index}</i>
                                    </a>

                                </td>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.Name}</td>
                                <td width='150px'>{row.Designation}</td>
                                <td width='200px'>{row.Site}</td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "week")
                                    }}>
                                        <Badge
                                            badgeContent={row.WeekDay}
                                            primary={true}
                                        >
                                        </Badge>
                                    </a>
                                </td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "weekend")
                                    }}>
                                        <Badge
                                            badgeContent={row.WeekendDay}
                                            primary={true}
                                        >
                                        </Badge>
                                    </a>
                                </td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "pph")
                                    }}>
                                        <Badge
                                            badgeContent={row.PphDdays}
                                            secondary={true}
                                        >
                                        </Badge>
                                    </a>

                                </td>
                                <td width='200px'>{row.Hours}</td>
                                <td width='200px' align="left">{"R " + row.GrossPay.toFixed(3)}</td>
                                <td width='200px' align="left">{"R " + row.UIF.toFixed(3)}</td>
                                <td width='200px' align="left">{"R " + row.NetPay.toFixed(3)}</td>

                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        )

    }

    renderDayClock() {
        let myStyle = {
            overflow: 'visible',
        };
        let index = 0;

        let ls = [];

        for (let i in this.state.Clocks) {
            let row = this.state.Clocks[i];
            /*if (this.isDateBetween(row.ClockDate, this.state.startDate, this.state.endDate)) {
                ls.push(row);
            }*/
            ls.push(row);
        }

        console.log("YY--> NUMBER OF CLOCKED > ", ls);

        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Clocking</p>)
        }

        return (
            <table className="table"
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th width='20px'>#</th>
                    <th width='200px'>Name</th>
                    <th width='200px'>Code</th>
                    <th width='200px'>Contract</th>
                    <th width='200px'>Site</th>
                    <th width='50px'>Period</th>
                    <th width='50px'>Hours</th>
                    <th width='200px'>ClockDate</th>
                    <th width='200px'>Rate/Type</th>
                    <th width='200px'>Cost</th>
                    <th width='200px'>Pay Date</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='20px'>{index}</td>
                                <td width='200px'>{row.Fullname}</td>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.ContractName}</td>
                                <td width='200px'>{row.SiteName}</td>
                                <td width='50px'>{row.PayPeriodNumber}</td>
                                <td width='50px'>{row.HoursWorked}</td>
                                <td width='200px'>{row.ClockDate}</td>
                                <td width='200px'>{"R " + row.CostRateEmployee + "/" + row.RateType}</td>
                                <td width='200px'>{"R " + row.CostAmountEmployee}</td>
                                <td width='200px'>{row.PayDate}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        )

    }

    submitClock() {
        /**<MenuItem value={row.Name} primaryText={row.Name}/>
         * Validation
         * */
        let emps = [];
        for (let i in this.state.selectedEmployees) {
            emps.push(i);
        }
        let contract = this.state.selectedContract;
        let site = this.state.selectedSite;
        let year = this.state.selectedYear;
        let month = this.state.selectedMonth;

        let company = this.state.company;
        let endpoint = "/api/services/payslip/generate";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = this.state.selectedContract;
        hub.SiteName = site;
        hub.Year = year;
        hub.Month = month;
        hub.CostLoacation = "Sewende Laan-Valhalla";

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                ///window.location.reload();
                this.setState({downloadLink:responseData.download});
                console.log("+++++>submitClock response ", responseData," > ", this.state);
                document.title = "PMIS-Payslip";
                window.open(this.state.downloadLink, '_blank');
                alert("Thank you your payslip is now read for download!");

            });


    }

    convertDateFormat_YYYYMMDD(datesIn) {
        let dates=[];
        for(let i in datesIn){
            let item = datesIn[i];
            //let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
            let mydate = moment(item ).format("YYYY-MM-DD");
            dates.push(mydate);
        }
        console.log("convertDateFormat_YYYYMMDD > ",dates);
        return dates;
    }

    openDownloadLink=()=>{
        console.log(("this.state.downloadLink > ",this.state.downloadLink));

        window.open(this.state.downloadLink, '_blank');
    }

    render() {
        let myStyle = {
            overflow: 'visible',
        };
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="CLocking" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">

                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9 ">

                            <Paper style={style} zDepth={1}>
                                <div className="centerContent">


                                    <div className="row">
                                        <div className="col col-lg-6">
                                            <h4>
                                                <label style={{color: "#03A9F4"}}>Payslip Request</label>
                                            </h4>
                                            <Divider/>
                                            <form onSubmit={this.submitClock}>
                                            <SelectField
                                                floatingLabelText="Contract"
                                                name="selectedContract"
                                                onChange={this.handleChangeContract}
                                                value={this.state.selectedContract}
                                                autoWidth={true}
                                                required={true}
                                            >
                                                {this.renderContractOption()}
                                            </SelectField>
                                            <br/>
                                            <SelectField
                                                floatingLabelText="Site"
                                                name="selectedSite"
                                                onChange={this.handleChangeSite}
                                                value={this.state.selectedSite}
                                                required={true}
                                            >
                                                {this.renderSiteOption()}
                                            </SelectField>
                                            <br/>

                                            <SelectField
                                                floatingLabelText="Year"
                                                name="selectedYear"
                                                onChange={(e,index,value)=>this.handleInputChange(e, index, value,"selectedYear")}
                                                value={this.state.selectedYear}
                                                style={{width:"25%"}}
                                                required={true}
                                            >
                                                <MenuItem value={2018} primaryText={"2018"}/>

                                            </SelectField>
                                            &nbsp; &nbsp; &nbsp; &nbsp;

                                            <SelectField
                                                floatingLabelText="Month"
                                                name="selectedMonth"
                                                onChange={(e,index,value)=>this.handleInputChange(e, index, value,"selectedMonth")}
                                                value={this.state.selectedMonth}
                                                style={{width:"25%"}}
                                                required={true}
                                            >
                                                <MenuItem value={"01"} primaryText={"Jan"}/>
                                                <MenuItem value={"02"} primaryText={"Feb"}/>
                                                <MenuItem value={"03"} primaryText={"Marc"}/>
                                                <MenuItem value={"04"} primaryText={"Apr"}/>
                                                <MenuItem value={"05"} primaryText={"May"}/>
                                                <MenuItem value={"06"} primaryText={"Jun"}/>
                                                <MenuItem value={"07"} primaryText={"Jul"}/>
                                                <MenuItem value={"08"} primaryText={"Aug"}/>
                                                <MenuItem value={"09"} primaryText={"Sep"}/>
                                                <MenuItem value={"10"} primaryText={"Oct"}/>
                                                <MenuItem value={"11"} primaryText={"Nov"}/>
                                                <MenuItem value={"12"} primaryText={"Dec"}/>
                                            </SelectField>
                                            <br/>



                                            <RaisedButton
                                                label="Cancel"
                                                secondary={true}
                                                style={style}
                                                onClick={()=>window.location.href="#/dashboard-contract"}
                                            />
                                            <RaisedButton
                                                label="Submit"
                                                primary={true}
                                                style={style}
                                                type={"submit"}
                                                onClick={() => {
                                                    // this.submitClock();
                                                }}
                                            />
                                            </form>

                                        </div>

                                        <div className="col col-lg-6">
                                            <Divider/>
                                            <div className={"container"}>
                                                <h4>Params</h4>
                                                <table className="table" style={{width:"38%"}}>
                                                    <tbody>
                                                    <tr>
                                                        <td>Contract: </td>
                                                        <td>{this.state.selectedContract}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Site: </td>
                                                        <td>{this.state.selectedSite}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Year: </td>
                                                        <td>{this.state.selectedYear}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Month: </td>
                                                        <td>{this.state.selectedMonth}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <h3><u>Download Link Info</u></h3>

                                                    {this.state.downloadLink !==""&&(
                                                        <p className="alert alert-success" style={{width:"38%"}}>
                                                        <a
                                                            className={"clickMe"}
                                                            onClick={()=>this.openDownloadLink()}
                                                        >Click Here to Open on New Tab</a>
                                                        </p>
                                                    )}

                                                    {this.state.downloadLink ===""&&(
                                                        <p className="alert alert-danger " style={{width:"38%"}}>Waiting for link...</p>
                                                    )}


                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </Paper>

                        </div>

                    </div>


                </div>
            </MuiThemeProvider>
        );
    }
}



export default MyContainer;


function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function handleActive(tab) {
    alert(`A tab with this route property ${tab.props['data-route']} was activated.`);
}

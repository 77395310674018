import React from 'react';
import '../asset/styles/emailConfirmation.css';

class emailConfirmationMessageContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <html>
            <body>
            <div className="myHeader">
                <img width="100" height="100"
                     src="http://biadebt.easipath.com:33332/download/rev/file/215d84f3d1/PMIS%20Logo%20Mock%20Up.png"/>
                <hr/>
            </div>

            <div className="myContent1">
                <br/>
                <h2>Hi Peter, it great to meet you.</h2>
                <p>we'd like to make sure we got your email address right.</p>
                <a href="http://epwp.easipath.com" className="myButtonLink">Yes, it's me - let's get started</a>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>

            <div className="myFooter">
                <hr/>
                <p>
                    if you have any question you can contact us at <b>support@pmis.co.za</b>
                </p>
            </div>

            </body>
            </html>
        )
    }


}

export default emailConfirmationMessageContainer;
import React from 'react';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {ListItem} from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import {Tabs, Tab} from 'material-ui/Tabs';
import Badge from 'material-ui/Badge';
import moment from 'moment';




import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';


import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';



import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import {PostPims} from '../api/post_pmis';

const serverEpwp = eConfig.serverEpwp;

const styles = {
    customWidth: {
        width: 150,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};
const style = {
    margin: 12,
};


class MyContainerReportPayrollPayment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: "",
            username: "",
            LoginUser: {},
            Queues:[],
            contract:"",
        };

        this.searchClock = this.searchClock.bind(this);
        this.fetchQaQueue = this.fetchQaQueue.bind(this);
        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);
        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);
        this.renderQaQueue = this.renderQaQueue.bind(this);
        this.submitStatus = this.submitStatus.bind(this);
    }

    componentWillMount() {
        let u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        tmp.contract =  window.localStorage.getItem("SELECTED-CONTACT");
        this.setState(tmp);
        this.fetchQaQueue();
    }


    convertStringDateIntoMomentDate(dateString) {
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    convertMomentDateToStringISO(dt) {
        let momentObj = moment(dt);
        let momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        return momentString
        // let dttt = dt.format("YYYY-MM-DD");
        // console.log("!!!!!!!!!!---> ",momentString);
        // return dt.toISOString().slice(0, 10);
    }

    searchClock() {
        /** Initialize clocks array **/
        var tmp = this.state;
        tmp["Clocks"] = [];
        this.setState(tmp);


        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/clock/report/basic";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = storeContract;
        hub.SiteName = this.state.selectedSiteReport;
        hub.start = this.state.startDate;
        hub.end = this.state.endDate;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                let csvDataPayaccsys=[];
                let csvDataProcard=[];
                let csvDataKey=[];
                let x=0;
                for (let i in responseData.report) {
                    let row = responseData.report[i];
                    row.NetPay = row.NetPay.toFixed(2);
                    const emp = this.state.EmployeesMap[row.EmployeeCode];
                    row.AccountNumber = emp.AccountNumber;
                    row.Bank = emp.Bank;
                    row.BranchCode = emp.BranchCode;
                    row.EmployeeIDNo = emp.EmployeeIDNo;
                    //todo let generate csv data

                    if(emp.PaymentMethod ==="PayAccsys") {
                        csvDataPayaccsys.push([
                            row.EmployeeCode,
                            row.Name,
                            row.NetPay,
                            row.AccountNumber,
                            row.BranchCode,
                            row.Site,
                            row.Designation,
                            /* row.Bank*/
                        ]);


                    }

                    if(emp.PaymentMethod ==="Procard") {
                        csvDataProcard.push({
                            EmployeeCode: row.EmployeeCode,
                            Name: emp.Name,
                            Surname: emp.Surname,
                            NetPay: row.NetPay,
                            Account: row.AccountNumber,
                            BranchCode: row.BranchCode,
                            Site: row.Site,
                            Designation: row.Designation,
                            Bank: row.Bank,
                            EmployeeIDNo : row.EmployeeIDNo,
                            Company:emp.Company,
                        });
                    }



                }



                var tmp = this.state;
                tmp["Clocks"] = responseData.clocks;
                tmp["ClocksContract"] = responseData.clocksContract;
                tmp["ClockBasicReport"] = responseData.report;
                tmp["ClockBasicReportContract"] = responseData.reportContract;
                tmp["csvDataPayaccsys"]= csvDataPayaccsys;
                tmp["csvDataProcard"]= csvDataProcard;

                this.setState(tmp);
                console.log("+++++>searchClock List response ", responseData);
            });
    }

    fetchQaQueue(){

        const endpoint = "/api/clock/qa/list-pending";
        let hub = {
            company: this.state.company,
            contract: this.state.contract,
        };
        let _this = this;
        PostPims(hub, endpoint, function(data,err){
            console.log("fetchQaQueue -> ",data,err);
            _this.setState({Queues:data});
        });

    }

    submitStatus(row,status){

        let text = window.confirm("Are you sure that you ["+status+"] this clock of ["+row.Name[0]+". "+row.Surname+"] Clocked on ["+row.ClockDate+"] ?");
        if(!text){
            return
        }

        const endpoint = "/api/clock/qa/change-status";
        let hub = row;
        hub.Status = status;
        let _this = this;
        console.log("submitStatus hub -> ",hub);

        PostPims(hub, endpoint, function(data,err){
            console.log("submitStatus Response-> ",data,err);
            alert("Submit status "+data.STATUS);
            _this.fetchQaQueue();
        });


    }

    renderQaQueue(){

        if(this.state.Queues.length ===0){
            return(<p className="alert alert-warning">Not Clock to Qa!</p>)
        }

        return(
            <table className="table">
                <thead>
                <tr>
                    <th>Fullname</th>
                    <th>Contract</th>
                    <th>Site</th>
                    <th>EmpCode</th>
                    <th>ClockDate</th>
                    <th>Requested</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {this.state.Queues.map((row)=>{
                    return(
                        <tr key={row.Id}>
                            <td>{row.Name+" "+row.Surname}</td>
                            <td>{row.ContractName}</td>
                            <td>{row.SiteName}</td>
                            <td>{row.EmployeeCode}</td>
                            <td>{row.ClockDate}</td>
                            <td>{row.OrgDateTime}</td>
                            <td>
                                |&nbsp; <a className="clickMe" onClick={()=>this.submitStatus(row,"approve")}>Approve</a>
                                &nbsp;&nbsp; | &nbsp;&nbsp;
                                <a className="clickMe" onClick={()=>this.submitStatus(row,"reject")}>Reject</a>
                            </td>
                        </tr>
                    )
                })}

                </tbody>
            </table>
        )
    }



    render() {
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="Qa Auto Clock" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>


                                <div className="centerContent" >


                                    <Tabs>
                                        <Tab label="Queue & New">


                                            {this.renderQaQueue()}

                                        </Tab>

                                        <Tab label="Issue & Query">



                                        </Tab>

                                    </Tabs>


                                </div>

                            </Paper>

                        </div>

                    </div>

                </div>

            </MuiThemeProvider>
        )
    }


}

export default MyContainerReportPayrollPayment;


function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}
import React from 'react';
import PMIS_LOGO from '../../asset/images/pmis_logo.png';
import TokenService from '../../api/tokeninfo';
import eConfig from '../../epwp_config.json';
import '../../asset/styles/top-menu.css';

const serverEpwp = eConfig.serverEpwp;




class TopMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            LoginUser: {},
            company: "",
            username: "",
            fullname:"Unknown",
            role: "",
            page:"Unknown",
            Organisation: {},
            menu:"",
        };

        this.fetchOrganisation = this.fetchOrganisation.bind(this);
        this.getActiveMenu  = this.getActiveMenu.bind(this);
        this.setMenu = this.setMenu.bind(this);

        this.setMenu();


    }

    setMenu(){

        if(typeof this.props.menu ==="undefined"){
            let link = window.location.pathname;
            console.log("Link > ", link);
            console.log("Loc > ", this.props);
            return
        }

        let currentMenu = this.props.menu.replace("/","");
        let tmp = this.state;
        tmp.menu = currentMenu;
        this.setState(tmp);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        tmp.fullname = u.Name+" "+u.Surname;
        if(typeof this.props.page !=="undefined"){
            tmp.page = this.props.page;
        }

        this.setState(tmp);



        /**
         * Load companies from api
         */
        this.fetchOrganisation();


    }

    getActiveMenu(menuIn){

        let currentMenu = this.state.menu;
        if(menuIn ===currentMenu){
            return "active myColorMenuBlue"
        }

        return "";
    }


    fetchOrganisation() {
        let company = this.state.company;
        let endpoint = "/api/organisation/find/owner";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Organisation = responseData.DATA;
                this.setState(tmp);
                console.log("+++++>fetchOrganisation response ", responseData);
            });

    }

    render() {
        return (
            <div className="navmenu">
                <div className="topMenuLevel1">
                    <img className="circle2" width={50} height={30} src="http://biadebt.easipath.com:33332/download/rev/file/225d84f3d1/logo-white.jpeg" />
                    {/*<span className="circle2 circleTextFormat">PMIS</span>*/}
                    <div className="dropMenu pull-right">
                        <li className="dropdown undecoraMe">
                        <a href="#/design" className="dropdown-toggle " data-toggle="dropdown" role="button"
                           aria-haspopup="true" aria-expanded="false">
                            <i className="glyphicon glyphicon-user decoreTxt"></i>&nbsp;
                            <span className="decoreTxt"> {this.state.fullname}</span>

                        </a>
                        <ul className="dropdown-menu">
                            <li><a href="#/design">Profile</a></li>
                            <li><a className="clickMe" onClick={()=>{window.location.href ="#/logout"}}>Logout</a></li>
                            <li role="separator" className="divider"></li>
                        </ul>
                        </li>
                    </div>
                </div>

                <div className="topMenuLevel2">
                    <i className="glyphicon glyphicon-list"></i>
                    &nbsp;&nbsp;<span className="companyName boldMe">{this.state.Organisation.DisplayName}</span>
                    &nbsp;&nbsp;<span className="companyName boldMe pull-right">{window.localStorage.getItem("SELECTED-CONTACT")}</span>
                    <hr role="separator" className=""/>

                    <nav className="navbar navbar-default">
                        <div className="container-fluid">

                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                        data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                            </div>


                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav">
                                    <li className={this.getActiveMenu("dashboard")}>
                                        <a href="#/dashboard">
                                        Dashboard
                                        <span className="sr-only">(current)</span>
                                        </a>
                                    </li>
                                    {/*<li className={this.getActiveMenu("employee")}><a href="#/employee">Employee</a></li>
                                    <li className={this.getActiveMenu("clocking")}><a href="#/clocking">Clock</a></li>*/}


                                </ul>
                                <ul  className={"nav navbar-nav navbar-right mysetting01"}>
                                    <li className={this.getActiveMenu("setting")}>
                                    <a href="#/setting" ><i className="glyphicon glyphicon-cog "></i>&nbsp;
                                        Setting</a>
                                    </li>
                                </ul>

                                {/*<ul className="nav navbar-nav navbar-right">
                                    <li className="dropdown">
                                        <a href="#/design" className="dropdown-toggle" data-toggle="dropdown" role="button"
                                           aria-haspopup="true" aria-expanded="false">
                                            <i className="glyphicon glyphicon-cog"></i>&nbsp;
                                            Setting

                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a href="#/setting-organisation">Organisation settings</a></li>
                                            <li><a href="#/setting-contract">Contract Setup</a></li>
                                            <li><a href="#/setting-users">Users</a></li>
                                            <li role="separator" className="divider"></li>
                                            <li><a href="#/design">Email Setting</a></li>
                                        </ul>
                                    </li>
                                </ul>*/}


                            </div>



                        </div>



                    </nav>

                </div>

                <div className="myPageTitle">
                    <h3>{this.state.page}</h3>
                </div>
            </div>

    )
    }
    }


    export default TopMenu;




import React from 'react';
import TopMenu from '../component/common/top-nav-new';

class DemoPage extends React.Component {
    render() {
        return (
            <div className="page">
                <TopMenu/>
            </div>

        )
    }
}


export  default DemoPage;
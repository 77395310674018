import React from 'react';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import PropTypes from 'prop-types';
import TopMenu from '../component/common/top-nav-new';
import '../asset/styles/setting-menu.css';
import '../asset/styles/setting-compliance-criteria.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import Dialog from '@material-ui/core/Dialog';
import FlatButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SelectField from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

const serverEpwp = eConfig.serverEpwp;

class SettingComplianceCriteriaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company: "",
            username: "",
            LoginUser: {},
            Compliances: [],
            complianceName: "",
            complianceDesc: "",
            complianceScore: "",
            complianceMandatory: true,
            open: false,
            selected: [1],
        }

        this.fetchComplianceCriteria = this.fetchComplianceCriteria.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderDialog = this.renderDialog.bind(this);

        this.handleInput = this.handleInput.bind(this);
        this.submitComplianceCriteria = this.submitComplianceCriteria.bind(this);
        this.handleInputMandatory = this.handleInputMandatory.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.renderComplianceCriteria = this.renderComplianceCriteria.bind(this);
        this.handleRowSelection = this.handleRowSelection.bind(this);


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        this.setState(tmp);
        /**
         * Load default from api
         */
        this.fetchComplianceCriteria();

    }

    isSelected(index) {
        console.log("isSelected > ", index);
        return this.state.selected.indexOf(parseInt(index)) !== -1;
    };

    handleInput(e, key) {
        const val = e.target.value;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);

        console.log("handleInput change > ", key, val);
    }

    handleRowSelection = (row) => {
        console.log("handleRowSelection > ", row);
        let text = window.confirm("Are you sure that you want to delete this compliance: "+row.Name);
        if(!text){
            return
        }

        let endpoint = "/api/compliance/criteria/delete";
        let url = serverEpwp + endpoint;
        let hub = row;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Compliance deleted");
                this.fetchComplianceCriteria();
                console.log("+++++>fetchComplianceCriteria List response ", responseData);
            });

    };


    handleInputMandatory(e, index, value) {
        let key = "complianceMandatory";
        let tmp = this.state;
        tmp[key] = value;
        this.setState(tmp);
    }

    handleClickOpen() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
    }

    fetchComplianceCriteria() {

        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/compliance/criteria/list";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.contractName = storeContract;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                let data = [];
                let index = 0;
                for (let i in responseData) {
                    let row = responseData[i];
                    index++;
                    row.num = index;
                    data.push(row);
                }
                var tmp = this.state;
                tmp["Compliances"] = data;
                this.setState(tmp);
                console.log("+++++>fetchComplianceCriteria List response ", responseData);
            });
    }

    submitComplianceCriteria() {

        let company = this.state.company;
        let endpoint = "/api/compliance/criteria/new";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.Name = this.state.complianceName;
        hub.Detail = this.state.complianceDesc;
        hub.ScoreWeight = parseFloat(this.state.complianceScore);
        hub.Mandatory = this.state.complianceMandatory;
        hub.username = this.state.username;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Compliance Added");
                this.handleClose();
                this.fetchComplianceCriteria();
                console.log("+++++>submitComplianceCriteria List response ", responseData);
            });


    }

    renderDialog() {

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Submit"
                primary={true}
                keyboardFocused={true}
                onClick={this.submitComplianceCriteria}
            />,
        ];


        return (
            <div>
                <Dialog
                    title="Add New Compliance Criteria"
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    autoScrollBodyContent={true}
                >
                    <TextField
                        hintText="Compliance Name"
                        defaultValue={this.state.complianceName}
                        onChange={(e) => {
                            this.handleInput(e, "complianceName")
                        }}
                    /><br/>
                    <br/>
                    <TextField
                        hintText="Compliance Description"
                        defaultValue={this.state.complianceDesc}
                        onChange={(e) => {
                            this.handleInput(e, "complianceDesc")
                        }}
                    /><br/>
                    <TextField
                        hintText="Compliance Score"
                        id="text-field-default"
                        defaultValue={this.state.complianceScore}
                        onChange={(e) => {
                            this.handleInput(e, "complianceScore")
                        }}
                    /><br/>

                    <SelectField
                        floatingLabelText="Mandatory"
                        value={this.state.complianceMandatory}
                        onChange={(e, ee, val) => {
                            this.handleInputMandatory(e, ee, val)
                        }}
                    >
                        <MenuItem value={true} primaryText="Yes"/>
                        <MenuItem value={false} primaryText="No"/>
                    </SelectField>
                    <br/>


                </Dialog>
            </div>
        )
    }

    renderComplianceCriteria() {

        const myMandatory = function (inme) {
            if (inme) {
                return "Yes"
            }
            return "no";
        }
        let index = 0;
        return (
            <div>
                <Table className="mycompliance">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderColumn width={50}>ID</TableHeaderColumn>
                            <TableHeaderColumn width={750}>Name</TableHeaderColumn>
                            {/*<TableHeaderColumn>Description</TableHeaderColumn>*/}
                            <TableHeaderColumn width={50}>Score</TableHeaderColumn>
                            <TableHeaderColumn width={50}>Mandatory</TableHeaderColumn>
                            <TableHeaderColumn>#</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {this.state.Compliances.map((row) => {
                            index++;
                            return (
                                <TableRow>
                                    <TableRowColumn width={50}>{index}</TableRowColumn>
                                    <TableRowColumn width={750}>{row.Name}</TableRowColumn>
                                    {/*<TableRowColumn>{row.Detail}</TableRowColumn>*/}
                                    <TableRowColumn width={50}>{row.ScoreWeight}</TableRowColumn>
                                    <TableRowColumn width={50}>{myMandatory(row.Mandatory)}</TableRowColumn>
                                    <TableRowColumn>
                                        <FlatButton
                                            label="Delete"
                                            secondary={true}
                                            keyboardFocused={true}
                                            onClick={()=>{this.handleRowSelection(row)}}
                                        /><br/>
                                    </TableRowColumn>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>

                <br/>



            </div>
        );
    }

    render() {
        return (
            <div className="">
                <TopMenu page="Compliance Criteria" menu={this.props.location.pathname}/>

                <div className="pageContentWhite">

                    <div className="container borderShadon">
                        <div className="container">
                            <br/>
                            <MuiThemeProvider>
                                <div className="row">
                                    <div className="col col-lg-12">
                                        <h4>
                                            <label style={{color: "#03A9F4"}}>Compliance Criteria List</label>
                                            <button onClick={this.handleClickOpen}
                                                    className="btn btn-primary pull-right addButton">Add new
                                            </button>
                                        </h4>


                                        {this.renderComplianceCriteria()}

                                        {this.renderDialog()}

                                    </div>
                                </div>
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>


            </div>

        )
    }
}

SettingComplianceCriteriaPage.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};


export default SettingComplianceCriteriaPage;
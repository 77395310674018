/**
 * Created by dev1 on 2017/01/12.
 */


import React from 'react';
import {Link} from 'react-router-dom';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import TokenService from '../../api/tokeninfo';

class CompHeader extends React.Component {
    constructor(props) {
        super(props);
        this.isSuperUser =this.isSuperUser.bind(this);
        this.status = {
            OrgCode: "NONE",
            OrgName: "NONE",
            Role:"NONE"
        }
        this.getCurrentUser =this.getCurrentUser.bind(this);
    }

    componentWillMount() {
        let tInfo = TokenService.GetTokenObject();
        this.setState({
            OrgCode: tInfo.OrgCode,
            OrgName: tInfo.OrgName,
            Role : tInfo.Role,
            Status: tInfo.Status,
        });
    }

    getCurrentUser(){
        var myrole = TokenService.GetTokenObject();
        return myrole;
    }

    isSuperUser(){

        if(typeof TokenService.GetTokenObject().Role ==="undefined" ){
            return false;
        }
        var myrole = TokenService.GetTokenObject().Role;
        myrole = myrole.toUpperCase();
        if(myrole ==="SUPER"){
            return true
        }
        return false;
    }
    render() {
        return (
            <header className="navbar">
                <div className="container-fluid">
                    <button
                        className="navbar-toggler mobile-toggler hidden-lg-up navbar-toggle"
                        type="button"
                        data-toggle="collapse"
                        data-target=".navbar-collapse"

                    >☰</button>
                    <a className="navbar-brand" href="#"></a>



                    <ul className="nav navbar-nav hidden-md-down ">
                        <li className="nav-item">
                            <a className="nav-link navbar-toggler layout-toggler" href="#">☰2</a>
                        </li>

                        <li className="nav-item px-1">
                            <a className="nav-link" href="#">Home</a>
                        </li>
                        <li className="nav-item px-1">
                            <Link to="/contract" className="nav-link" >Contract</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link to="/#" className="nav-link" >Sites</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link to="/employee" className="nav-link" >Employee</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link to="/#" className="nav-link" >Clock</Link>
                        </li>

                        <li className="nav-item px-1">
                            <Link to="/#" className="nav-link" >Pay-Schedule</Link>
                        </li>
                        <li className="nav-item px-1">
                            <Link to="/#" className="nav-link" >Payroll</Link>
                        </li>

                        {(this.isSuperUser()===true)?

                        <li className="nav-item px-1">
                            <Link to="/company" className="nav-link" >Company</Link>
                        </li>
                        : ""}

                    </ul>
                    <ul className="nav navbar-nav float-xs-right">

                        <li className="nav-item hidden-md-down">
                            <i className="fa fa-user "></i><span className="tag tag-pill tag-info">{this.getCurrentUser().Name}</span>
                        </li>

                        <li className="nav-item hidden-md-down">
                            <a className="nav-link" href="#"><i className="icon-bell"></i><span className="tag tag-pill tag-danger">5</span></a>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
                                <img src="img/user.jpeg" className="img-avatar" alt="admin@bootstrapmaster.com" />
                                    <span className="hidden-md-down">{this.getCurrentUser().Role}</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">

                                    <i className="fa fa-user"></i><span className="tag tag-pill tag-info">{this.getCurrentUser().Name}</span>

                                <a className="dropdown-item" href="#"><i className="fa fa-user"></i> Profile</a>
                                <Link className="dropdown-item" to="/setting-home"><i className="fa fa-wrench"></i> Settings</Link>
                                <div className="divider"></div>
                                <a className="dropdown-item" href="#"><i className="fa fa-shield"></i> Lock Account</a>
                                <Link to="/logout" className="dropdown-item"><i className="fa fa-lock"></i> Logout</Link>
                            </div>
                        </li>
                        <li className="nav-item hidden-md-down">
                            <a className="nav-link aside-toggle" href="#">☰</a>
                        </li>

                    </ul>


                    {/**THIS IS FOR COLLAPSE MENU ONLY IN MOBILE*/}

                    <div className="collapse navbar-collapse">
                        <ul className="nav navbar-nav navbar-left myCollapseMenu" >
                            <li className="">
                                Choose your menu
                            </li>
                            <li className="">
                                <a className="" href="#">Home</a>
                            </li>
                            <li className="">
                                <Link to="/contract" className="" >Contract</Link>
                            </li>
                            <li className="">
                                <Link to="/#" className="" >Sites</Link>
                            </li>
                            <li className="">
                                <Link to="/employee" className="" >Employee</Link>
                            </li>
                            <li className="">
                                <Link to="/#" className="" >Clock</Link>
                            </li>

                            <li className="">
                                <Link to="/#" className="" >Pay-Schedule</Link>
                            </li>
                            <li className="">
                                <Link to="/#" className="" >Payroll</Link>
                            </li>
                        </ul>
                    </div>




                </div>
            </header>

        );
    }
}




export default CompHeader;

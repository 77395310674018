'use strict';

import React, {Component} from 'react';

export default class Step3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rates: props.getStore().rates,
            paycircle: props.getStore().paycircle,
            PayAfter :0,
            WeekPayDay :"friday",
            WorkOnSaturday :"false",
            WorkOnSunday :"false",
            RateTypeEmployee:"daily",
            RateTypeChargeOut:"daily",
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
        this.renderLocalInput = this.renderLocalInput.bind(this);
        this.handleChangeRate = this.handleChangeRate.bind(this);
        this.handleChangeCircle = this.handleChangeCircle.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.getRete = this.getRete.bind(this);
        this.setRate = this.setRate.bind(this);
        this.renderTableRateRow = this.renderTableRateRow.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(e){
        const key = e.target.name;
        const val = e.target.value;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
    }
    handleChangeCircle(e) {
        const val = e.target.value;
        let tmp = this.state;
        tmp.paycircle = val;
        this.setState(tmp)
    }

    handleChangeRate(e) {
        const name = e.target.name;
        //console.log("->> ",name,e.target.designation,e.target);
        const arr = name.split("##");
        const typeOfRate = arr[0];
        const designation = arr[1];
        const daytype = arr[2];
        const val = e.target.value;
        this.setRate(typeOfRate, designation, daytype, val)

    }

    setRate(typeOfRate, designation, daytype, val) {
        let state = this.state;
        let rates = state.rates;

        for (let i in rates) {
            const row = rates[i];
            //console.log("validata  ****> ",row.typeOfRate,typeOfRate,(row.typeOfRate ===typeOfRate)," > ",(row.designation ===designation));
            if (row.typeOfRate === typeOfRate && row.designation === designation) {

                if (typeof row[daytype] === "undefined") {
                    rates[i][daytype] = val
                } else {
                    rates[i][daytype] = val;
                }

            }
        }

        state.rates = rates;
        this.setState(state);
        //console.log("SET RATE > ",  this.state.rates);
    }

    getRete(typeOfRate, designation, daytype) {
        let state = this.state;
        for (let i in state.rates) {
            const row = state.rates[i];
            if (row.typeOfRate === typeOfRate && row.designation === designation) {
                if (typeof row[daytype] === "undefined") {
                    return 0
                }
                return row[daytype];
            }
        }
    }

    renderTableRateRow() {
        let designations = ["Worker", "Worker leader", "Supervisor"];
        return designations.map((designation) => {
            return (
                <tr>
                    <td>
                        {designation}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##normal", "text", "employee", designation, "normal")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##saturday", "text", "employee", designation, "saturday")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##sunday", "text", "employee", designation, "sunday")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##phd", "text", "employee", designation, "phd")}
                    </td>

                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##normal", "text", "employee", designation, "normal")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##saturday", "text", "employee", designation, "saturday")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##sunday", "text", "employee", designation, "sunday")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##phd", "text", "employee", designation, "phd")}
                    </td>
                </tr>
            )
        })
    }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {
                return validateNewInput[k] === true
            })) {
            //if (this.props.getStore().email != userInput.email || this.props.getStore().gender != userInput.gender) { // only update store of something changed
            this.props.updateStore({
                ...userInput,
                savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
            });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            // }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput));
        this.props.updateStore(userInput);
    }

    _validateData(data) {

        /**
         * loop through the rates and find if any element have zero value and make validation to true
         */

        let boo = true;

        return {
            ratesVal: boo, // required: anything besides N/A
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            ratesValMsg: val.ratesVal ? '' : 'A rates  is required',
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            rates: this.state.rates,
            paycircle: this.state.paycircle,
            PayAfter :this.state.PayAfter,
            WeekPayDay :this.state.WeekPayDay,
            WorkOnSaturday :this.state.WorkOnSaturday,
            WorkOnSunday :this.state.WorkOnSunday,
            RateTypeEmployee:this.state.RateTypeEmployee,
            RateTypeChargeOut:this.state.RateTypeChargeOut,
        };

        /* return {
             gender: this.refs.gender.value,
             email: this.refs.email.value
         };*/
    }

    renderLocalInput(name, type, typeOfRate, designation, daytype) {
        // typeOfRate:"emplyee",designation:"Worker"
        return (
            <div>
                <input
                    ref={name}
                    name={name}
                    typeofrate={typeOfRate}
                    designation={designation}
                    daytype={daytype}
                    autoComplete="off"
                    type={type}
                    placeholder="e.g 150.9"
                    className="form-control"
                    required
                    defaultValue={this.getRete(typeOfRate, designation, daytype)}
                    onBlur={this.validationCheck}
                    onChange={this.handleChangeRate}
                />
            </div>
        )
    }

    render() {

        return (
            <div className="step step3">
                <div className="row">
                    <form id="Form" className="form-horizontal">
                        <div className="form-group">
                            <label className="col-md-12 control-label">
                                <h1 className={"companyList"}>Step 3: Rate</h1>
                            </label>
                        </div>


                        <div className="form-group col-md-12 content form-block-holder">

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Pay Circle
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChangeCircle}
                                    >
                                        <option value={"week"}>One week</option>
                                        <option value={"two week"} selected={true}>Two week</option>
                                        <option value={"monthly"}>Month</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Week Pay Day
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"WeekPayDay"}
                                        id={"WeekPayDay"}
                                    >
                                        <option value={"monday"}>Monday</option>
                                        <option value={"tuesday"}>Tuesday</option>
                                        <option value={"wednesday"}>Wednesday</option>
                                        <option value={"thursday"}>Thursday</option>
                                        <option value={"friday"}  selected={true}>Friday</option>
                                        <option value={"saturday"}>Saturday</option>
                                        <option value={"sunday"}>Sunday</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Work On Saturday
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"WorkOnSaturday"}
                                        id={"WorkOnSaturday"}
                                    >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"} selected={true}>No</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Work On Sunday
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"WorkOnSunday"}
                                        id={"WorkOnSunday"}
                                    >
                                        <option value={"true"}>Yes</option>
                                        <option value={"false"} selected={true}>No</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-12"}>
                                    <label className="">
                                        Pay After how many days?
                                    </label>

                                    <input
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"PayAfter"}
                                        id={"PayAfter"}
                                    />
                                    <hr/>
                                </div>
                            </div>


                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Rate Type Employee
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"RateTypeEmployee"}
                                        id={"RateTypeEmployee"}
                                    >
                                        <option value={"weekly"} >Weekly</option>
                                        <option value={"hourly"} >Hourly</option>
                                        <option value={"daily"} selected={true}>Daily</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Rate Type Employee
                                    </label>

                                    <select
                                        className={"form-control"}
                                        onChange={this.handleChange}
                                        name={"RateTypeChargeOut"}
                                        id={"RateTypeChargeOut"}
                                    >
                                        <option value={"weekly"} >Weekly</option>
                                        <option value={"hourly"} >Hourly</option>
                                        <option value={"daily"} selected={true}>Daily</option>
                                    </select>
                                    <hr/>
                                </div>
                            </div>




                            <table className={"table table-bordered"}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th colSpan={4}>Emplyee Rate</th>
                                    <th colSpan={4}>Charge Out Rate</th>
                                </tr>
                                <tr>
                                    <th>Designation</th>
                                    <th>NormalDay</th>
                                    <th>Saturday</th>
                                    <th>Sunday</th>
                                    <th>PUBHday</th>

                                    <th>NormalDay</th>
                                    <th>Saturday</th>
                                    <th>Sunday</th>
                                    <th>PUBHday</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.renderTableRateRow()}

                                {/* renderLocalInput(name,type,typeOfRate,designation,daytype)*/}
                                </tbody>
                            </table>

                        </div>

                    </form>
                </div>
            </div>
        )
    }
}
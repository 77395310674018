/**
 * Created by dev1 on 2017/01/17.
 */

var jwtDecode = require('jwt-decode');

module.exports = {
    TokenInfo: function () {
        var token = localStorage.getItem("token");
        var decoded = jwtDecode(token, {header: true});
        console.log("jwt_decode ******>>> ", decoded);
        if (decoded.typ === 'JWT' && decoded.alg === 'HS256') {
            // your token is created using the standard format
        } else {
            return false;
        }
        var hasLogin = false;
        if (token != null) {
            hasLogin = true;
        }
        return hasLogin;
    },
    GetTokenObject: function () {
        var token = localStorage.getItem("token");
        var decoded = jwtDecode(token);
        return decoded;
    },
    CheckTokenValidation: function () {
        var token = localStorage.getItem("token");
        var decoded = jwtDecode(token, {header: true});
        if (decoded.typ === 'JWT' && decoded.alg === 'HS256') {
            // your token is created using the standard format
        } else {
            return false;
        }
        return true;
    }
};

import React from 'react';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { ListItem} from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import {Tabs, Tab} from 'material-ui/Tabs';
import Badge from 'material-ui/Badge';
import moment from 'moment';



import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';


/**
 * LEt try this to make it work
 */

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const serverEpwp = eConfig.serverEpwp;

const styles = {
    customWidth: {
        width: 150,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};
const style = {
    margin: 12,
};


class MyContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},

            selectedContract: "",
            selectedSite: "",
            selectedDate: "",

            selectedSiteReport: "",
            selectedPaycycleReport: "",

            Contracts: [],
            Sites: [],
            Employees: [],
            Clocks: [],
            ClocksContract: [],
            ClockBasicReport: {},
            ClockBasicReportContract: {},
            PayCycles: [],
            selectedEmployees: {},
            selected: [1],
            startDate: "",
            endDate: "",

            empClocks: [],
            empCurrentViewClock: {},
            isViewClock: false,

            PeoplesSite: [],

            controlledDate: null,

        };


        this.handelCheckEmployee = this.handelCheckEmployee.bind(this);
        this.handleChangeClockDate = this.handleChangeClockDate.bind(this);
        this.handleChangePaycycle = this.handleChangePaycycle.bind(this);
        this.handleChangeReportDate = this.handleChangeReportDate.bind(this);
        this.handleChangeContract = this.handleChangeContract.bind(this);
        this.handleChangeSite = this.handleChangeSite.bind(this);
        this.handleChangeSiteReport = this.handleChangeSiteReport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.fetchSites = this.fetchSites.bind(this);
        this.fetchEmployee = this.fetchEmployee.bind(this);
        this.fetchDailyClock = this.fetchDailyClock.bind(this);

        this.handleRowSelection = this.handleRowSelection.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.submitClock = this.submitClock.bind(this);


        this.renderContractOption = this.renderContractOption.bind(this);
        this.renderSiteOption = this.renderSiteOption.bind(this);
        this.renderSiteOptionReport = this.renderSiteOptionReport.bind(this);
        this.renderAvailableEmployeeList = this.renderAvailableEmployeeList.bind(this);
        this.renderDayClock = this.renderDayClock.bind(this);
        this.renderDayClockReport = this.renderDayClockReport.bind(this);

        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);
        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);
        this.isDateBetween = this.isDateBetween.bind(this);

        this.searchClock = this.searchClock.bind(this);
        this.fetchPayCycle = this.fetchPayCycle.bind(this);
        this.renderPaycycleOption = this.renderPaycycleOption.bind(this);
        this.showClock = this.showClock.bind(this);
        this.openShowClock = this.openShowClock.bind(this);
        this.closeShowClock = this.closeShowClock.bind(this);
        this.renderShowClock = this.renderShowClock.bind(this);
        this.showClockContent = this.showClockContent.bind(this);
        this.renderFilterSearchRow = this.renderFilterSearchRow.bind(this);
        this.renderFilterSiteReportRow = this.renderFilterSiteReportRow.bind(this);
        this.renderFilterPeriodReportRow = this.renderFilterPeriodReportRow.bind(this);
        this.fetchPeopleByDesignationContract = this.fetchPeopleByDesignationContract.bind(this);
        this.handleChangeDates = this.handleChangeDates.bind(this);


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        tmp.startDate = this.convertMomentDateToStringISO(moment());
        tmp.endDate = this.convertMomentDateToStringISO(moment());
        this.setState(tmp);

        window.$('.mui-body-table').css('overflow-x', 'auto');


        /**
         * Load default from api
         */
        this.fetchContracts();
        this.fetchSites();
        this.fetchEmployee();
        this.fetchPayCycle();
        this.fetchPeopleByDesignationContract();
        //this.fetchDailyClock();
    }
    handleChangeDates = (event, date) => {
        console.log('HERE');
        this.setState({
            controlledDate: date,
        });
        console.log(date);
        console.log(this.state.controlledDate);
    };

    deleteClock(hub){
        ///api/click/delete    obj.Company, obj.PayPeriodNumber,obj.ClockDate,obj.EmployeeCode),
        let txt  = window.confirm("Are you sure that you want to delete this Clock Entry? EmpNumber: "+
            hub.EmployeeCode+" | Date: "+hub.ClockDate+" | Period: "+hub.PayPeriodNumber);
        if(!txt){
            return
        }

        let endpoint = "/api/click/delete";
        let url = serverEpwp + endpoint;
      ;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

            alert("Clock deleted ");
            this.closeShowClock();

            });


    }

    fetchPeopleByDesignationContract() {

        //  api/contracts/contract/paycycle-detail/list-contract
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/sitepeoplebydesignation/list-contract";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.contractName = storeContract;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["PeoplesSite"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchPeopleByDesignationContract List response ", responseData);
            });
    }

    fetchPayCycle() {
        //  api/contracts/contract/paycycle-detail/list-contract
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/paycycle-detail/list-contract";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.contractName = storeContract;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["PayCycles"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchPayCycle List response ", responseData);
            });
    }

    searchClock() {
        /** Initialize clocks array **/
        var tmp = this.state;
        tmp["Clocks"] = [];
        this.setState(tmp);


        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/clock/report/basic";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = storeContract;
        hub.SiteName = this.state.selectedSiteReport;
        hub.start = this.state.startDate;
        hub.end = this.state.endDate;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Clocks"] = responseData.clocks;
                tmp["ClocksContract"] = responseData.clocksContract;
                tmp["ClockBasicReport"] = responseData.report;
                tmp["ClockBasicReportContract"] = responseData.reportContract;
                this.setState(tmp);
                console.log("+++++>searchClock List response ", responseData);
            });
    }

    fetchSites() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/site/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Sites"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchSites List response ", responseData);
            });
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Contracts"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchContracts List response ", responseData);
            });
    }

    fetchEmployee() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Employees"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchEmployee List response ", responseData);
            });
    }

    fetchDailyClock() {
        let company = this.state.company;
        let endpoint = "/api/clock/list/company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                let dateMoment = moment();
                let dt = this.convertMomentDateToStringISO(dateMoment);
                var tmp = this.state;
                tmp.Clocks = responseData;
                this.setState(tmp);
                console.log("+++++>fetchDailyClock List response ", responseData);
            });
    }

    handleChange(key, val) {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("-->handleChange > ", key, " > ", val);
    }

    handleChangeContract(e, index, value) {
        let key = "selectedContract";
        this.handleChange(key, value)
    }

    handleChangeSite(e, index, value) {
        let key = "selectedSite";
        this.handleChange(key, value)
    }

    handleChangeSiteReport(e, index, value) {
        let key = "selectedSiteReport";
        this.handleChange(key, value)
    }

    handleChangePaycycle(e, index, value) {
        let arr = value.split("--");
        const startDate = arr[1];
        const endDate = arr[2];
        this.handleChange("startDate", startDate)
        this.handleChange("endDate", endDate);
        this.handleChange("selectedPaycycleReport", value);


    }

    handleChangeClockDate(e, eDate, phaseDate) {
        let key = "selectedDate";
        let val = this.convertMomentDateToStringISO(eDate);
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("handleChangeClockDate >> ", val, " > ", phaseDate);
    }

    handleChangeReportDate(e, eDate, phaseDate) {
        let key = phaseDate;
        let val = this.convertMomentDateToStringISO(eDate);
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("handleChangeReportDate >> ", val, " > ", phaseDate);
    }

    handelCheckEmployee(e, action, empCode) {
        let tmp = this.state;
        if (action) {
            tmp.selectedEmployees[empCode] = true;
        }
        if (!action) {
            delete tmp.selectedEmployees[empCode];
        }
        this.setState(tmp);
    }

    isSelected(index) {
        return this.state.selected.indexOf(index) !== -1;
    }

    handleRowSelection(selectedRows) {
        this.setState({
            selected: selectedRows,
        });
    }

    convertStringDateIntoMomentDate(dateString) {
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    convertMomentDateToStringISO(dt) {
        let momentObj = moment(dt);
        let momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        return momentString
        // let dttt = dt.format("YYYY-MM-DD");
        // console.log("!!!!!!!!!!---> ",momentString);
        // return dt.toISOString().slice(0, 10);
    }

    isDateBetween(target, start, end) {

        var startDate = moment(start)
            , endDate = moment(end)
            , date = moment(target)
        ;

        console.log("isDateBetween ####--> ", startDate.toString(), endDate.toString(), date.toString(), date.isBetween(startDate, endDate));

        return date.isBetween(startDate, endDate); // false
    }

    renderContractOption() {
        return this.state.Contracts.map((row) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name}/>
            )
        })
    }

    renderPaycycleOption() {
        let list = [];
        for (let i in this.state.PayCycles) {
            let row = this.state.PayCycles[i];
            let o = {
                val: row.Period + "--" + row.StartDate + "--" + row.EndDate + "--" + row.PayDate,
                label: "" + row.Period + ". -> Start: " + convertDateFormat_DDMMYYYY(row.StartDate) + " -> End: " + convertDateFormat_DDMMYYYY(row.EndDate) + " -> Pay date: " + convertDateFormat_DDMMYYYY(row.PayDate),
            };
            list.push(o)
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.val} primaryText={row.label}/>
            )
        });
    }

    renderSiteOption() {
        let list = [];
        for (let i in this.state.Sites) {
            let row = this.state.Sites[i];
            if (row.ContractName === this.state.selectedContract) {
                list.push({Name: row.SiteName})
            }
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name}/>
            )
        })
    }

    renderSiteOptionReport() {
        /**
         * Get under ground contract
         * @type {Array}
         */
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");

        let list = [];
        for (let i in this.state.Sites) {
            let row = this.state.Sites[i];
            if (row.ContractName === storeContract) {
                list.push({Name: row.SiteName})
            }
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.Name} primaryText={row.Name}/>
            )
        })
    }

    renderAvailableEmployeeList() {
        let contract = this.state.selectedContract;
        let site = this.state.selectedSite;
        let list = [];
        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            if (row.Contract === contract && row.Site === site) {
                if (row.Status === "trial") {
                    list.push({
                        code: row.EmployeeCode,
                        name: row.EmployeeCode + " -- " + row.Name + " " + row.Surname + " -- " + row.Designation,
                    });
                }

            }

        }

        return list.map((row) => {
            return (
                <ListItem primaryText={row.name} leftCheckbox={<Checkbox name={row.name}
                                                                         onCheck={(e, isInputChecked) => this.handelCheckEmployee(e, isInputChecked, row.code)}/>}/>
            )
        })

        //EmployeeCode: "ecare-10013" EmployeeIDNo: "9876543210123"
        // <ListItem primaryText="Notifications" leftCheckbox={<Checkbox />} />
    }

    openShowClock() {
        let tmp = this.state;
        tmp.isViewClock = true;
        this.setState(tmp);
    }

    closeShowClock() {
        let tmp = this.state;
        tmp.isViewClock = false;
        this.setState(tmp);
    }

    showClock(obj, typeOfDay) {
        //alert("Request for :" + typeOfDay + " Is not implemented yet");
        let ls = [];
        for (let i in this.state.Clocks) {
            let row = this.state.Clocks[i];
            //make sure that we are dealing with only selected employee
            if (row.EmployeeCode === obj.EmployeeCode) {
                if (typeOfDay === "week") {
                    if (row.ClockDay === "normal") {
                        ls.push(row);
                    }
                }
                if (typeOfDay === "weekend") {
                    if (row.ClockDay === "sunday" || row.ClockDay === "saturday") {
                        ls.push(row);
                    }
                }

                if (typeOfDay === "pph") {
                    if (row.ClockDay !== "normal" && row.ClockDay !== "sunday" && row.ClockDay !== "saturday") {
                        ls.push(row);
                    }
                }
                if (typeOfDay === "all") {
                    ls.push(row);
                }
            }
        }

        //let update our report data
        let tmp = this.state;
        tmp.empClocks = ls;
        tmp.empCurrentViewClock = obj;
        this.setState(tmp);

        this.openShowClock();
    }

    showClockContent() {
        let ls = this.state.empClocks;
        let index = 0;
        let myFirstRow = {
            SiteName: "",
            PayPeriodNumber: 0,
            PayDate: ""
        };

        if (ls.length > 0) {
            myFirstRow = ls[0];
        }

        let tot = 0;

        console.log("this.state.empCurrentViewClock ---> ",this.state.empCurrentViewClock);
        let netpay = 0;
        let uif=0;
        if(typeof this.state.empCurrentViewClock.NetPay !=="undefined"){
            netpay = this.state.empCurrentViewClock.NetPay;
        }
        if(typeof this.state.empCurrentViewClock.UIF !=="undefined"){
            uif = this.state.empCurrentViewClock.UIF;
        }

        return (
            <div className="">
                <div className="container" style={{width: "100%"}}>
                    <div className="col-lg-4 myFirstRowCol">Site:&nbsp;{myFirstRow.SiteName}</div>
                    <div className="col-lg-4 myFirstRowCol">Pay Period:&nbsp;{myFirstRow.PayPeriodNumber}</div>
                    <div className="col-lg-4 myFirstRowCol">Pay
                        Date:&nbsp;{convertDateFormat_DDMMYYYY(myFirstRow.PayDate)}</div>
                </div>
                <hr/>
                <br/>

                <table className="table"
                       style={{width: "99%", height: "90%", overflow: "visible"}}>
                    <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                    <tr>
                        <th width='20px'>#</th>
                        <th width='200px'>ClockDate</th>
                        <th width='200px'>Day</th>
                        <th width='200px'>Rate</th>
                        <th width='50px'>Hours</th>
                        <th width='200px'>Gross Pay</th>
                        <th>#</th>

                    </tr>
                    </thead>
                    <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                    {
                        ls.map((row) => {
                            index++;
                            tot = tot + parseFloat(row.CostAmountEmployee);
                            return (
                                <tr selected={this.isSelected(index)}>
                                    <td width='20px'>{index}</td>
                                    <td width='200px'>{convertDateFormat_DDMMYYYY(row.ClockDate)}</td>
                                    <td width='200px'>{row.ClockDay}</td>
                                    <td width='200px' className="tAlignRight">
                                        {"R " + row.CostRateEmployee + "/" + row.RateType[0]}
                                    </td>
                                    <td width='50px' className="tAlignRight">{row.HoursWorked}</td>
                                    <td width='200px' className="tAlignRight">{"R " + row.CostAmountEmployee}</td>
                                    <td><a onClick={()=>this.deleteClock(row)} className="clickMe"><i style={{color:"red"}} className="glyphicon glyphicon-trash" /> </a> </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="tAlignRight" colSpan={5}>Total:</th>
                            <th className="tAlignRight">R {tot.toFixed(2)}</th>
                        </tr>
                        <tr>
                            <th className="tAlignRight" colSpan={5}>UIF:</th>
                            <th className="tAlignRight">R {uif.toFixed(2)}</th>
                        </tr>
                        <tr>
                            <th className="tAlignRight" colSpan={5}>Net Pay:</th>
                            <th className="tAlignRight">R {netpay.toFixed(2)}</th>
                        </tr>

                    </tfoot>
                </table>
            </div>
        )
    }

    renderShowClock() {

        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.closeShowClock}
            />,
        ];


        let ls = this.state.empClocks;
        let index = 0;
        const myTileComp = (myEmp) => {

            return (
                <div>
                    <h4>T & A Clocking: {myEmp.Name} -> {myEmp.EmployeeCode}<span
                        className="pull-right">{myEmp.Designation}</span></h4>
                </div>
            )
        }
        return (
            <Dialog
                title={myTileComp(this.state.empCurrentViewClock)}
                actions={actions}
                modal={false}
                open={this.state.isViewClock}
                onRequestClose={this.openShowClock}
                autoScrollBodyContent={true}
            >

                <br/>

                <div className="col-lg-12">
                    {this.showClockContent()}
                </div>


            </Dialog>



        )

    }

    renderDayClockReport() {
        let ls = [];
        for (let i in this.state.ClockBasicReport) {
            let row = this.state.ClockBasicReport[i];
            ls.push(row);
        }


        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Clocking</p>)
        }

        let index = 0;


        return (
            <table className="table "
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th width='20px'>#</th>
                    <th width='200px'>Code</th>
                    <th width='400px'>Name</th>
                    <th width='150px'>Role</th>
                    <th width='200px'>Site</th>
                    <th width='50px'>WKDAY</th>
                    <th width='50px'>W/END</th>
                    <th width='50px'>PPH</th>
                    <th width='200px'>Hours</th>
                    <th width='200px'>GrossPay</th>
                    <th width='200px'>UIF</th>
                    <th width='200px'>NetPay</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='20px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "all")
                                    }}>
                                        <i className="badge">{index}</i>
                                    </a>

                                </td>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.Name}</td>
                                <td width='150px'>{row.Designation}</td>
                                <td width='200px'>{row.Site}</td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "week")
                                    }}>
                                        <Badge
                                            badgeContent={row.WeekDay}
                                            primary={true}
                                        >
                                        </Badge>
                                    </a>
                                </td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "weekend")
                                    }}>
                                        <Badge
                                            badgeContent={row.WeekendDay}
                                            primary={true}
                                        >
                                        </Badge>
                                    </a>
                                </td>
                                <td width='50px'>
                                    <a className="clickMe" onClick={() => {
                                        this.showClock(row, "pph")
                                    }}>
                                        <Badge
                                            badgeContent={row.PphDdays}
                                            secondary={true}
                                        >
                                        </Badge>
                                    </a>

                                </td>
                                <td width='200px'>{row.Hours}</td>
                                <td width='200px' align="left">{"R " + row.GrossPay.toFixed(3)}</td>
                                <td width='200px' align="left">{"R " + row.UIF.toFixed(3)}</td>
                                <td width='200px' align="left">{"R " + row.NetPay.toFixed(3)}</td>

                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        )

    }

    renderDayClock() {
        let myStyle = {
            overflow: 'visible',
        };
        let index = 0;

        let ls = [];

        for (let i in this.state.Clocks) {
            let row = this.state.Clocks[i];
            /*if (this.isDateBetween(row.ClockDate, this.state.startDate, this.state.endDate)) {
                ls.push(row);
            }*/
            ls.push(row);
        }

        console.log("YY--> NUMBER OF CLOCKED > ", ls);

        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Clocking</p>)
        }

        return (
            <table className="table"
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th width='20px'>#</th>
                    <th width='200px'>Name</th>
                    <th width='200px'>Code</th>
                    <th width='200px'>Contract</th>
                    <th width='200px'>Site</th>
                    <th width='50px'>Period</th>
                    <th width='50px'>Hours</th>
                    <th width='200px'>ClockDate</th>
                    <th width='200px'>Rate/Type</th>
                    <th width='200px'>Cost</th>
                    <th width='200px'>Pay Date</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='20px'>{index}</td>
                                <td width='200px'>{row.Fullname}</td>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.ContractName}</td>
                                <td width='200px'>{row.SiteName}</td>
                                <td width='50px'>{row.PayPeriodNumber}</td>
                                <td width='50px'>{row.HoursWorked}</td>
                                <td width='200px'>{row.ClockDate}</td>
                                <td width='200px'>{"R " + row.CostRateEmployee + "/" + row.RateType}</td>
                                <td width='200px'>{"R " + row.CostAmountEmployee}</td>
                                <td width='200px'>{row.PayDate}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        )

    }

    submitClock() {
        /**
         * Validation
         * */
        let emps = [];
        for (let i in this.state.selectedEmployees) {
            emps.push(i);
        }
        let contract = this.state.selectedContract;
        let site = this.state.selectedSite;
        let date = this.state.selectedDate;
        let totEmp = emps.length;
        if (contract === "") {
            alert("Validation Failed! Empty Contract, please select a valid input");
            return
        }
        if (date === "") {
            alert("Validation Failed! Empty Clock Date, please select a valid input");
            return
        }
        if (totEmp === 0) {
            alert("Validation Failed! Empty Employee list, please select a valid input");
            return
        }
        let boo = window.confirm("Are you sure that you want to submit this  clock [" + contract + "] -> [" + site + "] ?");
        if (!boo) {
            return
        }

        //   /api/clock/new/bulk

        let company = this.state.company;
        let endpoint = "/api/clock/new/bulk";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = this.state.selectedContract;
        hub.Employees = emps;
        hub.ClockDate = this.state.selectedDate;
        hub.ClockType = "web";


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Thank you your clock have been submit, An email will be send to you once done!");
                console.log("+++++>submitClock response ", responseData, hub);
                window.location.reload();
            });


    }

    repGetTotalEmployeesBySite(siteName, sites) {
        let total = 0;
        //todo make sir that you select the site before
        if (siteName === "") {
            return 0;
        }
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        for (let i in sites) {
            let row = sites[i];
            if (row.ContractName === storeContract) {
                if (row.SiteName === siteName) {
                    let tot = parseInt(row.Total);
                    total = total + tot;
                }
            }
        }
        return total;
    }

    repGetTotalPayees(clocks) {
        return clocks.length;
    }

    repGetGrossNetUif(clocks, allClocks) {
        console.log("repGetGrossPay > ", clocks, allClocks);
        let groossPay = 0;
        let netPay = 0;
        let uif = 0;
        let pph = 0;
        for (let i in clocks) {
            let row = clocks[i];
            let tot = parseFloat(row.GrossPay);
            groossPay = groossPay + row.GrossPay;
            netPay = netPay + row.NetPay;
            uif = uif + row.UIF;
        }

        for (let i in allClocks) {
            const row = allClocks[i];
            if (row.ClockDay !== "normal" && row.ClockDay !== "sunday" && row.ClockDay !== "saturday") {
                pph = pph + row.CostAmountEmployee;
            }

        }

        return {grosspay: groossPay.toFixed(2), netpay: netPay.toFixed(2), uif: uif.toFixed(2), pph: pph.toFixed(2)};
    }

    renderFilterSiteReportRow() {

        let clocks = [];
        for (let i in this.state.ClockBasicReport) {
            let row = this.state.ClockBasicReport[i];
            clocks.push(row);
        }

        let totEmployee;
        let totPayees;

        //todo find total employees by site
        totEmployee = this.repGetTotalEmployeesBySite(this.state.selectedSiteReport, this.state.PeoplesSite);

        //todo get total payees from tagert clocks
        totPayees = this.repGetTotalPayees(clocks);

        //todo find total grosspay netpay and uif
        let totals = this.repGetGrossNetUif(clocks, this.state.Clocks);


        return (
            <table className="table tbSiteReport">
                <tbody>
                <tr>
                    <th rowSpan={2} className="vtable">Site Report</th>
                    <th className="tAlignRight">Employees</th>
                    <th className="tAlignRight">Payees</th>
                    <th className="tAlignRight">GrossPay</th>
                    <th className="tAlignRight">PPH Pay</th>
                    <th className="tAlignRight">UIF</th>
                    <th className="tAlignRight">Net Pay</th>
                    <th className="tAlignRight">No Pay</th>
                </tr>
                <tr>
                    <td className="tAlignRight">{totEmployee}</td>
                    <td className="tAlignRight">{totPayees}</td>
                    <td className="tAlignRight">{totals.grosspay}</td>
                    <td className="tAlignRight">{totals.pph}</td>
                    <td className="tAlignRight">{totals.uif}</td>
                    <td className="tAlignRight">{totals.netpay}</td>
                    <td className="tAlignRight">{(totEmployee - totPayees)}</td>
                </tr>
                </tbody>
            </table>
        )

    }

    renderFilterPeriodReportRow(repType) {

        let ClockData = [];
        let BasicData = null;
        if (repType === "site") {
            BasicData = this.state.ClockBasicReport;
            ClockData = this.state.Clocks;
        }
        if (repType === "period") {
            BasicData = this.state.ClockBasicReportContract;
            ClockData = this.state.ClocksContract;
        }

        let clocks = [];
        for (let i in BasicData) {
            let row = BasicData[i];
            clocks.push(row);
        }

        let totEmployee;
        let totPayees;

        //todo find total employees by site
        totEmployee = this.repGetTotalEmployeesBySite(this.state.selectedSiteReport, this.state.PeoplesSite);

        //todo get total payees from tagert clocks
        totPayees = this.repGetTotalPayees(clocks);

        //todo find total grosspay netpay and uif
        let totals = this.repGetGrossNetUif(clocks, ClockData);


        return (
            <table className="table tbSiteReport">
                <tbody>
                <tr>
                    <th rowSpan={2} className="vtable">Period Report</th>
                    <th className="tAlignRight">Employees</th>
                    <th className="tAlignRight">Payees</th>
                    <th className="tAlignRight">GrossPay</th>
                    <th className="tAlignRight">PPH Pay</th>
                    <th className="tAlignRight">UIF</th>
                    <th className="tAlignRight">Net Pay</th>
                    <th className="tAlignRight">No Pay</th>
                </tr>
                <tr>
                    <td className="tAlignRight">{totEmployee}</td>
                    <td className="tAlignRight">{totPayees}</td>
                    <td className="tAlignRight">{totals.grosspay}</td>
                    <td className="tAlignRight">{totals.pph}</td>
                    <td className="tAlignRight">{totals.uif}</td>
                    <td className="tAlignRight">{totals.netpay}</td>
                    <td className="tAlignRight">{(totEmployee - totPayees)}</td>
                </tr>
                </tbody>
            </table>
        )

    }


    renderFilterSearchRow() {

        if (this.state.selectedPaycycleReport === "") {
            return "";
        }
        let start, end, period, paydate;
        const arr = this.state.selectedPaycycleReport.split("--");
        period = arr[0];
        start = arr[1];
        end = arr[2];
        paydate = arr[3];

        return (
            <div>
                <span className="myFiterHeader">Filter</span>
                <table className="table grayTableHeader">
                    <thead>
                    <tr>
                        <th className="tAlignLeft">Site</th>
                        <th className="tAlignLeft">PayDate</th>
                        <th className="tAlignCenter">Period</th>
                        <th className="tAlignLeft">PayCycle</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="tAlignLeft">{this.state.selectedSiteReport}</td>
                        <td className="tAlignLeft">{paydate}</td>
                        <td className="tAlignCenter">{period}</td>
                        <td className="tAlignLeft">{"Start: " + start + " -> End: " + end}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        let myStyle = {
            overflow: 'visible',
        };
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="CLocking" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>

                                <div className="centerContent">

                                    <Tabs>
                                        <Tab label="Clock Report">
                                            <div>
                                                {/*<h2 style={styles.headline}>Clock Report
                                                    <span className="pull-right">

                                                    {this.convertMomentDateToStringISO(moment())}
                                                    </span>
                                                </h2>
                                                <p>
                                                    This report is depend on what your select as filter above.
                                                </p>*/}

                                                <div className="row">

                                                    <div className="col-lg-3">
                                                        <SelectField
                                                            floatingLabelText="Site"
                                                            name="selectedSiteReport"
                                                            onChange={this.handleChangeSiteReport}
                                                            value={this.state.selectedSiteReport}
                                                        >
                                                            {this.renderSiteOptionReport()}
                                                        </SelectField>
                                                    </div>

                                                    <div className="col-lg-7">
                                                        <SelectField
                                                            floatingLabelText="Pay Cycle"
                                                            name="selectedSiteReport"
                                                            onChange={this.handleChangePaycycle}
                                                            value={this.state.selectedPaycycleReport}
                                                            fullWidth={true}
                                                        >
                                                            {this.renderPaycycleOption()}
                                                        </SelectField>
                                                    </div>

                                                    <div className="col-lg-1">
                                                        <br/>
                                                        <RaisedButton
                                                            label="Search"
                                                            default={true}
                                                            style={style}
                                                            onClick={() => {
                                                                this.searchClock();
                                                            }}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col col-lg-12">
                                                        {this.renderFilterSearchRow()}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col col-lg-12">
                                                        {this.renderFilterSiteReportRow("site")}
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col col-lg-12">
                                                        {this.renderFilterPeriodReportRow("period")}
                                                    </div>
                                                </div>


                                                <div className="row">

                                                    <div className="col col-lg-12">
                                                        <br/><br/>


                                                        {this.renderDayClockReport()}

                                                        {this.renderShowClock()}

                                                    </div>
                                                </div>

                                                <br/>
                                                <br/>
                                                {/*<Slider name="slider0" defaultValue={0.3} />*/}
                                            </div>
                                        </Tab>
                                        <Tab label="Analytic">
                                            <div>
                                                <h2 style={styles.headline}>Analytic</h2>
                                                <p>
                                                    This is another example tab.
                                                </p>

                                            </div>
                                        </Tab>
                                        <Tab
                                            label="Notification"
                                            data-route="/home"
                                            onActive={handleActive}
                                        >
                                            <div>
                                                <h2 style={styles.headline}>Notification</h2>
                                                <p>
                                                    This is a third example tab.
                                                </p>
                                            </div>
                                        </Tab>
                                    </Tabs>


                                </div>

                            </Paper>
                        </div>

                    </div>


                </div>
            </MuiThemeProvider>
        );
    }
}

export default MyContainer;


function convertDateFormat_DDMMYYYY(dateIn) {
    let mydate = moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
    return mydate;
}

function handleActive(tab) {
    alert(`A tab with this route property ${tab.props['data-route']} was activated.`);
}

/**
 * Created by dev1 on 2017/01/13.
 */


import React from 'react';


class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
        <span className="text-left">
            <a href="http://coreui.io">Epwp</a> © 2017 lab.
        </span>
        <span className="float-xs-right">
            Powered by <a href="http://dikamba.co.za">Mr B.</a>
        </span>
            </footer>
        )
    }
}

export default Footer;

/**
 * Created by dev1 on 2017/01/14.
 */

import React from 'react';

import eConfig from '../epwp_config.json';
import {getApiXhrJSON2} from '../api/xhr';
import '../asset/styles/emailConfirmation.css';
import '../asset/styles/login-page.css';

const serverBiaUMS =  eConfig.serverEpwp;

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            username: "none",
            pwd: "none",
            hasLogin: false,
            token: "none"
        };
    }

    myStatusChange(e) {
        var fieldName = e.target.name;
        var fieldValue = e.target.value;
        if(fieldName ==="username"){
            this.setState({"username":fieldValue});
        }
        if(fieldName ==="pwd"){
                this.setState({"pwd":fieldValue});
        }
        //this.displayState();
        // this.state[fieldName] = fieldValue;
    }

    displayState() {
        console.log('DISPLAY STATE > ', this.state);
    }

    loginMe(e) {
        e.preventDefault()
        var url = serverBiaUMS + "/backend-pmis/auth/login";

        let hub={
            username:this.state.username,
            password: this.state.pwd
        };

        /*getApiXhrJSON2({
            url: url,
            success: this.onSuccess,
            error: this.onErrer,
            complete: this.onComplete
        }, "get", {});*/

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {


                console.log("): SUCCESS LOGIN FEEDBACK 1 > ",responseData,responseData.token);
                if(responseData.status ==="OK"){
                    window.localStorage.setItem("token",responseData.token);
                    window.localStorage.setItem("authorization",responseData.token);
                    window.localStorage.setItem("@pmis-user",JSON.stringify(responseData.user));
                    window.location.href = "#/dashboard";
                }else{
                    console.log("FAIL LOGIN FEEDBACK > ", responseData);
                    alert("FAIL TO LOGIN PLEASE TRY AGAIN ");
                }


            }).catch((err)=>{
            console.log("Conection to the server issue > ", err);
            alert("Connection to the server issue TRY AGAIN ");
        });
    }

    onSuccess(e) {
        console.log("): SUCCESS LOGIN FEEDBACK 1 > ",e.token, e);
        window.localStorage.setItem("token",e.token);
        window.localStorage.setItem("authorization",e.token);
        window.location.href = "#/dashboard";

    }

    onErrer(e) {
        console.log("FAIL LOGIN FEEDBACK > ", e);
    }
    onComplete(e){

    }

    onSuccessTokenInfo(eData){
        console.log(":(): SUCCESS TokenInfo FEEDBACK > ", eData);
        localStorage.setItem("epwp_user",JSON.stringify(eData));

            /*for(var i in eData){
                var key =i;
                var val  = eData[i];
                localStorage.setItem(key,val);
            }*/

    }
    onErrerTokenInfo(e) {
        console.log("FAIL TokenInfo FEEDBACK > ", e);
    }

    render() {
        return (
            <div>
                <div className="topMenuLevel1Login">
                    <img className="circle2" width={50} height={30} src="http://biadebt.easipath.com:33332/download/rev/file/225d84f3d1/logo-white.jpeg" />
                </div>





            <div className="container d-table pushTop" >
                <div className="d-100vh-va-middle ">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="card-group">
                                <div className="card p-2">
                                    <div className="card-block pushTop">
                                        <h1>Welcome to PMIS</h1>
                                        <p className="text-muted">&nbsp;</p>
                                        <div className="input-group mb-1">
                                <span className="input-group-addon"><i className="icon-user"></i>
                                </span>
                                            <input type="text" className="form-control" placeholder="Email"
                                                   name="username" onChange={this.myStatusChange.bind(this)}/>
                                        </div>
                                        <div className="input-group mb-2">
                                <span className="input-group-addon"><i className="icon-lock"></i>
                                </span>
                                            <input type="password" className="form-control" placeholder="Password"
                                                   name="pwd" onChange={this.myStatusChange.bind(this)}/>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-6">
                                                <button type="button" className="btn btn-primary px-2" onClick={this.loginMe.bind(this)}>Login</button>
                                            </div>
                                            <div className="col-xs-6 text-xs-right">
                                                <button type="button" className="btn btn-link px-0">Forgot password?
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-inverse card-primary py-3 hidden-md-down"
                                     style={{"width":"44%"}}>
                                    <div className="card-block text-xs-center">
                                        <div>
                                            <h2>EPWP Management & Reporting</h2>
                                            <p>Stay on to of EPWP contracts with our integrated management system which uses custom-developed processes and
                                            information to productively and efficiently manage your programme payroll</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                <div className="footer x-footer">
                    <div className="">

                        <p className="center try-xero">
                            Don't have a login? <a href="#/register">Register Now!</a>
                        </p>

                    </div>
                </div>

            </div>

        );
    }
}

export default LoginContainer;
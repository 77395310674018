import React from 'react';
import TopMenu from '../component/common/top-nav-new';

import '../asset/styles/setting-menu.css';

class SettingMenuPage extends React.Component {
    render() {
        return (
            <div className="">
                <TopMenu page="General Setting" menu={this.props.location.pathname}/>

                <div className="container settingMenu">
                    <h3 className="sectionBlock">Organistion</h3>
                    <div className="myline">&nbsp;</div>
                    <p>
                        <a
                            onClick={()=>{window.location.href="#/setting-organisation"}}
                            className=" clickMe menuTitle">
                            <i className={" glyphicon glyphicon-star"}></i> <span >Organisation Setting</span>
                        </a><br/>
                        <span className="menuDetail ">Edit your organisation detail</span>
                    </p>
                    <p>
                        <a onClick={()=>{window.location.href="#/setting-contract"}} className="menuTitle clickMe">
                            <i className={" glyphicon glyphicon-star"}></i> <span >Contract Setup</span>
                        </a><br/>
                        <span className="menuDetail">Setup your specific EPWP contact</span>
                    </p>
                    <p>
                        <a onClick={()=>{window.location.href="#/setting-users"}} className="menuTitle clickMe">
                            <i className={" glyphicon glyphicon-star"}></i> <span >
                            Users</span></a><br/>
                        <span className="menuDetail">Manage who has access to your contracts</span>
                    </p>


                    <h3 className="sectionBlock">Compliance</h3>
                    <div className="myline">&nbsp;</div>
                    <p>
                        <a
                            onClick={()=>{window.location.href="#/setting-compliance-criteria"}}
                            className=" clickMe menuTitle">
                            <i className={" glyphicon glyphicon-star"}></i> <span >Compliance Setting</span>
                        </a><br/>
                        <span className="menuDetail ">Setup specific compliance criteria</span>
                    </p>




                </div>
            </div>

        )
    }
}


export default SettingMenuPage;
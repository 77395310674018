/**
 * Created by dev1 on 2017/01/13.
 */
import React from 'react';
import TokenService from '../api/tokeninfo';
import CompDashBox from "../component/dash-box";
import CompPageHeaderAddList from "../component/page-header-add-list";
import Footer from "../component/common/footer";
import CompHeader from "../component/common/top-nav";
import CompNavInfo from "../component/nav-info";

class HomeContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: "",
            username: ""
        };


    }

    componentWillMount() {
        var tInfo = TokenService.GetTokenObject();
        this.setState({
            OrgCode: tInfo.OrgCode,
            OrgName: tInfo.OrgName
        });
        // this.setState({OrgCode: tInfo.OrgCode, OrgName: tInfo.OrgName});
        /*LoadEmployeeList(this.props.dispatch);
        LoadUserStatus(this.props.dispatch);*/
    }

    repTotEmployees() {
        let tot = 0;
        /*for(var i in this.props.Employees){
            if(this.props.Employees[i].Orgcode===this.state.OrgCode){
                tot++;
            }
        }*/
        return tot;
    }

    repTotOnlineEmployee() {
        let tot = 0;
        return tot;
    }

    repTotOfflineEmployee() {
        let tot = 0;
        return tot;
    }

    render() {
        return (
            <div>
              <CompHeader/>
                <main className="main" >

                    <CompNavInfo mainmenu={"Home"} pagename={"home"} pagelink={"#/"}/>


                    <div className="container-fluid">

                        <div className="animated fadeIn">

                            <div className="card">
                                <div className="card-block">
                                    <div className="row">
                                        <CompPageHeaderAddList
                                            pagename={"Home"}
                                            pagedesc={"Entry level, your employee report"}
                                            pagelink={"#/home"}
                                        />
                                    </div>
                                    <div className="row">
                                        <hr/>
                                    </div>
                                    <div className="chart-wrapper" style={{"height": "300px", "marginTop": "10px"}}>

                                        Home Page content here
                                    </div>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>


                        </div>


                    </div>

                </main>

                <Footer/>

            </div>
        );
    }
}


export default HomeContainer;

import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import React from 'react';
import ReactDOM from 'react-dom'
import TokenService from '../api/tokeninfo';
//import CompDashBox from "../component/dash-box";
import CompPageHeaderAddList from "../component/page-header-add-list";
import Footer from "../component/common/footer";
import CompHeader from "../component/common/top-nav";
import CompNavInfo from "../component/nav-info";
import eConfig from '../epwp_config.json';
import MyComponentModal from "../component/common/modal-bootstrap";
import CustomInsertModal from '../component/insertEmployeeModal';
import MyComponentModalChangeStatus from "../component/employee/modal-change-status";

const serverEpwp = eConfig.serverEpwp;


class employeeContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},
            Employees: [],
            Contracts: [],
            mycontract: "",
            currentEmployee: {},
            openclose: false,
            openclosestatus: false,


        };


        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchEmployees = this.fetchEmployees.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.renderEmployees = this.renderEmployees.bind(this);
        this.actionViewEmployee = this.actionViewEmployee.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.setViewChange = this.setViewChange.bind(this);
        this.renderNewEmployeeForm = this.renderNewEmployeeForm.bind(this);
        this.cancelAddForm = this.cancelAddForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.addEmployeeSave = this.addEmployeeSave.bind(this);
        this.terminateEmployee = this.terminateEmployee.bind(this);
        this.openModalTerminateEmployee = this.openModalTerminateEmployee.bind(this);
        this.closeModalTerminalEmployee = this.closeModalTerminalEmployee.bind(this);
    }

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchEmployees();
        this.fetchContracts();
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contract/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Contracts", _this);
        console.log("--)Contracts(--> ", this.Contracts);
    }

    fetchEmployees() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Employees", _this);

        let emp = [];

        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            row.Id = parseInt(row.Id);
            emp.push(row);
        }
        let tmp = this.state;
        tmp.Employees = emp;
        this.setState(tmp);
        console.log("--)(--> ", this.state);

    }

    setViewChange(val) {
        let tmp = this.state;
        tmp.view = val;
        this.setState(tmp);
    }

    openModal() {
        var tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }

    openModalTerminateEmployee() {
        var tmp = this.state;
        tmp.openclosestatus = true;
        this.setState(tmp);

    }

    closeModal() {
        var tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }

    closeModalTerminalEmployee() {
        var tmp = this.state;
        tmp.openclosestatus = false;
        this.setState(tmp);
    }


    actionViewEmployee(row) {
        let tmp = this.state;
        tmp.currentEmployee = row;
        this.setState(tmp);
        this.openModal()
        // alert("View company request popup");

    }

    cancelAddForm() {
        let el = window.document.getElementById("fmSubmit");
        el.reset();
        this.setState({view: "list"});
    }

    addEmployeeSave(row) {

        let newRowStr = "";
        for (const prop in row) {
            newRowStr += prop + ': ' + row[prop] + ' \n';
        }
        alert('The new employee is:\n ' + newRowStr);

        let endpoint = "/employee/new";
        let url = serverEpwp + endpoint;
        let hub = row;
        hub.company = this.state.company;
        hub.username = this.state.username;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.status === "SUCCESS") {
                    alert("SUCCESS Adding new employee ");
                    this.fetchEmployees();
                    return
                }
                alert("FAILED to add new employee error: " + responseData.error);
                //this.setState({view: "list"});
            });

    }

    submitForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you sure that you want to submit this form?")
        if (!text) {
            return;
        }


        let endpoint = "/employee/csv";
        let url = serverEpwp + endpoint;
        var inputUpload = document.getElementById("myfile");
        var myfile = inputUpload.files[0];
        if (!myfile) {
            alert("No file selected.");
            return;
        }

        const filename = myfile.name;


        let hub = {};
        hub = this.state;
        hub.FileName = filename;
        hub.Contract = this.state.mycontract;
        hub.company = this.state.company;

        var reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            hub.Base64String = reader.result;

            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hub)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    alert("SUCCESS Uploaded you will get feedback on your email  ");
                    this.setState({view: "list"});
                });

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


        console.log("CALL TO API > ", this.state.feedbackSubmit);
        this.fetchEmployees();
    }

    renderNewEmployeeForm() {
        let _this = this;
        if (this.state.view !== "add") {
            return "";
        }
        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    Import Employee Csv
                </label>

                <form className={"form"} onSubmit={this.submitForm} id={"fmSubmit"}>
                    {this._render2ColInput("Csv File", "myfile", "file", "", true,
                        "", "", "", "", true, _this)}

                    <div className="form-row">
                        <div className={"form-group col-md-" + 12}>
                            <div className={""}>
                                <label>Contact:</label>
                                <select
                                    className="form-control"
                                    id={"mycontract"}
                                    name={"mycontract"}
                                    ref={"mycontract"}
                                    onChange={_this._handleInput}
                                    required={true}
                                >
                                    <option value={""}> --Select Contract--</option>
                                    {
                                        this.state.Contracts.map((row) => {
                                            return ( <option value={row.Name}>{row.Name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className={"btn btn-primary pull-right"}>Submit</button>
                    &nbsp;&nbsp;
                    <button type={"button"} className={"btn btn-dander pull-right"} onClick={() => {
                        this.cancelAddForm()
                    }}>Cancel
                    </button>
                </form>


            </div>
        )


    }

    renderEmployees() {
        if (this.state.view !== "list") {
            return "";
        }
        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    Available Employees List (<span className="badge"> {this.state.Employees.length}</span>)
                </label>

                <table className={"table"} style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Code</th>
                        <th>Fullname</th>
                        <th>IDNo</th>
                        <th>Site</th>
                        <th>Gender</th>
                        <th>Designation</th>
                        <th>Created</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.state.Employees.map((row) => {
                        return (
                            <tr key={row.Id}>
                                <td>{row.EmployeeCode}</td>
                                <td>{row.Name} {row.Surname}</td>
                                <td>{row.EmployeeIDNo}</td>
                                <td>{row.Site}</td>
                                <td>{row.Gender}</td>
                                <td>{row.Designation}</td>
                                <td>{row.OrgDateTime}</td>
                                <td>
                                    <button className={"clickMe btn btn-primary"} onClick={() => {
                                        this.actionViewEmployee(row)
                                    }}>View
                                    </button>
                                    &nbsp;
                                </td>
                                <td>
                                    <button className={"clickMe btn btn-danger"} onClick={() => {
                                        alert("Feature not implemented")
                                    }}>Delete
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    createCustomModal(onModalClose, onSave, columns, validateState, ignoreEditable) {
        const attr = {
            onModalClose, onSave, columns, validateState, ignoreEditable
        };
        return (
            <CustomInsertModal {...attr} />
        );
    }

    popupTerminateForm(row) {
        let tmp = this.state;
        tmp.currentEmployee = row;
        this.setState(tmp);
        this.openModalTerminateEmployee()
        //alert("PopUp Terminateion "+JSON.stringify(row));
    }

    terminateEmployee(cell, row) {

        return (<button type="button" className={"btn btn-default"} onClick={() => {
            this.popupTerminateForm(row)
        }}>Status</button>);
        //alert("Choose to Terminate employee: "+row.EmployeeCode);
    }

    render() {

        let data = this.state.Employees;
        const selectRowProp = {
            mode: 'radio',
            clickToSelect: true  // enable click to select
        };


        const options = {
            defaultSortName: 'Id',
            defaultSortOrder: 'asc',
            paginationSize: 20,
            actionViewEmployee: this.actionViewEmployee,
            addEmployee: this.addEmployeeSave,
            /*onRowClick: function (row, columnIndex, rowIndex, e) {
                console.log(`You click row id: ${row.EmployeeCode}, column index: ${columnIndex}, row index: ${rowIndex}`);
                this.actionViewEmployee(row);
                console.log(e);
            },*/
            onRowDoubleClick: function (row, e) {
                this.actionViewEmployee(row);
                console.log(e);
            },
            clearSearch: true,
            searchField: (props) => (<MySearchField {...props}/>),
            onAddRow: this.addEmployeeSave,
            insertModal: this.createCustomModal,
        };


        return (
            <div>
                <CompHeader/>
                <main className="main">

                    <CompNavInfo mainmenu={"Admin"} pagename={"Employee"} pagelink={"#/employee"}/>


                    <div className="container-fluid">

                        <div className="animated fadeIn">

                            <div className="card">
                                <div className="card-block">
                                    <div className="row">
                                        <CompPageHeaderAddList
                                            pagename={"Employee"}
                                            pagedesc={"Super user only access,you can add or disable"}
                                            pagelink={"#/employee"}
                                            setViewChange={this.setViewChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <hr/>
                                    </div>
                                    <div className="chart-wrapper" style={{
                                        "minHeight": "300px",
                                        "marginTop": "10px",
                                        "padding": "15px",
                                        "overflow": "scroll"
                                    }}>

                                        <div className="Card-Container">
                                            <BootstrapTable
                                                data={data}
                                                striped={false}
                                                hover={true}
                                                pagination
                                                selectRow={selectRowProp}
                                                options={options}
                                                insertRow deleteRow exportCSV
                                                search
                                            >
                                                <TableHeaderColumn dataField="Id" isKey={true} dataSort
                                                                   sortFunc={numericSortFunc} hidden={true}
                                                                   filter={{
                                                                       type: 'TextFilter',
                                                                       delay: 1000
                                                                   }}>ID</TableHeaderColumn>
                                                <TableHeaderColumn dataField="EmployeeCode"
                                                                   filter={{
                                                                       type: 'TextFilter',
                                                                       delay: 1000
                                                                   }}>Code</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Name" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }}>Name</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Surname" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }}>Surname</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Designation"
                                                                   filter={{type: 'TextFilter', delay: 1000}}>Designation</TableHeaderColumn>
                                                <TableHeaderColumn dataField="DailyRate"
                                                                   filter={{type: 'TextFilter', delay: 1000}}
                                                                   hidden={true}>Daily
                                                    Rate</TableHeaderColumn>
                                                <TableHeaderColumn dataField="EmployeeIDNo"
                                                                   filter={{type: 'TextFilter', delay: 1000}}>Employee
                                                    ID No</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Site" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }}>Site</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Address" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }}>Address</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Gender" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }}>Gender</TableHeaderColumn>
                                                <TableHeaderColumn dataField="Bank" filter={{
                                                    type: 'TextFilter',
                                                    delay: 1000
                                                }} hidden={true}>Bank</TableHeaderColumn>
                                                <TableHeaderColumn dataField="AccountNumber"
                                                                   filter={{type: 'TextFilter', delay: 1000}}
                                                                   hidden={true}>Account
                                                    Number</TableHeaderColumn>
                                                <TableHeaderColumn dataField="BranchCode"
                                                                   filter={{type: 'TextFilter', delay: 1000}}
                                                                   hidden={true}>Branch
                                                    Code</TableHeaderColumn>
                                                <TableHeaderColumn dataField="PaymentMethod"
                                                                   filter={{type: 'TextFilter', delay: 1000}}
                                                                   hidden={true}>Payment
                                                    Method</TableHeaderColumn>
                                                <TableHeaderColumn dataField="CommencementDate"
                                                                   filter={{type: 'TextFilter', delay: 1000}}>CommencementDate</TableHeaderColumn>

                                                <TableHeaderColumn dataField="button"
                                                                   dataFormat={this.terminateEmployee}>#</TableHeaderColumn>


                                            </BootstrapTable>
                                        </div>


                                        {/*this.renderEmployees()*/}

                                        {this.renderNewEmployeeForm()}

                                    </div>
                                </div>
                                <div className="card-footer">
                                    <MyComponentModal
                                        obectname={"Employee Detail [" + this.state.currentEmployee.Name + "]"}
                                        openclose={this.state.openclose}
                                        close={this.closeModal}
                                        open={this.openModal}
                                        data={this.state.currentEmployee}
                                    />

                                    <MyComponentModalChangeStatus
                                        obectname={"[" + this.state.currentEmployee.Name + "]"}
                                        openclose={this.state.openclosestatus}
                                        close={this.closeModalTerminalEmployee}
                                        open={this.openModalTerminateEmployee}
                                        data={this.state.currentEmployee}
                                    />
                                </div>
                            </div>


                        </div>


                    </div>

                </main>

                <Footer/>

            </div>
        );
    }

}

export default employeeContainer;

class MySearchField extends React.Component {
    // It's necessary to implement getValue
    getValue() {
        return ReactDOM.findDOMNode(this).value;
    }

    // It's necessary to implement setValue
    setValue(value) {
        ReactDOM.findDOMNode(this).value = value;
    }

    render() {
        return (
            <input
                className={`form-control`}
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onKeyUp={this.props.search}/>
        );
    }
}

function numericSortFunc(a, b, order) {
    if (order === 'desc') {
        return Number(b.Id) - Number(a.Id);
    } else {
        return Number(a.Id) - Number(b.Id);
    }
}


/**
 * Created by dev1 on 2017/01/16.
 */
import React from 'react';
import {Link} from 'react-router-dom';
import eConfig from '../epwp_config.json';
import {getApiXhrJSON2} from '../api/xhr';
const serverBiaUMS =  eConfig.serverBiaUMS;

class LogoutContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            username: "none",
            pwd: "none",
            hasLogin: false,
            token: "none"
        };
    }


    logoutMe() {

        var url = serverBiaUMS + "/auth/logout/" + localStorage.getItem("token") ;
        getApiXhrJSON2({
            url: url,
            success: this.onSuccess,
            error: this.onErrer,
            complete: this.onComplete
        }, "get", {});
    }

    componentWillUpdate(nextProps){
        console.log("Your about to logout from EPWP :( ");
        this.logoutMe();
    }


    render() {
        return (

            <div className="container d-table">
                <div className="d-100vh-va-middle">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="card-group">
                                <div className="card p-2">
                                    <div className="card-block">

                                        <div className="alert alert-danger">
                                            <h2>You are logged out of the system</h2>
                                            <p>Click <Link to="/login">here to login </Link></p>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

}

export default LogoutContainer;
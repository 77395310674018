import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';

import React from 'react';
import TokenService from '../api/tokeninfo';
//import CompDashBox from "../component/dash-box";
import CompPageHeaderAddList from "../component/page-header-add-list";
import Footer from "../component/common/footer";
import CompHeader from "../component/common/top-nav";
import CompNavInfo from "../component/nav-info";
import eConfig from '../epwp_config.json';
import MyComponentModal from "../component/common/modal-bootstrap";
import StepZilla from 'react-stepzilla';

import Step1 from '../component/contract/Step_info';
import Step2 from '../component/contract/Step_period';
import Step3 from '../component/contract/Step_rate';
import Step4 from '../component/contract/Step_site';
import Step5 from '../component/contract/Step_summary';
import Step6 from '../component/contract/Step_finish';

import '../asset/css/step.css';

import $ from 'jquery';


const serverEpwp = eConfig.serverEpwp;


class contractContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            openclose: false,
            email: '',
            gender: '',
            savedToCloud: false,
            name: '',
            description: '',
            province: '',
            municipality: '',
            startdate: '',
            enddate: '',
            paycircle: "",
            rates: [
                {typeOfRate: "employee", designation: "Worker", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "chargeOut", designation: "Worker", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "employee", designation: "Worker leader", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "chargeOut", designation: "Worker leader", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "employee", designation: "Supervisor", normal: 0, saturday: "", sunday: "", phd: ""},
                {typeOfRate: "chargeOut", designation: "Supervisor", normal: 0, saturday: "", sunday: "", phd: ""},
            ],
            sites: [],
            PayAfter :0,
            WeekPayDay :"friday",
            WorkOnSaturday :"false",
            WorkOnSunday :"false",
            RateTypeEmployee:"daily",
            RateTypeChargeOut:"daily",
            feedback:{}
        };

        /*this.sampleStore = {
            email: '',
            gender: '',
            savedToCloud: false,
            name: '',
            description: '',
            province: '',
            municipality: '',
            startdate: '',
            enddate: '',
            paycircle: "",
            rates: [
                {typeOfRate: "employee", designation: "Worker", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "chargeOut", designation: "Worker", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "employee", designation: "Worker leader", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "chargeOut", designation: "Worker leader", normal: 0, saturday: 0, sunday: 0, phd: 0},
                {typeOfRate: "employee", designation: "Supervisor", normal: 0, saturday: "", sunday: "", phd: ""},
                {typeOfRate: "chargeOut", designation: "Supervisor", normal: 0, saturday: "", sunday: "", phd: ""},
            ],
            sites: [],
        };*/

        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);
        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.renderStep = this.renderStep.bind(this);
        this.setViewChange = this.setViewChange.bind(this);
        this.submitForm = this.submitForm.bind(this);


    }

    componentWillMount(){
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);

    }
    componentDidMount(){
        $(window.document).ready(function () {
            var navListItems = $('div.setup-panel div a'),
                allWells = $('.setup-content'),
                allPrevBtn = $('.prevBtn'),
                allNextBtn = $('.nextBtn');

            allWells.hide();

            navListItems.click(function (e) {
                e.preventDefault();
                var $target = $($(this).attr('href')),
                    $item = $(this);

                if (!$item.hasClass('disabled')) {
                    navListItems.removeClass('btn-primary').addClass('btn-default');
                    $item.addClass('btn-primary');
                    allWells.hide();
                    $target.show();
                    $target.find('input:eq(0)').focus();
                }
            });

            allNextBtn.click(function () {
                var curStep = $(this).closest(".setup-content"),
                    curStepBtn = curStep.attr("id"),
                    nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
                    curInputs = curStep.find("input[type='text'],input[type='url']"),
                    isValid = true;

                $(".form-group").removeClass("has-error");
                for (var i = 0; i < curInputs.length; i++) {
                    if (!curInputs[i].validity.valid) {
                        isValid = false;
                        $(curInputs[i]).closest(".form-group").addClass("has-error");
                    }
                }

                if (isValid)
                    nextStepWizard.removeAttr('disabled').trigger('click');
            });


            allPrevBtn.click(function () {
                var curStep = $(this).closest(".setup-content");
                var  curStepBtn = curStep.attr("id");
                var num = curStepBtn.replace("step-","");
                var nextStepWizard = $('div.setup-panel div a[href="#step-' + (parseInt(num)-1) + '"]');
                nextStepWizard.trigger('click');

            });


            //todo let initialise our first step
            let currStep = $('div.setup-panel div a[href="#step-1"]');
            console.log("CurrentStep > ", currStep);
            $(currStep).click();

            /*currStep.initEvent( 'click', true, true );
            currStep.dispatchEvent(currStep);*/


        });
    }

    getStore() {
        return this.state;
    }

    updateStore(update) {
        this.setState({
            ...this.state,
            ...update,
        });

        //console.log("Update store receive > ",update,this.state );
    }


    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    setViewChange(val) {
        let tmp = this.state;
        tmp.view = val;
        this.setState(tmp);
    }

    openModal() {
        var tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }

    closeModal() {
        var tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }

    renderStep() {

        let arr = ["Info", "Period", "Rate", "Site", "Summary"];
        let x = 0;
        return arr.map((row) => {
            x++;
            return (
                <div className="stepwizard-step">
                    <a href={"#step-" + x} type="button" className="btn btn-primary btn-circle "><span
                        className="color14"> {x}</span></a>
                    <p>{row}</p>
                </div>
            )
        })

    }

    submitForm(e){
        e.preventDefault();

        let endpoint = "/api/contract/new";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub = {...this.state};
        let _this = this;
        this.loadList(url, hub, "feedback", _this);
        console.log("--)(--> ", this.state);
        alert("Thank you, your New Contract have been submitted");
        window.location.reload();

    }

    renderStepTaps() {
        return (
            <div className="container">
                <div className="row">
                    <section>
                        <div className="wizard">
                            <div className="wizard-inner">
                                <div className="connecting-line"></div>
                                <ul className="nav nav-tabs" role="tablist">

                                    <li role="presentation" className="active">
                                        <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab"
                                           title="Step 1">
                            <span className="round-tab">
                                <i className="glyphicon glyphicon-folder-open"></i>
                            </span>
                                        </a>
                                    </li>

                                    <li role="presentation" className="disabled">
                                        <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab"
                                           title="Step 2">
                            <span className="round-tab">
                                <i className="glyphicon glyphicon-pencil"></i>
                            </span>
                                        </a>
                                    </li>
                                    <li role="presentation" className="disabled">
                                        <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab"
                                           title="Step 3">
                            <span className="round-tab">
                                <i className="glyphicon glyphicon-picture"></i>
                            </span>
                                        </a>
                                    </li>

                                    <li role="presentation" className="disabled">
                                        <a href="#complete" data-toggle="tab" aria-controls="complete" role="tab"
                                           title="Complete">
                            <span className="round-tab">
                                <i className="glyphicon glyphicon-ok"></i>
                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <form role="form">
                                <div className="tab-content">
                                    <div className="tab-pane active" role="tabpanel" id="step1">
                                        <h3>Step 1</h3>
                                        <p>This is step 1</p>
                                        <ul className="list-inline pull-right">
                                            <li>
                                                <button type="button" className="btn btn-primary next-step">Save and
                                                    continue
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane" role="tabpanel" id="step2">
                                        <h3>Step 2</h3>
                                        <p>This is step 2</p>
                                        <ul className="list-inline pull-right">
                                            <li>
                                                <button type="button" className="btn btn-default prev-step">Previous
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn btn-primary next-step">Save and
                                                    continue
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane" role="tabpanel" id="step3">
                                        <h3>Step 3</h3>
                                        <p>This is step 3</p>
                                        <ul className="list-inline pull-right">
                                            <li>
                                                <button type="button" className="btn btn-default prev-step">Previous
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className="btn btn-default next-step">Skip
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button"
                                                        className="btn btn-primary btn-info-full next-step">Save and
                                                    continue
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane" role="tabpanel" id="complete">
                                        <h3>Complete</h3>
                                        <p>You have successfully completed all steps.</p>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        )
    }


    render() {

            let _this =this;
        const steps =
            [
                {
                    name: 'Info', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
                },

                {
                    name: 'Period', component: <Step2 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
                },
                {
                    name: 'Rate', component: <Step3 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
                },
                {
                    name: 'Site', component: <Step4 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
                },
                {
                    name: 'Summary', component: <Step5 getStore={() => (this.getStore())} updateStore={(u) => {
                    this.updateStore(u)
                }}/>
                },
                /*{
               name: 'finish', component: <Step6 getStore={() => (this.getStore())} updateStore={(u) => {
                   this.updateStore(u)
               }}/>},*/


            ];

        return (
            <div>
                <CompHeader/>
                <main className="main">

                    <CompNavInfo mainmenu={"Admin"} pagename={"Contract"} pagelink={"#/contract"}/>


                    <div className="container-fluid">

                        <div className="animated fadeIn">

                            <div className="card">
                                <div className="card-block">
                                    <div className="row">
                                        <CompPageHeaderAddList
                                            pagename={"Contract"}
                                            pagedesc={"Super user only access,you can add or disable"}
                                            pagelink={"#/contract"}
                                            setViewChange={this.setViewChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <hr/>
                                    </div>
                                    <div className="chart-wrapper"
                                         style={{
                                             "minHeight": "300px",
                                             "marginTop": "10px",
                                             "padding": "15px",
                                             "overflow": "auto"
                                         }}>




                                        {/*<div className='example'>
                                            <div className='step-progress'>
                                                <StepZilla
                                                    steps={steps}
                                                    preventEnterSubmission={true}
                                                    nextTextOnFinalActionStep={"Save"}
                                                    hocValidationAppliedTo={[3]}
                                                    startAtStep={window.sessionStorage.getItem('step') ? parseFloat(window.sessionStorage.getItem('step')) : 0}
                                                    onStepChange={(step) => window.sessionStorage.setItem('step', step)}
                                                />
                                            </div>
                                        </div>*/}


                                        <div className="stepwizard col-md-offset-3 centerNavigation">
                                            <div className="stepwizard-row setup-panel">
                                                {this.renderStep()}
                                            </div>
                                        </div>

                                        <form className={"container"} onSubmit={this.submitForm}>

                                            <div className="row setup-content" id="step-1">

                                                <Step1 getStore={() => (this.getStore())} updateStore={(u) => {
                                                    this.updateStore(u)
                                                }}/>

                                                <button className="btn btn-primary nextBtn btn-lg pull-right"
                                                        type="button">Next
                                                </button>
                                            </div>

                                            <div className="row setup-content" id="step-2">

                                                <Step2 getStore={() => (this.getStore())} updateStore={(u) => {
                                                    this.updateStore(u)
                                                }}/>

                                                <button className="btn btn-primary nextBtn btn-lg pull-right"
                                                        type="button">Next
                                                </button>
                                                <button className="btn btn-primary prevBtn btn-lg pull-left"
                                                        type="button">Previous
                                                </button>

                                            </div>

                                            <div className="row setup-content" id="step-3">

                                                <Step3 getStore={() => (this.getStore())} updateStore={(u) => {
                                                    this.updateStore(u)
                                                }}/>

                                                <button className="btn btn-primary nextBtn btn-lg pull-right"
                                                        type="button">Next
                                                </button>
                                            </div>

                                            <div className="row setup-content" id="step-4">

                                                <Step4 getStore={() => (this.getStore())} updateStore={(u) => {
                                                    this.updateStore(u)
                                                }}/>

                                                <button className="btn btn-primary nextBtn btn-lg pull-right"
                                                        type="button">Next
                                                </button>
                                            </div>

                                            <div className="row setup-content" id="step-5">

                                                <Step5 data={this.state} getStore={() => (this.getStore())} updateStore={(u) => {
                                                    this.updateStore(u)
                                                }}/>

                                                <button className="btn btn-primary nextBtn btn-lg pull-right"
                                                        type="submit">Save
                                                </button>
                                            </div>


                                        </form>


                                    </div>
                                </div>
                                <div className="card-footer">
                                    {/*<MyComponentModal
                                        obectname={"Employee Detail [" + this.state.currentEmployee.Name + "]"}
                                        openclose={this.state.openclose}
                                        close={this.closeModal}
                                        open={this.openModal}
                                        data={this.state.currentEmployee}
                                    />*/}
                                </div>
                            </div>


                        </div>


                    </div>

                </main>

                <Footer/>

            </div>
        );
    }

    render2() {
        return (
            <div className="container">

                <div className="stepwizard col-md-offset-3">
                    <div className="stepwizard-row setup-panel">
                        {this.renderStep()}
                    </div>
                </div>

            </div>
        )
    }


}


export default contractContainer;



import React from 'react';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';

const style = {
    display: 'inline-block',
    margin: '16px 32px 16px 0',
};

class SideMenuNav extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount(){

    }


    _handleClick(menuName) {
        console.log("SideMenuNav _handleClick > ",menuName);
        window.location.href = "#/"+menuName;
    }

    render() {


        return (
            <div>

                <Paper style={style}>
                    <Menu desktop={false} width={320}>
                        <MenuItem primaryText="Contract Dashboard"  onClick={()=>this._handleClick("dashboard-contract")} />
                        <MenuItem
                            rightIcon={<ArrowDropRight />}
                            primaryText="Employee Database"
                            menuItems={[
                                <MenuItem primaryText="View Employees" onClick={()=>this._handleClick("employee")}/>,
                                <MenuItem primaryText="Import CSV"  onClick={()=>this._handleClick("employee-csv")} />,
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            rightIcon={<ArrowDropRight />}
                            primaryText="Employee T & A"
                            menuItems={[
                                <MenuItem primaryText="Employees Clocking" onClick={()=>this._handleClick("clocking")}/>,
                                <MenuItem primaryText="Employees Clocking CSV" onClick={()=>this._handleClick("clocking-csv")}/>,
                                <MenuItem primaryText="Clocking Report"  onClick={()=>this._handleClick("clocking-report")} />,
                                <MenuItem primaryText="Qa Clocking"  onClick={()=>this._handleClick("qa-clock")} />,
                            ]}
                        />

                        <MenuItem
                            rightIcon={<ArrowDropRight />}
                            primaryText="Contract Reports"
                            menuItems={[
                                <MenuItem
                                    rightIcon={<ArrowDropRight />}
                                    primaryText="Payroll Payment with Submenu"
                                    menuItems={[
                                        <MenuItem primaryText="PayAcc Sys" onClick={()=>this._handleClick("contract-report-payroll-payment")}/>,
                                        <MenuItem primaryText="Procard"  onClick={()=>this._handleClick("contract-report-payroll-payment")} />,
                                    ]}
                                />,
                                <MenuItem primaryText="Payroll Payment" onClick={()=>this._handleClick("contract-report-payroll-payment")}/>,
                                <MenuItem primaryText="Payrool Invoice"  onClick={()=>this._handleClick("contract-report-payroll-invoice")} />,
                                <MenuItem primaryText="Payslip"  onClick={()=>this._handleClick("payslip")} />,
                            ]}
                        />

                    </Menu>
                </Paper>


            </div>
        );
    }

}


export default SideMenuNav;


import React from 'react';


class CompNavInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ol className="breadcrumb">
                <li className="breadcrumb-item systemname">EPWP Payrool</li>
                <li className="breadcrumb-item"><a href={this.props.pagelink}>{this.props.mainmenu}</a>
                </li>
                <li className="breadcrumb-item active">{this.props.pagename}</li>


                <li className="breadcrumb-menu">
                    <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                        <a className="btn btn-secondary" href="#"><i className="icon-speech"></i></a>
                        <a className="btn btn-secondary" href="./"><i className="icon-graph"></i> &nbsp;
                            Dashboard</a>
                        <a className="btn btn-secondary" href="#"><i className="icon-settings"></i> &nbsp;
                            Settings</a>
                    </div>
                </li>
            </ol>
        )
    }

}

export default CompNavInfo;
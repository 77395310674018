import React from 'react';
import {Router, Route, Switch} from 'react-router';
import createHistory from 'history/createHashHistory';
//import {Provider} from 'react-redux';
import HomeContainer from './container/homeContainer';
import LoginContainer from './container/loginContainer';
import LogoutContainer from './container/logoutContainer';
//import RessetPasswordContainer from './container/resetPasswordContainer';
import PageNotFoundContainer from './container/404Container';
//import Page501Container from './container/501Container';
import CompanyContainer from './container/companyContainer';
import EmployeeContainer from './container/employeeContainer';
import ContractContainer from './container/contractContainer';
import ContainerDemoPage from './container/design';
import ContainerLandingPage from './container/landingContainer';
import ContainerRegisterPage from './container/registerContainer';
import ContainerAccountVerification from './container/accountVerificationContainer';
import ContainerDashboardPage from './container/dashboardContainer';
import ContainerSettingOrganisation from './container/settingOrganisationContainer';
import ContainerSettingUsers from './container/settingUsersContainer';
import ContainerSettingContracts from './container/settingContractContainer';
import ContainerSettingEmployeeContracts from './container/settingEmployeeContract';
import ContainerSettingEmployeeCsvContracts from './container/settingEmployeeContract-csv';
import ContainerSettingMenu from './container/settingMenuContainer';
import ContainerClockling from './container/clockingInputContainer';
import ContainerClocklingCsv from './container/clockingInputCsvContainer';
import ContainerClocklingReportBasic from  './container/reportClockingBasic';
import ContainerContractDashboard from './container/reportContractDashboard';
import ContainerSettingCompliance from './container/containerComplianceCriteria';
import ContainerComplianceDocument from './container/containerComplianceDocument';
import ContainerReportPayrollPayment from './container/reportContractPayrollPayment';
import ContainerQaClock from './container/containerClockQa';
import ContainerPayslip from './container/payslipContainer';
import ContainerRecordCompliance from './container/recordDocsContainer';
import ContainerRecordComplianceEdocSign from './container/edocsSignContainer';

import ContainerPayslipTest from './container/reportContractPayslip-test';



import CEmail from './container/emailConfirmationMessage';
import RequireAuth from './api/requireAuth';
import './App.css';

const history = createHistory();


class App extends React.Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                <Route exact path="/" component={ContainerLandingPage}/>
                <Route path="/home" component={ContainerLandingPage}/>
                <Route path="/login" component={LoginContainer}/>
                <Route path="/logout" component={LogoutContainer}/>
                <Route path="/register" component={ContainerRegisterPage}/>
                <Route path="/dashboard" component={RequireAuth(ContainerDashboardPage)}/>
                <Route path="/company" component={RequireAuth(CompanyContainer)}/>
                <Route path="/old-employee" component={RequireAuth(EmployeeContainer)}/>
                <Route path="/contract" component={RequireAuth(ContractContainer)}/>
                <Route path="/design" component={ContainerDemoPage}/>
                <Route path="/landing" component={ContainerLandingPage}/>
                <Route path="/cemail" component={CEmail}/>
                <Route path="/account-verification/:info" component={ContainerAccountVerification}/>
                <Route path="/setting" component={RequireAuth(ContainerSettingMenu)}/>
                <Route path="/setting-organisation" component={RequireAuth(ContainerSettingOrganisation)}/>
                <Route path="/setting-users" component={RequireAuth(ContainerSettingUsers)}/>
                <Route path="/setting-contract" component={RequireAuth(ContainerSettingContracts)}/>
                <Route path="/employee" component={RequireAuth(ContainerSettingEmployeeContracts)}/>
                    <Route path="/employee-csv" component={RequireAuth(ContainerSettingEmployeeCsvContracts)}/>
                <Route path="/manage-contract" component={RequireAuth(ContainerSettingContracts)}/>
                <Route path="/clocking" component={RequireAuth(ContainerClockling)}/>
                <Route path="/clocking-csv" component={RequireAuth(ContainerClocklingCsv)}/>
                    <Route path="/clocking-report" component={RequireAuth(ContainerClocklingReportBasic)}/>
                <Route path="/dashboard-contract" component={RequireAuth(ContainerContractDashboard)}/>
                <Route path="/setting-compliance-criteria" component={RequireAuth(ContainerSettingCompliance)}/>
                <Route path="/profile" component={RequireAuth(ContainerComplianceDocument)}/>

                <Route path="/contract-report-payroll-payment" component={RequireAuth(ContainerReportPayrollPayment)}/>

                <Route path="/qa-clock" component={RequireAuth(ContainerQaClock)}/>

                <Route path="/payslip" component={RequireAuth(ContainerPayslip)}/>
                <Route path="/record" component={RequireAuth(ContainerRecordCompliance)}/>
                    <Route path="/edocs/:otp" component={ContainerRecordComplianceEdocSign}/>
                    <Route path="/paysplip-test" component={ContainerPayslipTest}/>




                <Route path='*' component={PageNotFoundContainer}/>
                </Switch>
            </Router>

        );
    }
}

export default App;

/*render(
 <App/>,
 document.getElementById('app')
 );*/


/*
 import React  from 'react';
 import Topnav from './components/common/top-nav';
 import Sidebar from './components/common/sidebar';
 import AsideMenu from './components/common/aside-menu';
 import TempContainer from './containers/templateContainer';
 import Footer from  './components/common/footer';
 import './App.css';

 import thunk from 'redux-thunk';
 import {createStore, applyMiddleware, compose} from 'redux';
 import {logger} from 'redux-logger';

 var reduxLogger = require('redux-logger');
 const middleware  = applyMiddleware(thunk);
 const store = compose(middleware)(createStore)(allReducers);

 class App extends React.Component {
 render() {
 return (
 <div className="App">
 <Topnav></Topnav>
 <Sidebar></Sidebar>
 <TempContainer> </TempContainer>

 <AsideMenu></AsideMenu>
 <Footer></Footer>
 </div>
 );
 }
 }

 export default App;
 */
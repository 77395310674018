import React from 'react';
import '../asset/styles/emailConfirmation.css';

class emailConfirmationMessageContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: "",
            name: "",
            surname: "",
            email: "",
            phone: "",
        }

        this.getLinkUrlInfo = this.getLinkUrlInfo.bind(this);
        this.getLinkUrlInfo();
    }

    getLinkUrlInfo() {
        let link = window.location.pathname;
        let loc = this.props.location.pathname.replace("/account-verification/", "");
        const arr = loc.split("--");
        let state = this.state;
        state.company = arr[0];
        state.name = arr[1];
        state.surname = arr[2];
        state.email = arr[3];
        state.company = arr[4];
        this.setState(state);
        console.log("Link > ", link);
        console.log("Loc > ", loc);

        /**
         *  http://localhost:3000/#/account-verification/easipath--biangacila--luvungula--biangacila@gamil.com--0729139504
         */
    }

    render() {
        return (
            <html>
            <body>
            <div className="myHeader">
                <img width="100" height="100"
                     src="http://biadebt.easipath.com:33332/download/rev/file/215d84f3d1/PMIS%20Logo%20Mock%20Up.png"/>
                <hr/>
            </div>

            <div className="myContent1">
                <br/>
                <h2>Thank You {this.state.name}!</h2>
                <p>Your registration has been logged on our system</p>
                <p>Your account activation would be confirmed within</p>
                <h3>24 hours</h3>
                <p>Please check your inbox for your login credentials.</p>
                <p><button className="btn btn-primary" onClick={()=>{window.location.href="#/home"}}>CLick here for Home!</button></p>
                <br/>
                <br/>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>

            <div className="myFooter">
                <hr/>
                <p>
                    if you have any question you can contact us at <b>support@pmis.co.za</b>
                </p>
            </div>

            </body>
            </html>
        )
    }


}

export default emailConfirmationMessageContainer;
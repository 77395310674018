import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import TopMenu from '../component/common/top-nav-new';
import '../asset/styles/global-main.css';
import '../asset/styles/setting-contract.css';

import '../asset/styles/stepbystepform.css';

import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';
import loaderImg from '../asset/ajax_loader.gif';
import DatePicker from 'react-datepicker';
//https://reactdatepicker.com/#example-7
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const serverEpwp = eConfig.serverEpwp;


class SettingContractPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            LoginUser: {},
            company: "",
            username: "",
            role: "",
            isLoading: false,
            StartDate: moment(),
            EndDate: moment(),
            Sep1: {
                Name: "",
                ProjectNumber: "",
                ContractDescription: "",
                Directorate: "",
                Department: "",
                Municipality: "",
                Province: "",
                ContactPerson: "",
                ContactEmail: "",
                ContactCellNumber: "",
                ContractOfficeNumber: "",
                ContractUnitOfMeasurement: "",
            },
            Sep2: {
                StartDate: moment(),
                EndDate: moment(),
                ContractTerm: "",
            },
            Sep3: {
                PayRunFrequency: "weekly",
                PayDate: "",
                PayRunArrearsDays: "",
                Details:{},
                detail:{},
            },
            Sep4: {
                Designation1: "",
                Designation2: "",
                Designation3: "",
                Designation4: "",
                Designation5: "",
                Designation6: "",
            },
            Sep5: {
                RateTypeChargeOut: "weekly",
                PayCircle: "week",
                WeekPayDay: "monday",
                WorkOnSaturday: "true",
                WorkOnSunday: "true",
                PayAfter: "4",
                RateTypeEmployee: "weekly",
                WorkOnPPh: "true",
                HoursWorkedPerDay: "4",

            },
            Sep6: {},
            Sep7: {
                List: [],// {SiteName:"",NumberOfUnitOfMeasurement:0,Raduis:"",Latitude:"",Longitude:"",SitePeopleByDesignation:[]}
                adding: false,
                SiteName: "",
                NumberOfUnitOfMeasurement: 0,
                Raduis: "",
                Latitude: "",
                Longitude: "",
                SitePeopleByDesignation: {},//{contract:"",SiteName:"",designation:"",total:0}
                TotalPeople: 0,
            },
            Sep8: {},
            rates: [],
            Contracts: [],
            openclose: false,
            step: 1
        };


        this.fetchContract = this.fetchContract.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.submitContract = this.submitContract.bind(this);
        this.renderAddContractForm = this.renderAddContractForm.bind(this);
        this.renderContractList = this.renderContractList.bind(this);

        this.editContract = this.editContract.bind(this);
        this.viewContract = this.viewContract.bind(this);
        this.addContactClick = this.addContactClick.bind(this);
        this.openContract = this.openContract.bind(this);
        this.closeContract = this.closeContract.bind(this);
        this.renderPopupObject = this.renderPopupObject.bind(this);
        this.validationSubmit = this.validationSubmit.bind(this);
        this.contractCounter = this.contractCounter.bind(this);
        this.renderStep = this.renderStep.bind(this);


        this.renderRowInput2Coloum = this.renderRowInput2Coloum.bind(this);
        this.renderCheckbox = this.renderCheckbox.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.renderTextArea = this.renderTextArea.bind(this);
        this.renderSelect = this.renderSelect.bind(this);

        this.stepControler = this.stepControler.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.NextStepClick = this.NextStepClick.bind(this);
        this.saveAndNext = this.saveAndNext.bind(this);
        this.stepOne = this.stepOne.bind(this);
        this.stepTwo = this.stepTwo.bind(this);
        this.stepFour = this.stepFour.bind(this);
        this.stepFive = this.stepFive.bind(this);
        this.stepThree = this.stepThree.bind(this);
        this.stepSix = this.stepSix.bind(this);
        this.stepSeven = this.stepSeven.bind(this);
        this.stepEight = this.stepEight.bind(this);
        this.saveNewSite = this.saveNewSite.bind(this);
        this.saveAndExit = this.saveAndExit.bind(this);


        this.BackStepClick = this.BackStepClick.bind(this);
        this.getSelectedClass = this.getSelectedClass.bind(this);
        this.getRequiredLabel = this.getRequiredLabel.bind(this);
        this.handleInputStep1 = this.handleInputStep1.bind(this);
        this.handleInputStep2 = this.handleInputStep2.bind(this);
        this.handleInputStep3 = this.handleInputStep3.bind(this);
        this.handleInputStep4 = this.handleInputStep4.bind(this);
        this.handleInputStep5 = this.handleInputStep5.bind(this);
        this.handleInputStep6 = this.handleInputStep6.bind(this);
        this.handleInputStep7 = this.handleInputStep7.bind(this);
        this.handleInputStep8 = this.handleInputStep7.bind(this);

        this.getDesignations = this.getDesignations.bind(this);
        this.setRate = this.setRate.bind(this);
        this.getRete = this.getRete.bind(this);
        this.handleChangeRate = this.handleChangeRate.bind(this);

        this.renderSiteList = this.renderSiteList.bind(this);
        this.renderSiteAddForm = this.renderSiteAddForm.bind(this);
        this.renderSiteEditForm = this.renderSiteEditForm.bind(this);
        this.EditSiteRequest = this.EditSiteRequest.bind(this);
        this.DeleteSite = this.DeleteSite.bind(this);
        this.changeSiteForm = this.changeSiteForm.bind(this);
        this.renderNumberOfPeopleByDesignation = this.renderNumberOfPeopleByDesignation.bind(this);
        this.handleChangeSiteDesignation = this.handleChangeSiteDesignation.bind(this);
        this.getDefaultValueForSiteDesignation = this.getDefaultValueForSiteDesignation.bind(this);

        this.handleChangeDatePickerStartDate = this.handleChangeDatePickerStartDate.bind(this);
        this.handleChangeDatePickerEndDate = this.handleChangeDatePickerEndDate.bind(this);
        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);
        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);
        this.calculateMonthsBetweenPeriod = this.calculateMonthsBetweenPeriod.bind(this);

        this.setPayCycleDetail =  this.setPayCycleDetail.bind(this);
        this.getPayCycleDetail =  this.getPayCycleDetail.bind(this);
        this.renderPayCycleDetail = this.renderPayCycleDetail.bind(this);
        this.handlePayCycleDetail = this.handlePayCycleDetail.bind(this);



        //this.validationSubmit = this.validationSubmit.bind(this);

    }

    componentDidMount() {

    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        this.setState(tmp);
        /**
         * Load companies from api
         */
        this.fetchContract();

    }

    getDefaultValueForSiteDesignation(designation, sitename) {
        let key = sitename + "--" + designation;
        if (typeof this.state.Sep7.SitePeopleByDesignation[key] === "undefined") {
            return 0;
        }

        console.log("getDefaultValueForSiteDesignation > ", designation, sitename, " > ", this.state.Sep7.SitePeopleByDesignation[key]);

        return this.state.Sep7.SitePeopleByDesignation[key].Total;
    }

    handleChangeSiteDesignation(e) {
        let key = e.target.name;
        let val = e.target.value;
        let designation = key.split("--")[1];
        let siteName = key.split("--")[2];
        let contactName = this.state.Sep1.Name;
        let tmp = this.state;
        tmp.Sep7.SitePeopleByDesignation[siteName + "--" + designation] = {
            Contract: contactName,
            SiteName: siteName,
            designation: designation,
            Total: val
        };


        //todo clean Site People By Designation
        let isFind = function (arr, key) {
            let boo = false;
            for (let i in arr) {
                let t = arr[i];
                if (t === key) {
                    boo = true;
                    break;
                }
            }
            return boo;
        }
        let list = [];
        for (let i in this.state.Sep4) {
            let item = this.state.Sep4[i];
            if (item !== "") {
                list.push(item)
            }
        }

        for (let i in tmp.Sep7.SitePeopleByDesignation) {
            let boo = isFind(list, i);
            if (!boo) {
                window.delete(tmp.Sep7.SitePeopleByDesignation, i)
            }
        }

        this.setState(tmp);

    }


    handleInputStep1(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep1[key] = val;
        this.setState(tmp);
    }

    handleInputStep2(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep2[key] = val;
        this.setState(tmp);
    }

    handleInputStep3(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep3[key] = val;
        this.setState(tmp);
    }

    handleInputStep4(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep4[key] = val;
        this.setState(tmp);

        /**
         * Let clear  rates based on designation
         */

        let findDesignation = function (myObject, myVal) {
            let boo = false;
            for (let i in myObject) {
                let inVal = myObject[i];
                if (inVal !== "") {
                    if (inVal === myVal) {
                        boo = true
                    }
                }

            }
            return boo;
        }


        let rates = [];
        for (let i in this.state.rates) {
            const row = this.state.rates[i];
            if (findDesignation(this.state.Sep4, this.state.Sep4)) {
                rates.push(row);
            }
        }
        tmp = this.state;
        tmp.rates = rates;
        this.setState(tmp);

        /**
         * Let initialise  rates based on new designation
         */

        for (let i in this.state.Sep4) {
            const designation = this.state.Sep4[i];
            let boo = false;
            for (let a in this.state.rates) {
                let row = this.state.rates[a];
                let designationStore = row.designation;
                if (designationStore === designation) {
                    boo = true;
                }
            }
            if (!boo) {
                let tmp = this.state;
                tmp.rates.push({
                    typeOfRate: "employee",
                    designation: designation,
                    normal: 0,
                    saturday: 0,
                    sunday: 0,
                    phd: 0
                });
                tmp.rates.push({
                    typeOfRate: "chargeOut",
                    designation: designation,
                    normal: 0,
                    saturday: 0,
                    sunday: 0,
                    phd: 0
                });
                this.setState(tmp);
            }
        }

        //todo clean Site People By Designation
        let isFind = function (arr, key) {
            let boo = false;
            for (let i in arr) {
                let t = arr[i];
                if (t === key) {
                    boo = true;
                    break;
                }
            }
            return boo;
        }
        let list = [];
        for (let i in this.state.Sep4) {
            let item = this.state.Sep4[i];
            if (item !== "") {
                list.push(item)
            }
        }

        for (let i in tmp.Sep7.SitePeopleByDesignation) {
            let boo = isFind(list, i);
            if (!boo) {
                window.delete(tmp.Sep7.SitePeopleByDesignation, i)
            }
        }

        this.setState(tmp);

    }

    handleInputStep5(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep5[key] = val;
        this.setState(tmp);

        console.log("Handle step five > ", tmp.Sep5);
    }

    handleInputStep6(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep6[key] = val;
        this.setState(tmp);
    }

    handleInputStep7(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep7[key] = val;
        this.setState(tmp);
    }

    handleInputStep8(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp.Sep8[key] = val;
        this.setState(tmp);
    }


    getRequiredLabel(req) {
        if (req) {
            return (<span className="fieldRequired">*</span> )
        }
        return "";
    }

    openContract() {
        let tmp = this.state;
        tmp.openclose = true;
        tmp.step = 1;
        this.setState(tmp);
    }

    closeContract() {
        let tmp = this.state;
        tmp.openclose = false;
        tmp.step = 1;
        this.setState(tmp);
    }

    fetchContract() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {
            Company: this.state.company
        };
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Contracts = responseData;
                this.setState(tmp);

                console.log("+++++>fetchContracts response ", this.state);
            });

    }

    handleInput(e) {
        let key = e.target.name;
        let val = e.target.value;
        if (key === "PhysicalSameAsPostal") {
            if (e.target.checked) {
                val = "yes";
            } else {
                val = "no";
            }
        }
        let tmp = this.state;
        tmp.User[key] = val;
        this.setState(tmp);
    }

    renderPopupObject() {
        return (
            <div className="container ">
                <Modal show={this.state.openclose} onHide={this.closeContract} className="">
                    <Modal.Header closeButton>
                        <Modal.Title>Manage Contract</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <div className="row">
                            <div className="col-lg-12">
                                Place holding for contract form
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeContract}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    addContactClick() {
        this.openContract();
    }

    editContract(row) {

        let tmp = this.state;
        tmp.isLoading = true;
        this.setState(tmp);

        let endpoint = "/api/contracts/service/load";
        let url = serverEpwp + endpoint;
        let hub = {
            company: row.Company,
            contractName: row.Name
        }

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var hub = this.state;
                hub.Sep1 = responseData.ContractInformation;
                let myPeriod = responseData.ContractPeriod;
                myPeriod.StartDate = this.convertStringDateIntoMomentDate(responseData.ContractPeriod.StartDate);
                myPeriod.EndDate = this.convertStringDateIntoMomentDate(responseData.ContractPeriod.EndDate);
                hub.Sep2 = myPeriod;
                hub.Sep3 = responseData.ContractPayCycle;
                /**
                 * Let create pay cycle detail
                 * @type {Array}
                 */
                hub.Sep3.detail={};
                for(let i in responseData.ContractPayCycleDetail){
                    let row = responseData.ContractPayCycleDetail[i];
                    hub.Sep3.detail[row.Period]={
                        period:parseInt(row.Period),
                        startdate: row.StartDate,
                        enddate:row.EndDate,
                        paydate:row.PayDate,
                        PayRunFrequency: row.PayRunFrequency,
                    };
                }


                hub.Sep4 = responseData.ContractDesignation;
                hub.Sep5 = responseData.ContractConditions;
                hub.Sep6 = responseData.ContractRate;
                hub.Sep7.List = responseData.ContractSite;
                hub.rates = responseData.ContractRate;
                console.log("Edit Contract Conditions --> ", hub.Sep5 );
                /**
                 * put rate to lower case
                 */
                let rates = [];
                for (let i in hub.rates) {
                    let rate = {};
                    let row = hub.rates[i];

                    rate.designation = row.Designation;
                    rate.typeOfRate = row.TypeOfRate;
                    rate.normal = row.Normal;
                    rate.saturday = row.Saturday;
                    rate.sunday = row.Sunday;
                    rate.phd = row.Phd;
                    rates.push(rate);

                }
                hub.rates = rates;

                if (rates.length === 0) {
                    let tmp = this.state;
                    for (let i in hub.Sep4) {
                        let designation = hub.Sep4 [i];
                        if (designation !== "") {
                            tmp.rates.push({
                                typeOfRate: "employee",
                                designation: designation,
                                normal: 0,
                                saturday: 0,
                                sunday: 0,
                                phd: 0
                            });
                            tmp.rates.push({
                                typeOfRate: "chargeOut",
                                designation: designation,
                                normal: 0,
                                saturday: 0,
                                sunday: 0,
                                phd: 0
                            });
                        }
                    }

                    this.setState(tmp);
                }
                console.log("rates --> ", rates);

                /**
                 * contruct site people by designation
                 */

                let SitePeopleByDesignation = {};
                for (let i in hub.Sep7.List) {
                    let rows = hub.Sep7.List[i];
                    for (let y in rows.SitePeopleByDesignation) {
                        let row = rows.SitePeopleByDesignation[y];
                        let newKey = rows.SiteName + "--" + row.Designation;
                        let des = row.Designation;
                        delete row.Designation;
                        row.designation = des;
                        SitePeopleByDesignation[newKey] = row;
                    }

                }
                hub.Sep7.SitePeopleByDesignation = SitePeopleByDesignation;
                this.setState(hub);

                /**
                 * let render rates
                 */
                for (let i in rates) {
                    //  "employee##" + designation + "##normal"
                    let row = rates[i];
                    let key = row
                }

                this.openContract();

                let tmp = this.state;
                tmp.isLoading = false;
                this.setState(tmp);
                this.calculateMonthsBetweenPeriod();

            });
    }

    viewContract(row) {
        alert("Not implemented");
    }

    validationSubmit() {
        /**
         * Validation step 1
         */
        if (this.state.step === 1) {
            let obj = this.state.Sep1;
            if (obj.Name === "") {
                alert("Step 1: Project name can't be Empty!, please provide");
                return false
            }
            if (obj.ProjectNumber === "") {
                alert("Project number can't be Empty!, please provide");
                return false
            }
            if (obj.Municipality === "") {
                alert("Step 1: Municipality can't be Empty!, please provide");
                return false
            }
            if (obj.Province === "") {
                alert("Step 1: Province can't be Empty!, please provide");
                return false
            }
            if (obj.ContactPerson === "") {
                alert("Step 1: Contact Person can't be Empty!, please provide");
                return false
            }
            if (obj.ContactEmail === "") {
                alert("Step 1: Vendor Email Address can't be Empty!, please provide");
                return false
            }
            if (obj.ContactCellNumber === "") {
                alert("Step 1: Vendor Cell number can't be Empty!, please provide");
                return false
            }
            if (obj.ContractUnitOfMeasurement === "") {
                alert("Step 1: Unit Of Measurement can't be Empty!, please provide");
                return false
            }
        }

        if (this.state.step === 2) {
            let obj = this.state.Sep2;
            if (obj.StartDate === "") {
                alert("Step 2: Start Date can't be Empty!, please provide");
                return false
            }
            if (obj.EndDate === "") {
                alert("Step 2: End Date can't be Empty!, please provide");
                return false
            }
        }

        if (this.state.step === 3) {
            let obj = this.state.Sep3;
            if (obj.PayRunFrequency === "") {
                alert("Step 3: Pay Run Frequency can't be Empty!, please provide");
                return false
            }

            console.log("validationSubmit step 3 > ",this.state.Sep3);

            if (JSON.stringify(this.state.Sep3.detail) === "{}") {
                alert("Step 3: Pay Dates can't be Empty!, please provide at least one");
                return false
            }

        }

        if (this.state.step === 4) {
            let obj = this.state.Sep4;
            if (obj.Designation1 === "") {
                alert("Step 4: Designation 1 can't be Empty!, please provide");
                return false
            }

        }

        if (this.state.step === 6) {
            let obj = this.state.Sep6;
            for (let i in obj.List) {
                let row = obj.List[i];
                for (let a in row) {
                    let val = row[a];
                    if (a !== "SitePeopleByDesignation") {
                        if (val === "") {
                            alert("Step 6: Rate Designation " + row.designation + " Key " + a + "  can't be Empty!, please provide");
                            return false;
                        }
                    }

                }
            }
        }

        if (this.state.step === 7) {
            let obj = this.state.Sep7;
            for (let i in obj.List) {
                let row = obj.List[i];
                row.Username = this.state.username;
                row.Company = this.state.company;
                if (row.SiteName === "") {
                    alert("Step 7: Site Name key  can't be Empty!, please provide");
                    return false;
                }
                if (row.NumberOfUnitOfMeasurement === "") {
                    alert("Step 7: Site " + row.SiteName + " Key NumberOfUnitOfMeasurement  can't be Empty!, please provide");
                    return false;
                }
            }
        }

        return true;
    }

    submitContract(e) {
        e.preventDefault();
        if (!this.validationSubmit()) {
            return;
        }
        const text = window.confirm("Are you sure that you want to submit this contract?");
        if (!text) {
            return;
        }
        console.log("SUBMIT FORM REQUEST -> ", this.state);
        /**
         * Everything is good to submit please go ahead
         */

        let tmp = this.state;
        tmp.isLoading = true;
        this.setState(tmp);

        let state = this.state;
        let hub = {};
        hub.company = state.company;
        hub.username = state.username;
        hub.ContractInformation = state.Sep1;

        let myPeriod = state.Sep2;
        myPeriod.StartDate = this.convertStringDateIntoMomentDate(myPeriod.StartDate);
        myPeriod.EndDate = this.convertStringDateIntoMomentDate(myPeriod.EndDate);
        hub.ContractPeriod = myPeriod;

        hub.ContractPayCycle = state.Sep3;
        hub.ContractDesignation = state.Sep4;
        hub.ContractConditions = state.Sep5;
        //hub.ContractSite = state.Sep7.List;
        // hub.ContractRate = state.rates;


        /**
         * add designation
         */
        let mySites = [];

        for (let i in state.Sep7.List) {
            let rowSite = state.Sep7.List[i];
            rowSite.SitePeopleByDesignation = [];
            console.log("rowSite > ", rowSite);
            for (let p in state.Sep7.SitePeopleByDesignation) {
                let rowPeople = state.Sep7.SitePeopleByDesignation[p];
                let sitename = p.split("--")[0];
                if (sitename === rowSite.SiteName) {
                    rowSite.SitePeopleByDesignation.push(rowPeople)
                }
            }


            mySites.push(rowSite)

        }
        hub.ContractSite = mySites;


        /**
         * Clean extra rates
         */
        let rates = state.rates;
        console.log("SUBMIT RATES CHECK 1 ",rates);
        let tmpRate = [];
        for (let i in rates) {
            let row = rates[i];
            if (row.normal === 0 && row.saturday === 0 && row.sunday === 0 && row.phd === 0) {
                //nothing to do leave it
            } else {
                let rate = {};
                rate = row;
                rate.normal = parseFloat(row.normal);
                rate.saturday = parseFloat(row.saturday);
                rate.sunday = parseFloat(row.sunday);
                rate.phd = parseFloat(row.phd);
                tmpRate.push(rate)
            }
        }

        console.log("SUBMIT RATES CHECK 2 ",tmpRate);

        hub.ContractRate = tmpRate;

        /**
         * Let create pay cycle detail
         * @type {Array}
         */
        let cycleDetail = [];
        for(let i in this.state.Sep3.detail){
            let row =this.state.Sep3.detail[i];
            row.period = parseInt(i);
            row.PayRunFrequency = hub.PayRunFrequency;
            if(row.period !=="" && row.period !==0   && row.startdate !=="" && row.enddate !=="" && row.paydate!==""){
                row.company = this.state.company;
                row.username = this.state.username;
                row.ContractName = this.state.contractName;
                row.PayRunFrequency = this.state.Sep3.PayRunFrequency;
                cycleDetail.push(row);
            }
        }
        hub.ContractPayCycleDetail = cycleDetail;




        let endpoint = "/api/contracts/service/new";
        let url = serverEpwp + endpoint;

        console.log("^^^^^>>>> ", hub);
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                console.log("Thank you, your  contract have  be submitted > ", responseData);
                this.fetchContract();

                let tmp = this.state;
                tmp.isLoading = false;
                this.setState(tmp);

                alert("Thank you, your  contract have  be submitted");
                this.closeContract();
            });
    }

    contractCounter() {
        if (typeof this.state.Contracts !== "undefined") {
            return this.state.Contracts.length;
        }
        return 0;
    }

    getSelectedClass(step) {

        if (this.state.step === step) {
            return "btn  btn-arrow-down"
        }

        return "btn btn-default btn-circle"
    }

    handleChange(e) {
        const key = e.target.name;
        const val = e.target.value;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);


    }

    defaultDateTime=()=>{
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy+'-'+mm+'-'+dd
    }

    convertStringDateIntoMomentDate(dateString) {
        if(dateString===""){
            dateString = this.defaultDateTime();
        }
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    convertMomentDateToStringISO(dt) {
        return dt.toISOString().slice(0, 10);
    }

    calculateMonthsBetweenPeriod() {
        let tmp = this.state;
        let p = tmp.Sep2.EndDate.diff(tmp.Sep2.StartDate, "months");
        tmp.Sep2.ContractTerm = p.toString() + " Month(s)";
        this.setState(tmp);
        console.log("Date Between > ", tmp.Sep2.ContractTerm);
    }

    handleChangeDatePickerStartDate(date) {
        let tmp = this.state;
        tmp.Sep2.StartDate = date;
        this.setState(tmp);
        this.calculateMonthsBetweenPeriod();
    }

    handleChangeDatePickerEndDate(date) {
        let tmp = this.state;
        tmp.Sep2.EndDate = date;
        this.setState(tmp);
        this.calculateMonthsBetweenPeriod();
    }

    handleChangeCircle(e) {
        const val = e.target.value;
        let tmp = this.state;
        tmp.paycircle = val;
        this.setState(tmp)
    }

    handleChangeRate(e) {
        const name = e.target.name;
        //console.log("->> ",name,e.target.designation,e.target);
        const arr = name.split("##");
        const typeOfRate = arr[0];
        const designation = arr[1];
        const daytype = arr[2];
        const val = e.target.value;
        this.setRate(typeOfRate, designation, daytype, val)

    }

    setRate(typeOfRate, designation, daytype, val) {
        let state = this.state;
        let rates = state.rates;

        for (let i in rates) {
            const row = rates[i];

            if (row.typeOfRate === typeOfRate && row.designation === designation) {

                if (typeof row[daytype] === "undefined") {
                    rates[i][daytype] = val
                } else {
                    rates[i][daytype] = val;
                }

            }
        }

        state.rates = rates;
        this.setState(state);
        console.log("SET RATE > ", this.state.rates, typeOfRate, designation, daytype, val);
    }

    getRete(typeOfRate, designation, daytype) {
        let state = this.state;
        for (let i in state.rates) {
            const row = state.rates[i];
            console.log("GET_RATE2 > ", typeOfRate, designation, daytype, " > ", row[daytype]);
            if (row.typeOfRate === typeOfRate && row.designation === designation) {
                if (typeof row[daytype] === "undefined") {
                    return 0
                }

                console.log("GET_RATE > ", typeOfRate, designation, daytype, " > ", row[daytype]);
                return row[daytype];
            }
        }
    }

    renderLocalInput(name, type, typeOfRate, designation, daytype) {
        // typeOfRate:"emplyee",designation:"Worker"
        return (
            <div>
                <input
                    ref={name}
                    name={name}
                    autoComplete="off"
                    type={type}
                    placeholder="e.g 150.9"
                    className="form-control"
                    required
                    defaultValue={this.getRete(typeOfRate, designation, daytype)}
                    /*onBlur={this.validationCheck}*/
                    onChange={this.handleChangeRate}
                />
            </div>
        )
    }

    renderStep() {
        let arr = ["Info", "Period", "Pay Cycle", "Designation", "Conditions", "Rate", "Site", "Submit"];
        let x = 0;
        return arr.map((row) => {
            x++;
            return (
                <div className="stepwizard-step">
                    <a name={"linkstep-" + x} onClick={this.changeStep} type="button"
                       className={this.getSelectedClass(x)}><b>{x}</b></a>
                    <p><b>{row}</b></p>
                </div>
            )
        })
    }

    renderContractList() {
        if (typeof this.state.Contracts === "undefined") {
            return (
                <p className="alert alert-danger ">Fetching in process...</p>
            )
        }

        let ls = this.state.Contracts;
        if (ls.length === 0) {
            return (
                <p className="alert alert-warning ">No data find</p>
            )
        }

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row) => {
                    return (
                        <tr>
                            <td>{row.Name}</td>
                            <td>{row.Status}</td>
                            <td>{row.OrgDateTime}</td>
                            <td>
                                <a className="clickMe" onClick={() => {
                                    this.editContract(row)
                                }}>Edit</a>&nbsp;|&nbsp;
                                <a className="clickMe" onClick={() => {
                                    this.viewContract(row)
                                }}>View</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>

                <div className="">
                    <DatePicker
                        customInput={<DatePickerCustomInput/>}
                        selected={this.state.StartDate}
                        onChange={this.handleChangeDatePickerStartDate}
                        dateFormat="YYYY-MM-DD"
                    />
                </div>
            </table>
        )
    }

    renderCheckbox(label, inputName, inputType, placeHolder, requireded, fn, dValue) {
        return (
            <div className={""}>
                <label>&nbsp;</label>
                <div className={"form-check"}>
                    <input
                        type={inputType}
                        className="form-check-input"
                        id={inputName}
                        name={inputName}
                        ref={inputName}
                        onChange={fn}
                        value={dValue}
                    />
                    <label className={"form-check-label"}>{label}:</label>
                </div>
            </div>

        )
    }

    renderInput(label, inputName, inputType, placeHolder, requireded, fn, dValue) {
        return (
            <div className={""}>
                <label>{label} {this.getRequiredLabel(requireded)}</label>
                <input
                    type={inputType}
                    className="form-control"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    placeholder={"" + placeHolder}
                    onChange={fn}
                    required={requireded}
                    value={dValue}
                />
            </div>
        )
    }

    renderTextArea(label, inputName, inputType, placeHolder, requireded, fn, dValue) {
        return (
            <div className={""}>
                <label>{label} {this.getRequiredLabel(requireded)}</label>
                <textarea
                    className="form-control"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    placeholder={"" + placeHolder}
                    onChange={fn}
                    required={requireded}
                    value={dValue}
                />
            </div>
        )
    }

    renderSelect(label, inputName, options, placeHolder, requireded, fn, dValue) {
        return (
            <div className={""}>
                <label>{label} {this.getRequiredLabel(requireded)}</label>
                <select
                    className="select"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    onChange={fn}
                    required={requireded}
                    value={dValue}
                    selected={dValue}
                >
                    <option value={""}> --Select {label}--</option>
                    {
                        options.map((row) => {
                            return ( <option value={row.value}>{row.label}</option>)
                        })
                    }
                </select>
            </div>
        )
    }

    renderRowInput2Coloum(label1, placeHolder1, inputName1, inputType1, val1, requireded1, fn1, dValue1,
                          label2, placeHolder2, inputName2, inputType2, val2, requireded2, fn2, dValue2) {
        let lengCol = "6";
        if (inputName2 === "") {
            lengCol = "12";
        }
        return (
            <div className="form-row ctForm">
                <div className={"form-group col-md-" + lengCol}>
                    {(inputType1 === "text") && (
                        this.renderInput(label1, inputName1, inputType1, placeHolder1, requireded1, fn1, dValue1)
                    )}
                    {(inputType1 === "email") && (
                        this.renderInput(label1, inputName1, inputType1, placeHolder1, requireded1, fn1, dValue1)
                    )}
                    {(inputType1 === "checkbox") && (
                        this.renderCheckbox(label1, inputName1, inputType1, placeHolder1, requireded1, fn1, dValue1)
                    )}
                    {(inputType1 === "select") && (
                        this.renderSelect(label1, inputName1, val1, placeHolder1, requireded1, fn1, dValue1)
                    )}
                    {(inputType1 === "textarea") && (
                        this.renderTextArea(label1, inputName1, val1, placeHolder1, requireded1, fn1, dValue1)
                    )}

                </div>
                {inputName2 !== "" && (
                    <div className={"form-group col-md-6"}>
                        {(inputType2 === "text") && (
                            this.renderInput(label2, inputName2, inputType2, placeHolder2, requireded2, fn2, dValue2)
                        )}
                        {(inputType2 === "email") && (
                            this.renderInput(label2, inputName2, inputType2, placeHolder2, requireded2, fn2, dValue2)
                        )}
                        {(inputType2 === "textaera") && (
                            this.renderInput(label2, inputName2, inputType2, placeHolder2, requireded2, fn2, dValue2)
                        )}
                        {(inputType2 === "checkbox") && (
                            this.renderCheckbox(label2, inputName2, inputType2, placeHolder2, requireded2, fn2, dValue2)
                        )}
                        {(inputType2 === "select") && (
                            this.renderSelect(label2, inputName2, val2, placeHolder2, requireded2, fn2, dValue2)
                        )}.

                    </div>
                )}

            </div>
        )
    }

    navGroupButtonWithBack() {
        return (
            <div className="myButtonNav">
                <button className="btn btn-default nextBtn btn-sm pull-left" type="button" onClick={() => {
                    this.BackStepClick()
                }}>Back
                </button>


                <button className="btn btn-primary nextBtn btn-sm pull-right push-bottom" type="button" onClick={() => {
                    this.NextStepClick()
                }}>Next
                </button>

                <button className="btn btn-success nextBtn btn-sm pull-right push-bottom" type="button" onClick={() => {
                    this.saveAndExit();
                }}>Save
                </button>

                <button className="btn btn-warning nextBtn btn-sm pull-right push-bottom btnEditCustom" type="button"
                        onClick={() => {
                            alert("Not implement");
                        }}>Edit
                </button>
            </div>
        )
    }

    navGroupButtonWithNoBack() {
        return (
            <div>


                <button className="btn btn-primary nextBtn btn-sm pull-right push-bottom" type="button" onClick={() => {
                    this.NextStepClick()
                }}>Next
                </button>

                <button className="btn btn-success nextBtn btn-sm pull-right push-bottom" type="button" onClick={() => {
                    this.saveAndExit();
                }}>Save
                </button>

                <button className="btn btn-warning nextBtn btn-sm pull-right push-bottom btnEditCustom" type="button"
                        onClick={() => {
                            alert("Not implement");
                        }}>Edit
                </button>
            </div>
        )
    }

    navGroupButtonWithNoNext() {
        return (
            <div>

                <button className="btn btn-default nextBtn btn-sm pull-left" type="button" onClick={() => {
                    this.BackStepClick()
                }}>Back
                </button>


                <button className="btn btn-success nextBtn btn-sm pull-right push-bottom" type="submit" onClick={() => {
                    console.log("Step 8 implemented");
                }}>Submit
                </button>

            </div>
        )
    }

    getDesignations() {
        let designations = [];
        if (this.state.Sep4.Designation1 !== "") {
            designations.push(this.state.Sep4.Designation1);
        }
        if (this.state.Sep4.Designation2 !== "") {
            designations.push(this.state.Sep4.Designation2);
        }
        if (this.state.Sep4.Designation3 !== "") {
            designations.push(this.state.Sep4.Designation3);
        }
        if (this.state.Sep4.Designation4 !== "") {
            designations.push(this.state.Sep4.Designation4);
        }
        if (this.state.Sep4.Designation5 !== "") {
            designations.push(this.state.Sep4.Designation5);
        }
        if (this.state.Sep4.Designation6 !== "") {
            designations.push(this.state.Sep4.Designation6);
        }
        return designations;
    }

    renderTableRateRowEmployee() {
        let designations = this.getDesignations();

        /*let designations = ["Worker", "Worker leader", "Supervisor"];*/
        return designations.map((designation) => {
            return (
                <tr className="rowNoBorder">
                    <td>
                        <span className="designationRole">{designation}</span>
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##normal", "text", "employee", designation, "normal")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##saturday", "text", "employee", designation, "saturday")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##sunday", "text", "employee", designation, "sunday")}
                    </td>
                    <td>
                        {this.renderLocalInput("employee##" + designation + "##phd", "text", "employee", designation, "phd")}
                    </td>

                </tr>
            )
        })
    }

    renderTableRateRowchargeOut() {
        let designations = this.getDesignations();

        /*let designations = ["Worker", "Worker leader", "Supervisor"];*/
        console.log("renderTableRateRowchargeOut designations > ", designations);
        return designations.map((designation) => {
            return (
                <tr className="rowNoBorder">
                    <td>
                        <span className="designationRole">{designation}</span>
                    </td>

                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##normal", "text", "chargeOut", designation, "normal")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##saturday", "text", "chargeOut", designation, "saturday")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##sunday", "text", "chargeOut", designation, "sunday")}
                    </td>
                    <td>
                        {this.renderLocalInput("chargeOut##" + designation + "##phd", "text", "chargeOut", designation, "phd")}
                    </td>
                </tr>
            )
        })
    }


    stepOne() {
        return (
            <div className="setup-content" id="step-1">
                <p className={"row alignMeLeft"}><span
                    className={"stepLable  "}>Step 1 of 7 -
                                <label
                                    className="scStepTitle">Capture the general information about your contract</label> </span>
                </p>
                <br/>

                {this.renderRowInput2Coloum(
                    "Contract or Works Projedt Name", "e.g Full Flash", "Name", "text", null, true, this.handleInputStep1, this.state.Sep1.Name,
                    "Contract or Works Project Number", "e.g 1555/18/260!", "ProjectNumber", "text", null, true, this.handleInputStep1, this.state.Sep1.ProjectNumber
                )}

                {this.renderRowInput2Coloum(
                    "Contract Description", "Rental, delivering,placement and servicing of portable non-flushing toilet...", "ContractDescription", "textarea", null, false, this.handleInputStep1, this.state.Sep1.ContractDescription,
                    "", "", "", null, false, null, null
                )}

                {this.renderRowInput2Coloum(
                    "Directorate", "e.g Utility Services", "Directorate", "text", null, false, this.handleInputStep1, this.state.Sep1.Directorate,
                    "Department", "e.g Water and Sanitation", "Department", "text", null, false, this.handleInputStep1, this.state.Sep1.Department
                )}

                {this.renderRowInput2Coloum(
                    "Municipality", "--- Select Municipality ---", "Municipality", "text", null, true, this.handleInputStep1, this.state.Sep1.Municipality,
                    "Province", "--- Select Province ---", "Province", "text", null, true, this.handleInputStep1, this.state.Sep1.Province
                )}


                {this.renderRowInput2Coloum(
                    "Contractor or Vendor Contact Person", "e.g John Smith", "ContactPerson", "text", null, true, this.handleInputStep1, this.state.Sep1.ContactPerson,
                    "Contractor or Vendor Email Address", "e.g smith@gmail.com", "ContactEmail", "text", null, true, this.handleInputStep1, this.state.Sep1.ContactEmail
                )}

                {this.renderRowInput2Coloum(
                    "Contractor or Vendor Cell Number", "e.g 0729971234", "ContactCellNumber", "text", null, true, this.handleInputStep1, this.state.Sep1.ContactCellNumber,
                    "Contractor or Vendor Office Number", "e.g 021 415 2147", "ContractOfficeNumber", "text", null, false, this.handleInputStep1, this.state.Sep1.ContractOfficeNumber
                )}


                {this.renderRowInput2Coloum(
                    "What is the unit of measurement?", "e.g toilet, hospital,...", "ContractUnitOfMeasurement", "text", null, true, this.handleInputStep1, this.state.Sep1.ContractUnitOfMeasurement,
                    "", "", "", "text", null, null, null, null
                )}


                {this.navGroupButtonWithNoBack()}

            </div>
        )
    }

    stepTwo() {
        return (
            <div className="row setup-content" id="step-2">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 2 of 7 -<label className="scStepTitle">Set your contract start and end dates</label>
                    </span>
                </p>
                <br/>

                <div className="form-row ctForm">
                    <div className={"form-group col-md-6 col-lg-6"}>
                        <div className={""}>
                            <label>{"Start Date"} {this.getRequiredLabel(true)}</label>
                            <DatePicker
                                customInput={<DatePickerCustomInput/>}
                                selected={this.state.Sep2.StartDate}
                                onChange={this.handleChangeDatePickerStartDate}
                                dateFormat="YYYY-MM-DD"
                            />
                        </div>
                    </div>

                    <div className={"form-group col-md-6 col-lg-6"}>
                        <div className={""}>
                            <label>{"End Date"} {this.getRequiredLabel(true)}</label>
                            <DatePicker
                                customInput={<DatePickerCustomInput/>}
                                selected={this.state.Sep2.EndDate}
                                onChange={this.handleChangeDatePickerEndDate}
                                dateFormat="YYYY-MM-DD"
                            />
                        </div>
                    </div>

                </div>


                {/*{this.renderRowInput2Coloum(
                    "Contract Start Date", "e.g 2018-03-01", "StartDate", "text", null, true, this.handleInputStep2, this.state.Sep2.StartDate,
                    "Contract End Date", "e.g 2019-02-28", "EndDate", "text", null, true, this.handleInputStep2, this.state.Sep2.EndDate,
                )}*/}

                {this.renderRowInput2Coloum(
                    "Contract Term", "Calculate End Date Start Date in Years", "ContractTerm", "textarea", null, false, this.handleInputStep2, this.state.Sep2.ContractTerm,
                    "", "", "", null, false
                )}


                {this.navGroupButtonWithBack()}

            </div>
        )
    }

    stepThree() {
        return (
            <div className="row setup-content" id="step-3">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 3 of 7 -<label className="scStepTitle">Set your pay run cycle</label>
                    </span>
                </p>
                <br/>

{/*                {this.renderRowInput2Coloum(
                    "Pay Run Frequency", "e.g Weekly,Forthnighly or Monthly", "PayRunFrequency", "select", OptionFrequency, true, this.handleInputStep3, this.state.Sep3.PayRunFrequency,
                    "", "", "", "", null, true, null, null,
                )}*/}

                <div className="form-row">
                    <div className={"form-group col-md-12"}>
                        <label className="">
                            Pay Run Frequency
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep3}
                            name={"PayRunFrequency"}
                            id={"PayRunFrequency"}
                            defaultValue={this.state.Sep3.PayRunFrequency}
                        >
                            {OptionFrequency.map((row)=>{
                                return(
                                    <option key={row.val} value={row.val}>{row.label}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>


                {this.renderPayCycleDetail()}


                {this.navGroupButtonWithBack()}
            </div>
        )
    }

    stepFour() {
        return (
            <div className="row setup-content" id="step-4">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 4 of 7 -<label className="scStepTitle">Setup contract employee designation</label>
                    </span>
                </p>
                <br/>

                {this.renderRowInput2Coloum(
                    "Designation #1", "e.g Worker, Worker leader,Roving supervisor", "Designation1", "text", null, true, this.handleInputStep4, this.state.Sep4.Designation1,
                    "Designation #2", "e.g Worker, Worker leader,Roving supervisor", "Designation2", "text", null, false, this.handleInputStep4, this.state.Sep4.Designation2,
                )}

                {this.renderRowInput2Coloum(
                    "Designation #3", "e.g Worker, Worker leader,Roving supervisor", "Designation3", "text", null, false, this.handleInputStep4, this.state.Sep4.Designation3,
                    "Designation #4", "e.g Worker, Worker leader,Roving supervisor", "Designation4", "text", null, false, this.handleInputStep4, this.state.Sep4.Designation4,
                )}
                {this.renderRowInput2Coloum(
                    "Designation #5", "e.g Worker, Worker leader,Roving supervisor", "Designation5", "text", null, false, this.handleInputStep4, this.state.Sep4.Designation5,
                    "Designation #6", "e.g Worker, Worker leader,Roving supervisor", "Designation6", "text", null, false, this.handleInputStep4, this.state.Sep4.Designation6,
                )}

                {this.navGroupButtonWithBack()}
            </div>
        )
    }

    stepFive() {
        return (
            <div className="row setup-content" id="step-6">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 5 of 8 -<label className="scStepTitle">Setup Employee Working Conditions</label>
                    </span>
                </p>
                <br/>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Pay Circle
                        </label>

                        <select
                            className={"select"}
                            name={"PayCircle"}
                            onChange={this.handleInputStep5}
                            defaultValue={this.state.Sep5.PayCircle}
                        >
                            <option value={""}></option>
                            <option value={"week"}>One week</option>
                            <option value={"two week"} >Two week</option>
                            <option value={"monthly"}>Month</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Week Pay Day
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"WeekPayDay"}
                            id={"WeekPayDay"}
                            defaultValue={this.state.Sep5.WeekPayDay}
                        >
                            <option value={""}></option>
                            <option value={"monday"}>Monday</option>
                            <option value={"tuesday"}>Tuesday</option>
                            <option value={"wednesday"}>Wednesday</option>
                            <option value={"thursday"}>Thursday</option>
                            <option value={"friday"}>Friday</option>
                            <option value={"saturday"}>Saturday</option>
                            <option value={"sunday"}>Sunday</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Work On Saturday
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"WorkOnSaturday"}
                            id={"WorkOnSaturday"}
                            defaultValue={this.state.Sep5.WorkOnSaturday}
                        >
                            <option value={""}></option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Work On Sunday
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"WorkOnSunday"}
                            id={"WorkOnSunday"}
                            defaultValue={this.state.Sep5.WorkOnSunday}
                        >
                            <option value={""}></option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"} >No</option>
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Work On Public Holiday
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"WorkOnPPh"}
                            id={"WorkOnPPh"}
                            defaultValue={this.state.Sep5.WorkOnPPh}
                        >
                            <option value={""}></option>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                        </select>
                    </div>


                    <div className={"form-group col-md-6"}>

                        <label className="">
                            Rate Type Employee
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"RateTypeEmployee"}
                            id={"RateTypeEmployee"}
                            defaultValue={this.state.Sep5.RateTypeEmployee}
                        >
                            <option value={""}></option>
                            <option value={"hourly"}>Hourly</option>
                            <option value={"daily"}>Daily</option>
                        </select>
                    </div>

                </div>

                <br/><br/>

                <div className="form-row">
                    <div className={"form-group col-md-6"}>
                        <label className="">
                            Hours Worked Per Day
                        </label>

                        <select
                            className={"select"}
                            onChange={this.handleInputStep5}
                            name={"HoursWorkedPerDay"}
                            id={"HoursWorkedPerDay"}
                            defaultValue={this.state.Sep5.HoursWorkedPerDay}
                        >
                            <option value={""}></option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                            <option value={"6"}>6</option>
                            <option value={"7"}>7</option>
                            <option value={"8"}>8</option>
                            <option value={"9"}>9</option>
                            <option value={"10"}>10</option>
                        </select>
                    </div>


                </div>

                <div className="form-row">
                    <div className={"form-group col-md-12"}>
                        {this.navGroupButtonWithBack()}
                    </div>
                </div>



            </div>
        )
    }

    stepSix() {


        return (
            <div className="row setup-content" id="step-6">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 6 of 8 -<label className="scStepTitle">Setup contract employee pay rates</label>
                    </span>
                </p>
                <br/>

                <div className="form-group col-md-12 content form-block-holder myPayRatesBlock">


                    <h3>Employee Pay Rates <span className="empPayRates pull-right">({this.state.Sep5.RateTypeEmployee})</span> </h3>
                    <table className={"table"}>
                        <thead>
                        <tr className="rowNoBorder">
                            <th>Designation<span className="fieldRequired">*</span></th>
                            <th>Normal Day Rate <span className="fieldRequired"> *</span></th>
                            <th>Saturday Rate <span className="fieldRequired"> *</span></th>
                            <th>Sunday Rate <span className="fieldRequired"> *</span></th>
                            <th>PPH Rate <span className="fieldRequired"> *</span></th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.renderTableRateRowEmployee()}

                        </tbody>
                    </table>

                    <br/>

                    <h3>Charge Out Rates <span className="empPayRates pull-right">({this.state.Sep5.RateTypeEmployee})</span></h3>
                    <table className={"table"}>
                        <thead>
                        <tr className="rowNoBorder">
                            <th>Designation<span className="fieldRequired">*</span></th>
                            <th>Normal Day Rate <span className="fieldRequired"> *</span></th>
                            <th>Saturday Rate <span className="fieldRequired"> *</span></th>
                            <th>Sunday Rate <span className="fieldRequired"> *</span></th>
                            <th>PPH Rate <span className="fieldRequired"> *</span></th>
                        </tr>
                        </thead>
                        <tbody>

                        {this.renderTableRateRowchargeOut()}

                        </tbody>
                    </table>

                </div>

                {this.navGroupButtonWithBack()}
            </div>
        )
    }

    stepSeven() {
        return (
            <div className="row setup-content" id="step-7">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 7 of 8 -<label className="scStepTitle">Setup contract sites</label>
                    </span>
                </p>
                <br/>

                <div className="sitecontent">
                    <h3>
                        Site List ({this.state.Sep7.List.length})

                        {!this.state.Sep7.adding && (
                            <a
                                className="pull-right clickMe"
                                onClick={() => {
                                    this.changeSiteForm(true)
                                }}
                            >Click here to add new site
                            </a>
                        )}

                    </h3>


                    {this.state.Sep7.adding && (
                        this.renderSiteAddForm()
                    )}

                    {!this.state.Sep7.adding && (
                        <div className="siteTableContainer">
                            {this.renderSiteList()}
                            {this.navGroupButtonWithBack()}
                        </div>
                    )}

                </div>


            </div>
        )
    }

    stepEight() {
        return (
            <div className="row setup-content" id="step-8">

                <p className={"row alignMeLeft"}>
                    <span className={"stepLable  "}>
                        Step 8 of 8 -<label className="scStepTitle">Submit Contract</label>
                    </span>
                </p>
                <br/>


                {this.navGroupButtonWithNoNext()}
            </div>
        )
    }


    changeStep(e) {

        let elname = e.target.name;
        let arr = elname.split("-");
        if (arr.length > 1) {
            let myStep = [1];
            let step = parseInt(myStep);

            let tmp = this.state;
            tmp.step = step;
            this.setState(tmp);
        }

    }

    NextStepClick() {

        if (!this.validationSubmit()) {
            return
        }

        this.saveAndNext();

        let tmp = this.state;
        tmp.step++;
        this.setState(tmp);

    }

    saveAndNext() {
        let endpoint = "";
        let hub = null;

        let state = this.state;
        let step = this.state.step;
        let contractName = state.Sep1.Name;

        let apiSubmit = function (step, endpoint, hub) {
            console.log("saveAndNext ^^^^^>>>> step: ", step, hub);
            let url = serverEpwp + endpoint;
            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hub)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    console.log("saveAndNext response > ", responseData)
                });
        }

        if (step === 1) {
            hub = state.Sep1;
            hub.company = state.company;
            hub.username = state.username;
            hub.ContractName = contractName;
            endpoint = "/api/contracts/information/new";
            return apiSubmit(step, endpoint, hub);
        }
        if (step === 2) {
            hub = state.Sep2;
            hub.company = state.company;
            hub.username = state.username;
            hub.ContractName = contractName;
            hub.StartDate = this.convertStringDateIntoMomentDate(hub.StartDate);
            hub.EndDate = this.convertStringDateIntoMomentDate(hub.EndDate);
            endpoint = "/api/contracts/period/new";
            return apiSubmit(step, endpoint, hub);
        }
        if (step === 3) {
            hub = state.Sep3;
            hub.company = state.company;
            hub.username = state.username;
            hub.ContractName = contractName;
            endpoint = "/api/contracts/paycycle/new";
             apiSubmit(step, endpoint, hub);

            /**
             * LEt make provision for detail pay cycle
             */
            console.log("PAYCYCLE-DETAIL 1 > ",hub);
            let data = [];
            for(let i in hub.detail){
                let row =hub.detail[i];
                row.period = parseInt(i);
                row.PayRunFrequency = hub.PayRunFrequency;
                console.log("PAYCYCLE-DETAIL 2 > ",row);
                if(row.period !=="" && row.period !==0   && row.startdate !=="" && row.enddate !=="" && row.paydate!==""){
                    console.log("PAYCYCLE-DETAIL 3 > ",row);
                    row.company = state.company;
                    row.username = state.username;
                    row.ContractName = contractName;
                    data.push(row);
                }
            }
            endpoint = "/api/contracts/paycycle-detail/new";
            return apiSubmit(step, endpoint, {data:data});


        }
        if (step === 4) {
            hub = state.Sep4;
            hub.company = state.company;
            hub.username = state.username;
            hub.ContractName = contractName;
            endpoint = "/api/contracts/designation/new";
            return apiSubmit(step, endpoint, hub);
        }
        if (step === 5) {
            hub = state.Sep5;
            hub.company = state.company;
            hub.username = state.username;
            hub.ContractName = contractName;
            endpoint = "/api/contracts/conditions/new";
            return apiSubmit(step, endpoint, hub);
        }
        if (step === 6) {
            let rates = state.rates;
            console.log("SUBMIT RATES CHECK 1 ",rates);
            let tmpRate = [];
            for (let i in rates) {
                let row = rates[i];
                if (row.normal === 0 && row.saturday === 0 && row.sunday === 0 && row.phd === 0) {
                    //nothing to do leave it
                } else {
                    let rate = {};
                    rate = row;
                    rate.normal = parseFloat(row.normal);
                    rate.saturday = parseFloat(row.saturday);
                    rate.sunday = parseFloat(row.sunday);
                    rate.phd = parseFloat(row.phd);
                    tmpRate.push(rate)
                }
            }

            console.log("SUBMIT RATES CHECK 2",tmpRate);

            console.log("SaveNext Step 6 array >> ", tmpRate, rates, state);
            for (let i in tmpRate) {
                let row = tmpRate[i];
                row.company = state.company;
                row.username = state.username;
                row.ContractName = contractName;
                endpoint = "/api/contracts/rates/new";
                apiSubmit(step, endpoint, row);
            }

        }
        if (step === 7) {
            let mySites = [];
            let mySByDesignation = [];

            for (let i in state.Sep7.List) {
                let rowSite = state.Sep7.List[i];
                rowSite.SitePeopleByDesignation = [];

                for (let p in state.Sep7.SitePeopleByDesignation) {
                    let rowPeople = state.Sep7.SitePeopleByDesignation[p];
                    mySByDesignation.push(rowPeople)
                }
                mySites.push(rowSite)
            }

            /**
             * process site
             */
            for (let i in mySites) {
                let row = mySites[i];
                row.company = state.company;
                row.username = state.username;
                row.ContractName = contractName;
                endpoint = "/api/contracts/site/new";
                console.log("Save next new site > ", row);
                apiSubmit(step, endpoint, row);
                /*
                Company: "kananga"
​
                ContractName: "Cikapa"
                ​
                Latitude: ""
                ​
                Longitude: ""
                ​
                NumberOfUnitOfMeasurement: "3"
                ​
                Raduis: ""
                ​
                SiteName: "randberg"
                ​
                SitePeopleByDesignation: Array []
                ​
                Username: "mingakuminga@gmail.com"
                ​
                company: "kananga"
                ​
                username: "mingakuminga@gmail.com"
                 */
            }
            for (let i in mySByDesignation) {
                let row = mySByDesignation[i];
                row.company = state.company;
                row.username = state.username;
                row.ContractName = contractName;
                endpoint = "/api/contracts/sitepeoplebydesignation/new";
                apiSubmit(step, endpoint, row);
            }

            return

        }

        return
    }

    BackStepClick() {
        let tmp = this.state;
        if (tmp.step !== 1) {
            tmp.step--;
        }
        this.setState(tmp);
    }

    stepControler() {
        let step = this.state.step;
        if (step === 1) {
            return this.stepOne();
        }
        if (step === 2) {
            return this.stepTwo();
        }
        if (step === 3) {
            return this.stepThree();
        }
        if (step === 4) {
            return this.stepFour();
        }
        if (step === 5) {
            return this.stepFive();
        }
        if (step === 6) {
            return this.stepSix();
        }
        if (step === 7) {
            return this.stepSeven();
        }
        if (step === 8) {
            return this.stepEight();
        }


        let tmp = this.state;
        tmp.step = 1;
        this.setState(tmp);
        return this.stepOne();

    }

    renderAddContractForm() {

        return (
            <div>
                <p className="alert alert-info">Adding new contract in progress....</p>

                <div className="container">

                    <div className="stepwizard col-md-offset-3">
                        <div className="stepwizard-row setup-panel">
                            {this.renderStep()}
                        </div>
                    </div>


                    <form className={" container fmContract "} onSubmit={this.submitContract}>

                        {this.stepControler()}


                    </form>

                </div>

            </div>
        )
    }

    EditSiteRequest(row) {
        let tmp = this.state;
        tmp.Sep7.SiteName = row.SiteName;
        tmp.Sep7.NumberOfUnitOfMeasurement = row.NumberOfUnitOfMeasurement;
        tmp.Sep7.Raduis = row.Raduis;
        tmp.Sep7.Latitude = row.Latitude;
        tmp.Sep7.Longitude = row.Longitude;
        tmp.Sep7.adding = false;
        this.setState(tmp);
        this.changeSiteForm(true);
    }

    DeleteSite(row) {

        let endpoint ="/api/contracts/contract/site/delete";
        let hub = row;
        let url = serverEpwp + endpoint;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Site Deletion submit Status > "+responseData.STATUS);
                console.log("Site Deletion submit Status  > ", responseData)
            });


    }

    changeSiteForm(boo) {
        let tmp = this.state;
        tmp.Sep7.adding = boo;
        this.setState(tmp);
    }

    saveAndExit() {
        this.saveAndNext();
        alert("Step: " + this.state.step + " Saved, you can now exit");
    }

    saveNewSite() {

        let tmp = this.state;
        if (tmp.Sep7.SiteName === "") {
            alert("Can't add site without name, please provide a name")
            return;
        }
        let sites = [];
        for (let i in tmp.Sep7.List) {
            let row = tmp.Sep7.List[i];
            if (row.SiteName !== tmp.Sep7.SiteName) {
                sites.push(row);
            }
        }


        let newsite = {
            SiteName: tmp.Sep7.SiteName,
            NumberOfUnitOfMeasurement: tmp.Sep7.NumberOfUnitOfMeasurement,
            Raduis: tmp.Sep7.Raduis,
            Latitude: tmp.Sep7.Latitude,
            Longitude: tmp.Sep7.Longitude,
            SitePeopleByDesignation: [],//todo please implement this
        };

        console.log("&&&&&&---> ", tmp.Sep7.SiteName, " > ", tmp.Sep7.List, " > ", sites, " > ", newsite);

        sites.push(newsite);
        tmp.Sep7.List = sites;

        tmp.Sep7.SiteName = "";
        tmp.Sep7.NumberOfUnitOfMeasurement = 0;
        tmp.Sep7.Raduis = "";
        tmp.Sep7.Latitude = "";
        tmp.Sep7.Longitude = "";
        tmp.Sep7.adding = false;
        this.setState(tmp);

        alert("Site added");


    }

    renderSiteList() {
        let list = this.state.Sep7.List;
        if (list.length === 0) {
            return (
                <p className="my90Perc">Empty sites</p>
            );
        }

        let ds = [];
        let myStep4 = this.state.Sep4;
        delete myStep4.Id;
        delete myStep4.Company;
        delete myStep4.Status;
        delete myStep4.Date;
        delete myStep4.Time;
        delete myStep4.OrgDateTime;
        delete myStep4.Username;
        delete myStep4.ContractName;
        delete myStep4.company;
        delete myStep4.username;

        for (let i in myStep4) {
            let val = myStep4[i];
            if (val !== "") {
                ds.push({
                    name: i, val: val
                })
            }
        }

        return (
            <table className="table mySiteTable" style={{width: "100%"}}>
                <thead style={{width: "100%"}}>
                <tr>
                    <th>SiteName</th>
                    <th>Number Of ({this.state.Sep1.ContractUnitOfMeasurement})</th>
                    {ds.map((d) => {
                        return (
                            <th>{d.val}</th>
                        )
                    })}
                    <th>#</th>
                </tr>
                </thead>
                <tbody style={{width: "100%"}}>
                {list.map((row) => {
                    return (
                        <tr>
                            <td>{row.SiteName}</td>
                            <td>{row.NumberOfUnitOfMeasurement}</td>
                            {ds.map((d) => {
                                return (
                                    <td>{this.getDefaultValueForSiteDesignation(d.val, row.SiteName)}</td>
                                )
                            })}
                            <td><a onClick={() => {
                                this.EditSiteRequest(row)
                            }}>Edit</a> | <a onClick={() => {
                                this.DeleteSite(row)
                            }}>Delete</a></td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }

    renderNumberOfPeopleByDesignation() {
        let list = [];

        let canAvoid = function (key) {
            let inArrayAvoid = ["Id", "Company", "Username", "Status", "Date", "Time", "OrgDateTime"];
            for (let i in inArrayAvoid) {
                let item = inArrayAvoid[i];
                if (key === item) {
                    return true;
                }
            }
            return false;
        }

        let myRow = this.state.Sep4;
        delete myRow.Id;
        delete myRow.Company;
        delete myRow.Status;
        delete myRow.Date;
        delete myRow.Time;
        delete myRow.OrgDateTime;
        delete myRow.Username;
        delete myRow.ContractName;
        delete myRow.company;
        delete myRow.username;


        console.log("Designation-Numbeer Of People ->> ", myRow, this.state.Sep4);

        for (let i in myRow) {
            let item = myRow[i];
            if (item !== "") {
                list.push(item);
            }

        }

        if (list.length === 0) {
            return (<p>Empty Designation setup</p>)
        }

        return (
            <table className="table dsTable">
                <thead>
                <tr>
                    <th>Designation</th>
                    <th>Numbeer Of People</th>
                </tr>
                </thead>
                <tbody>
                {list.map((item) => {


                    return (
                        <tr>
                            <td>{item}</td>
                            <td>
                                <input
                                    className="form-control"
                                    name={"siteDesignation--" + item + "--" + this.state.Sep7.SiteName}
                                    onChange={this.handleChangeSiteDesignation}
                                    defaultValue={this.getDefaultValueForSiteDesignation(item, this.state.Sep7.SiteName)}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    renderSiteAddForm() {
        return (
            <div>
                <p className="alert alert-warning" style={{marginRight: "15px"}}>
                    Adding a new Site in progress ...
                    <a className="btn-default pull-right clickMe" onClick={() => {
                        this.changeSiteForm(false)
                    }}>Cancel Adding
                    </a>
                </p>

                {this.renderRowInput2Coloum(
                    "Site Name", "e.g Wynberg main road", "SiteName", "text", null, true, this.handleInputStep7, this.state.Sep7.SiteName,
                    "Number Of Unit Of Measurement", "e.g 7 " + this.state.Sep1.ContractUnitOfMeasurement, "NumberOfUnitOfMeasurement", "text", null, false, this.handleInputStep7, this.state.Sep7.NumberOfUnitOfMeasurement,
                )}

                {this.renderRowInput2Coloum(
                    "Raduis", "e.g 100 meter", "Raduis", "text", null, false, this.handleInputStep7, this.state.Sep7.Raduis,
                    "", "", "", "", null, null, null,
                )}

                {this.renderRowInput2Coloum(
                    "Latitude", "e.g 36.0008", "Latitude", "text", null, false, this.handleInputStep7, this.state.Sep7.Latitude,
                    "Longitude", "e.g 7503669 ", "Longitude", "text", null, false, this.handleInputStep7, this.state.Sep7.Longitude,
                )}

                <hr/>

                <h3>Number of people working by designation</h3>

                {this.renderNumberOfPeopleByDesignation()}

                <button className="btn btn-success pull-right"
                        style={{marginRight: "15px", background: "black", color: "white"}} onClick={() => {
                    this.saveNewSite()
                }}>Add Site
                </button>


            </div>
        )
    }

    renderSiteEditForm() {

    }

    /*
        this.setPayCycleDetail =  this.setPayCycleDetail.bind(this);
        this.getPayCycleDetail =  this.getPayCycleDetail.bind(this);
        this.renderPayCycleDetail = this.renderPayCycleDetail.bind(this);
        this.handlePayCycleDetail = this.handlePayCycleDetail.bind(this);
        Sep3: {
                PayRunFrequency: "",
                PayDate: "",
                PayRunArrearsDays: "",
                Details:{},
            },
         */

    handlePayCycleDetail(e,period,key){

        let val  = e.target.value;
        let tmp = this.state;
        //console.log("handlePayCycleDetail In > ",period,key,val," ---> ",tmp.Sep3.detail[period]," --> ",(typeof tmp.Sep3.detail[period] ==="undefined" ));
        if(typeof tmp.Sep3.detail[period] ==="undefined" ){
            tmp.Sep3.detail[period] = {};
        }
        tmp.Sep3.detail[period][key] = val;
        this.setState(tmp);
    }
    setPayCycleDetail(period,key,val){
        let tmp = this.state;
        tmp.Sep3.detail[period][key] = val;
        this.setState(tmp);
    }
    getPayCycleDetail(period,key){
        let currCycle =this.state.Sep3.detail;
        if(typeof currCycle[period] ==="undefined" ){
            return "";
        }
        return currCycle[period][key];
    }
    renderPayCycleDetail(){
        let numCycle = 0;
        let currCycle =this.state.Sep3; //this.state.Sep3.PayRunFrequency
        //console.log("-=-=-=-=>>>>>>>---- >",currCycle);
        if(currCycle.PayRunFrequency ==="weekly"){
            numCycle=48;
        }
        if(currCycle.PayRunFrequency ==="forthnighly"){
            numCycle=24;
        }
        if(currCycle.PayRunFrequency ==="monthly"){
            numCycle=12;
        }

       let  lsCycle=[];
        for(let i=0;i< numCycle;i++){
            lsCycle.push((i+1));
        }

        console.log("-=-=-=-=>>>>>>>---- >",currCycle.PayRunFrequency,numCycle,lsCycle);

        return(
            <table className="table">
                <thead>
                <tr>
                    <th>Period</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Pay Date</th>
                </tr>
                </thead>
                <tbody>
                {lsCycle.map((period)=>{
                    return(
                        <tr>
                            <td>{period}</td>
                            <td>
                                <input
                                    name="startdate"
                                    className="form-control"
                                    defaultValue={this.getPayCycleDetail(period,"startdate")}
                                    onChange={(e)=>{this.handlePayCycleDetail(e,period,"startdate")}}
                                    placeholder="e.g. 2018-05-24"
                                />
                            </td>
                            <td>
                                <input
                                    name="enddate"
                                    className="form-control"
                                    defaultValue={this.getPayCycleDetail(period,"enddate")}
                                    onChange={(e)=>{this.handlePayCycleDetail(e,period,"enddate")}}
                                    placeholder="e.g. 2018-05-24"
                                />
                            </td>
                            <td>
                                <input
                                    name="paydate"
                                    className="form-control"
                                    defaultValue={this.getPayCycleDetail(period,"paydate")}
                                    onChange={(e)=>{this.handlePayCycleDetail(e,period,"paydate")}}
                                    placeholder="e.g. 2018-05-24"
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )



    }

    render() {


        return (
            <div className="">
                <TopMenu page="Contract setup" menu={this.props.location.pathname}/>

                <div className="pageContentWhite">
                    <div className="container borderShadon">

                        {!this.state.openclose && (
                            <div>
                                <h2>
                                    Contact List ({this.contractCounter()})
                                    <button
                                        className="btn btn-primary pull-right"
                                        onClick={() => {
                                            this.addContactClick()
                                        }}
                                    >ADD
                                    </button>
                                </h2>

                                {this.renderContractList()}
                            </div>
                        )}

                        {this.state.openclose && (
                            <div>
                                <h2 className="designationRole">
                                    New Contract Form
                                    <button
                                        className="btn btn-danger pull-right"
                                        onClick={() => {
                                            this.closeContract();
                                        }}
                                    >Cancel
                                    </button>
                                </h2>

                                {this.renderAddContractForm()}
                            </div>
                        )}

                        {this.state.isLoading && (
                            <div className="myLoader loader">
                                <img src={loaderImg} className=""/>
                                <p>Processing your request please wait ...</p>
                            </div>
                        )}


                    </div>


                </div>


            </div>

        )
    }
}

export default SettingContractPage;

if (typeof  window.$ === 'undefined') {
    alert("jquery not loaded");
}

const OptionProvince = [
    {label: "Western Cape", val: "western cape"},
    {label: "Guateng", val: "guateng"},
];

const OptionMunicipality = [
    {label: "City of cape town", val: "cape town"},
    {label: "Stellembosch", val: "stellembosch"},
];

const OptionDays = [
    {val: "monday", label: "Monday"},
    {val: "tuesday", label: "Tuesday"},
    {val: "wednesday", label: "Wednesday"},
    {val: "thursday", label: "Thursday"},
    {val: "friday", label: "Friday"},
    {val: "saturday", label: "Saturday"},
    {val: "sunday", label: "Sunday"}
];

const OptionFrequency = [
    {label: "", val: ""},
    {label: "Weekly", val: "weekly"},
    {label: "Forthnighly", val: "forthnighly"},
    {label: "Monthly", val: "monthly"},
];

const OptionPayRunArrearsDays = [
    {label: "1", val: "1"},
    {label: "2", val: "2"},
    {label: "3", val: "3"},
    {label: "4", val: "4"},
    {label: "5", val: "5"},
    {label: "6", val: "6"},
    {label: "7", val: "7"},
];


/*

Raduis, Latitude , Longitude

 */

class DatePickerCustomInput extends React.Component {

    render() {
        return (
            <button
                type="button"
                className="form-control date-picker-custom-input"
                stype={{width: "100%"}}
                onClick={this.props.onClick}>
                {this.props.value}
            </button>
        )
    }
}



import  React from 'react';
import eConfig from '../epwp_config.json';
const serverApi = eConfig.serverEpwp;

export default function(ComposedComponent){
    class Authenitcate extends React.Component{
        constructor() {
            super();
            this.state = {
                isAuthnticate: false,
            }
            this.checkLoginNow = this.checkLoginNow.bind(this);

        }

        componentWillMount(){
            this.checkLoginNow();
        }


        checkLoginNow(){
            var token = localStorage.getItem("token");

            if(token ===null){
                window.location.href = "#/login";
            }
            if(token ===undefined){
                window.location.href = "#/login";
            }
            if(token ==="undefined"){
                window.location.href = "#/login";
            }



            var endpoint = "/backend-pmis/auth/checktoken";
            var url = serverApi + endpoint;

            console.log("checkLoginNow > ",url,token);

            let hub={};
            hub.token = token;

            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hub)
            })
                .then((response) => response.json())
                .then((response) => {
                    if(typeof response.Id ===""){
                        window.location.href = "#/login";
                    }
                }).catch((e) => {
                console.log("FETCH ERROR -->> ", e);
                window.location.href = "#/login";
            });
        }



        componentWillUpdate(nextProps){

        }

        render(){
            return(
                <ComposedComponent {...this.props} />
            )
        }
    }

    return  Authenitcate
}
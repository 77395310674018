import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {PostPimsRecord} from "../../api/post_pmis";

export default class DocsSignRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Company: "",
            Ref: "",
            SrcEmail: "",
            SrcPhone: "",
            DestEmail: "",
            DestPhone: "",
            SupplyProfileSrc: "no",
            SupplyProfileDest: "no",
            AttachmentLocation: "remote",
            Comment: "",
            SrcProfile: {
                fullname: "",
                ref: "",
            },
            DestProfile: {
                fullname: "",
                ref: "",
            },

            Attachments: {},
            RequestedSignature: {
                signature: "Main signature that will be use in document",
                initial: "Initial to be used in each page"
            },
            RequestName:""
        }

        this.docToSign = {TemplateName: "", Description: ""};
        this.docLink = "";

        this.syncProps = this.syncProps.bind(this);
        this.submitForm = this.submitForm.bind(this);


    }

    componentDidMount() {
        this.syncProps();
    }

    syncProps = () => {
        let props = this.props;
        const state = props.state;
        const loginUser = props.loginUser;
        const docName = state.TemplateName;
        const docLink = state.downloadLink;

        this.docToSign = docName;
        this.docLink = docLink;

        let DestProfile = state.Employee;
        DestProfile.Ref = state.employeeCode;

        let SrcProfile = loginUser;
        SrcProfile.Ref = loginUser.email;

        let Attachments = {}
        Attachments[docName] = docLink;

        let tmp = this.state;
        tmp.DestProfile = DestProfile;
        tmp.DestProfile.fullname = DestProfile.Name + " " + DestProfile.Surname;
        tmp.Company = state.Company;
        tmp.Ref = state.Ref;
        tmp.Attachments = Attachments;
        tmp.SrcProfile = SrcProfile;

        tmp.SrcEmail = SrcProfile.email;
        tmp.SrcPhone = SrcProfile.phone;
        tmp.RequestName = docName;

        this.setState(tmp);

        console.log("DocsSignRequest componentWillReceiveProps > ", props, this.state);
    }

    getRef = () => {
        return (
            <span>{this.state.Ref}</span>
        )
    }
    getFullname = () => {
        return (
            <span>{this.state.DestProfile.Name + " " + this.state.DestProfile.Surname}</span>
        )
    }

   async submitForm(e) {
        e.preventDefault();

        let hub=this.state;
        let _this = this;
        const endpoint = "/edocs/request/posted";
       console.log("submitForm Edoc request send > ", hub);
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("submitForm Edoc request response > ", data);
            alert("Request for eDoc signature have been submitted!");
            _this.props.handleClose();
        })
    }

    handleInput = (e, key) => {
        this.setState({[key]: e.target.value});
    }
    getHandInput = (key) => {
        return this.state[key];
    }

    render() {

        let docName = "";
        for (let i in this.state.Attachments) {
            docName = i;
        }
        return (
            <div className={"container"}>

                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <h3>Eletronic Request Sign Document</h3>
                        <small>{this.getRef()} | {this.getFullname()} | Doc: {docName}</small>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                            <form onSubmit={this.submitForm}>
                                <table className={"table"}>
                                    <tr>
                                        <td>From</td>
                                        <td>{this.state.SrcProfile.fullname}</td>
                                    </tr>
                                    <tr>
                                        <td>To</td>
                                        <td>{this.state.DestProfile.fullname}</td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Phone:</td>
                                        <td>
                                            <input
                                                className={""}
                                                onChange={(e) => this.handleInput(e, "DestPhone")}
                                                value={this.getHandInput("DestPhone")}
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Receiver Email:</td>
                                        <td>
                                            <input
                                                className={""}
                                                onChange={(e) => this.handleInput(e, "DestEmail")}
                                                value={this.getHandInput("DestEmail")}
                                                required={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Comment:</td>
                                        <td>
                                            <textarea
                                                className={""}
                                                onChange={(e) => this.handleInput(e, "Comment")}
                                                value={this.getHandInput("Comment")}
                                                required={true}/>
                                        </td>
                                    </tr>
                                </table>

                                <button type={"submit"} className={"btn btn-primary pull-right"}>Submit</button>
                            </form>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="secondary">
                            Close
                        </Button>
                        {/*<Button onClick={this.handleClose} color="primary" autoFocus>
                            Agree
                        </Button>*/}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }


}

/*
hub.Organization = "easipath"
	hub.Company = "easipath"
	hub.Ref = "FF-840"
	hub.SrcEmail = "biangacila@gmail.com"
	hub.SrcPhone = "0729139504"
	hub.DestEmail = "imbani16@gmail.com"
	hub.DestPhone = "0729139504"
	hub.SupplyProfileSrc = "no"
	hub.SupplyProfileDest = "no"

	hub.SrcProfile = make(map[string]interface{})
	hub.SrcProfile["fullname"] = "System Testing"
	hub.SrcProfile["role"] = "system"
	hub.SrcProfile["sale-number"] = "09876543210"
	hub.SrcProfile["ref"] = "Quality Assurance <biangacila@gmail.com>"

	hub.DestProfile = make(map[string]interface{})
	hub.DestProfile["fullname"] = "Merveilleux Biangacila"
	hub.DestProfile["role"] = "super"
	hub.DestProfile["passport"] = "OP 654-896"
	hub.DestProfile["phone"] = "0729139504"
	hub.DestProfile["ref"] = "09876543210"

	hub.AttachmentLocation = "local"
	hub.Attachments = make(map[string]string)
	hub.Attachments["CV Merveilleux"] = "./files/cv.pdf"
	hub.Attachments["Motivation Margin Mentor"] = "./files/mm.pdf"
	hub.Attachments["Background Transporter"] = "./files/bg.pdf"
	hub.Attachments["Fixed Terms"] = "http://voip2.easipath.com:33350/api/download/image/9ae5c465198"

	hub.RequestedSignature = make(map[string]string)
	hub.RequestedSignature["signature"] = "Main signature that will be use in document"
	hub.RequestedSignature["initial"] = "Initial to be used in each page"

	hub.Comment = `Most Trusted & Widely Used eSign. 200+ Million Users Can't Be Wrong.
	Anywhere, Any Device · Trusted Industry Leader · Safest & Most Secure · Start Signing Instantly
Types: eSign NDAs, Sales Contracts, Purchase Orders, Offer Letters`
 */


import React from 'react';

import Divider from 'material-ui/Divider';

import RaisedButton from 'material-ui/RaisedButton';

import Paper from 'material-ui/Paper';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';


import TopMenu from '../component/common/top-nav-new';
import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';
import imgContractDash from '../asset/images/contract-dashboar.jpeg';


/**
 * LEt try this to make it work
 */


const serverEpwp = eConfig.serverEpwp;

const styles = {
    customWidth: {
        width: 150,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};
const style = {
    margin: 12,
};


class MyContractDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: "",
            username: "",
            LoginUser: {},

        };


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        this.setState(tmp);
        window.$('.mui-body-table').css('overflow-x', 'auto');
        /**
         * Load default from api
         */
    }

    render(){
        return(
            <MuiThemeProvider>
            <div className="">
                <TopMenu page="Contract Dashboard" menu={this.props.location.pathname}/>


                <div className="row">

                    <div className="col col-lg-3">
                        {/*LET BUILD OUR MENU HERE*/}
                        <SideMenu />
                    </div>

                    <div className="col col-lg-9">
                        <Paper style={style} zDepth={1} >

                            <div className="container">
                                <br/>

                                <div className="row">
                                    <div className="col col-lg-10">

                                        {/*<RaisedButton
                                            label="Employee"
                                            secondary={true}
                                            style={style}
                                            onClick={()=>{
                                                window.location.href ="#/employee";
                                            }}
                                        />
                                        <RaisedButton
                                            label="Clock"
                                            primary={true}
                                            style={style}
                                            onClick={()=>{
                                                window.location.href ="#/clocking";
                                            }}
                                        />*/}

                                        <img src={imgContractDash} className="imgContractDash"/>

                                        <Divider className="col col-lg-10"/>

                                    </div>
                                </div>

                            </div>


                        </Paper>
                    </div>

                </div>

            </div>
            </MuiThemeProvider>

        )
    }

}

export default MyContractDashboard;

/**
 * Created by dev1 on 2017/01/26.
 */

/**
 * Created by dev1 on 2017/01/26.
 */


import React  from 'react';
import {Link} from 'react-router-dom';
import TopMenu from '../component/common/top-nav-new';


class MyContainer extends React.Component {


    render() {
        return (
            <div className="">
                <TopMenu page="Page Not Find" menu={this.props.location.pathname}/>
            <div className="container d-table">
                <div className="d-100vh-va-middle">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="clearfix">
                                <h1 className="float-xs-left display-3 mr-2">404</h1>
                                <h4 className="pt-1">Oops! You're lost.</h4>
                                <p className="text-muted">The page you are looking for was not found.</p>
                            </div>
                            <div className="input-prepend input-group">
                        <span className="input-group-addon"><i className="fa fa-search"></i>
                        </span>
                                <input id="prependedInput" className="form-control" size="16" type="text" placeholder="What are you looking for?" />
                        <span className="input-group-btn">
                            <button className="btn btn-info" type="button">Search</button>
                            Or <Link to="/login">Click Here to login</Link>
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )

    }

}


export default MyContainer;



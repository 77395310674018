import React from 'react';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';

import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';



import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';
import '../asset/styles/setting-contract.css';
import '../asset/styles/stepbystepform.css';


import DatePicker from 'material-ui/DatePicker';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';

import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';
import loaderImg from '../asset/ajax_loader.gif';
import CustomInsertModal from '../component/insertEmployeeModal';
import MyComponentModal from "../component/common/modal-bootstrap";

let serverEpwp = eConfig.serverEpwp;

const style = {
    margin: 12,
};


class SettingEmployeeContractCsvPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},
            Employees: [],
            Contracts: [],
            mycontract: "",
            currentEmployee: {},
            employeeEdit: {},
            openclose: false,
            openclosestatus: false,
            opencloseedit: false,
            terminationReason: "",
            terminationDate: moment().toISOString().slice(0, 10),
            hideColumnVariable: {
                Address: false,
                Gender: false,
                Email: false,
                CellphoneNumber: false,
            },
            empStatus: "trial"

        };

        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchEmployees = this.fetchEmployees.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.renderNewEmployeeForm = this.renderNewEmployeeForm.bind(this);
        this.submitForm = this.submitForm.bind(this);


    }


    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchEmployees();
        this.fetchContracts();
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Contracts", _this);
        console.log("--)Contracts(--> ", this.Contracts);
    }
    fetchEmployees() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Employees", _this);

        let emp = [];

        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            row.Id = parseInt(row.Id);
            emp.push(row);
        }
        let tmp = this.state;
        tmp.Employees = emp;
        this.setState(tmp);
        console.log("--)(--> ", this.state);

    }
    submitForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you sure that you want to submit this form?")
        if (!text) {
            return;
        }


        let endpoint = "/api/click/csv";
        let url = serverEpwp + endpoint;
        var inputUpload = document.getElementById("myfile");
        var myfile = inputUpload.files[0];
        if (!myfile) {
            alert("No file selected.");
            return;
        }

        const filename = myfile.name;

        console.log(":)--> F-1");
        let hub = {};
        hub = this.state;
        hub.FileName = filename;
        hub.Contract = this.state.mycontract;
        hub.company = this.state.company;

        let _this=this;

        var reader = new FileReader();
        console.log(":)--> F-2");
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            hub.Base64String = reader.result;
            hub.company = _this.state.company;
            hub.email =_this.state.username;
            console.log(":)--> F-3");
            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hub)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    console.log(":)--> F-4");
                    alert("SUCCESS Uploaded you will get feedback on your email  ");
                    this.setState({view: "list"});
                });

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    renderNewEmployeeForm() {
        let _this = this;

        return (
            <div className={"centerContent"}>
                <h2 className="alert alert-primary companyList ImportEmployeeDatabaseCsvTitle">
                    Import Clock Csv
                </h2>

                <form className={"form"} onSubmit={this.submitForm} id={"fmSubmit"}>
                    {this._render2ColInput("Csv File", "myfile", "file", "", true,
                        "", "", "", "", true, _this)}

                    <button type="submit" className={"btn btn-primary pull-right"}>Submit</button>
                    &nbsp;&nbsp;
                </form>


            </div>
        )


    }

    render() {
        return (

            <div className="">
                <TopMenu page="Clocking Csv" menu={this.props.location.pathname}/>

                <MuiThemeProvider>

                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>
                                <div className="centerContent">

                                    {this.renderNewEmployeeForm()}

                                </div>
                            </Paper>
                        </div>

                    </div>

                </MuiThemeProvider>
            </div>
        )
    }


}


export default SettingEmployeeContractCsvPage;

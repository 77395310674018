import React from 'react';


class CompDashBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        const colorBox = "card-"+this.props.color;
        return(
            <div className="col-sm-6 col-lg-3">
                <div className={"card card-inverse "+colorBox}>
                    <div className="card-block pb-0">
                        <div className="btn-group float-xs-right">
                            <button type="button" className="btn btn-transparent active dropdown-toggle p-0"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="icon-settings"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">Worker</a>
                                <a className="dropdown-item" href="#">Worker-leader</a>
                                <a className="dropdown-item" href="#">Admin</a>
                            </div>
                        </div>
                        <h4 className="mb-0">{this.props.total}</h4>
                        <p>{this.props.name}</p>
                    </div>
                    <div className="chart-wrapper px-1" style={{"height":"70px"}}>
                        <canvas id="card-chart1" className="chart" height="70"></canvas>
                    </div>
                </div>
            </div>
        )
    }

}

export default CompDashBox;

import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';

import React from 'react';
import TokenService from '../api/tokeninfo';
//import CompDashBox from "../component/dash-box";
import CompPageHeaderAddList from "../component/page-header-add-list";
import Footer from "../component/common/footer";
import CompHeader from "../component/common/top-nav";
import CompNavInfo from "../component/nav-info";
import eConfig from '../epwp_config.json';
import MyComponentModal from "../component/common/modal-bootstrap";
import logo from "../asset/images/landing-page/PMISLogoMockUp.png";
import '../asset/styles/register-page.css';
import ImagePreload from '../asset/ajax_loader.gif';
const serverEpwp = eConfig.serverEpwp;


class registerContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},
            Companies: [],
            currentCompany: {},
            openclose: false,
            FirstName:"",
            LastName:"",
            Name: "",
            Description: "",
            Industry: "",
            RegustrationNumber: "",
            VatNumber: "",
            Address: "",
            Province: "",
            City: "",
            Suburb: "",
            PostalCode: "",
            ContactNumber: "",
            ContactEmail: "",
            ContactPerson: "",
            Website: "",
            isFetching: false,

        };


        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.actionViewompany = this.actionViewompany.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.setViewChange = this.setViewChange.bind(this);
        this.cancelAddForm = this.cancelAddForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderSelectInput =this.renderSelectInput.bind(this);
        this.showPreload = this.showPreload.bind(this);

        this.openPreload = this.openPreload.bind(this);
        this.closePreload = this.closePreload.bind(this);
    }


    openPreload(){
        let tmp = this.state;
        tmp.isFetching = true;
        tmp.List = [];
        this.setState(tmp)
    }
    closePreload(){
        let tmp = this.state;
        tmp.isFetching = false;
        this.setState(tmp)
    }


    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchCompanies();
    }


    fetchCompanies() {
        // let company = this.state.company.toLocaleLowerCase();
        let endpoint = "/api/company/list";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = this.state.company;
        let _this = this;
        this.loadList(url, hub, "Companies", _this);
        console.log("--)(--> ", this.state);

    }

    setViewChange(val) {
        let tmp = this.state;
        tmp.view = val;
        this.setState(tmp);
    }

    openModal() {
        var tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }

    closeModal() {
        var tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }


    actionViewompany(row) {
        let tmp = this.state;
        tmp.currentCompany = row;
        this.setState(tmp);
        this.openModal()
        // alert("View company request popup");

    }

    cancelAddForm() {
        let el = window.document.getElementById("fmSubmit");
        el.reset();
        this.setState({view: "list"});
    }

    submitForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you sure that you want to submit this form?")
        if (!text) {
            return;
        }


        let endpoint = "/api/company/new";
        let url = serverEpwp + endpoint;
        let hub = this.state;
        hub.ContactPerson =this.state.FirstName+" "+this.state.LastName;
        /*delete(hub,"Companies");
        window.delete(hub,"view");
        window.delete(hub,"currentCompany");
        window.delete(hub,"openclose");*/
        this.openPreload();

        /**
         * let disable the button
         */

        window.document.getElementById("btnSubmit").disabled = true;
        window.document.getElementById("btnCancel").disabled = true;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                this.closePreload();
                console.log("+++++> new user response response ",  responseData);
                alert("Thank for register with us\n An email will be sent to your address with activation link\nCheck your email ["+this.state.ContactEmail+"]");
                window.location.href ="#/login";
            });
    }

    showPreload(){

        if(!this.state.isFetching){
            return ""
        }
        return(
            <div className={"row container myPreload"} >
                <div className={"col col-lg-12 col-md-2 col-md-offset-5"} >
                    <img src={ImagePreload} style={{margin:"0 auto 0"}}/>
                    <p style={{color:"red"}}>Please be patient busy processing your registration ....</p>
                </div>
            </div>
        )
    }



    renderHeader() {
        return(
            <div>
            <div className="registerHeader2">
                <img src={logo} width={50} height={50} className="pull-left imageHeader"/>
                <span className="titleName">Payroll Management system</span>

            </div>
                <hr/>
            </div>
        )
    }

    renderInput(label, inputName, inputType, requered){
        return (
            <div className={""}>
                <label></label>
                <input
                    type={inputType}
                    className="form-control"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    placeholder={label}
                    onChange={this._handleInput}
                    required={requered}
                />
            </div>
        )
    }
    renderSelectInput(label, inputName, opts, requered){
        return (
            <div className="form-group">
                <label>{label}</label>
                <select
                    className="myCombo"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    onChange={this._handleInput}
                    required={requered}
                >
                    <option value={""}></option>
                    {opts.map((row)=>{
                        return(
                            <option value={row.name}>{row.desc}</option>
                        )
                    })}
                </select>
            </div>
        )
    }



    renderContent(){
        return(
            <div className="container mainContent">
                <div className="cButton">
                    <h2>New User Registration</h2>
                    <small>First time user set-up on the system</small>
                </div>
                <form onSubmit={this.submitForm} id="fnSubmit">
                {this.renderInput("First Name", "FirstName", "text", true)}
                {this.renderInput("Last name", "LastName", "text", true)}
                {this.renderInput("Email address", "ContactEmail", "text", true)}
                {this.renderInput("Mobile number", "ContactNumber", "text", true)}
                {this.renderInput("Company name", "Name", "text", true)}
                <br/>
                {this.renderSelectInput("Province", "Province", Provinces, true)}

                <div className="cButton">
                    <br/>

                <button
                    id="btnCancel"
                    type={"button"}
                    className={"btn btn-dander"}
                    onClick={() => {window.location.href="#/home"}}>Cancel</button>
                    &nbsp;&nbsp;
                    <button type="submit" id="btnSubmit" className={"btn btn-primary"}>Submit</button>
                </div>

                </form>

            </div>
        )
    }

    render() {
        return (
            <div>
                {this.renderHeader()}
                {this.renderContent()}
                {this.showPreload()}
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }

}

export default registerContainer;

const Provinces = [
    {name:"western cape",desc:"Western Cape"},
    {name:"Eastern Cape",desc:"Eastern Cape"},
    {name:"Free State",desc:"Free State"},
    {name:"Gauteng",desc:"Gauteng"},
    {name:"KwaZulu-Natal",desc:"KwaZulu-Natal"},
    {name:"Limpopo",desc:"Limpopo"},
    {name:"Mpumalanga",desc:"Mpumalanga"},
    {name:"North West",desc:"North West"},
    {name:"Northern Cape",desc:"Northern Cape"},
];

import React from 'react';


class CompPageHeaderAddList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.setViewChange = this.setViewChange.bind(this);
    }

    setViewChange(e){
        let opt  = e.target.id;
        this.props.setViewChange(opt);
    }

    render() {
        return (
            <div>
                <div className="col-xs-5">
                    <h4 className="card-title">{this.props.pagename}</h4>
                    <div className="small text-muted" style={{"marginTop": "-10px"}}>{this.props.pagedesc}</div>
                </div>
                <div className="col-xs-7">
                    <div className="btn-toolbar float-xs-right" role="toolbar"
                         aria-label="Toolbar with button groups" >
                        <div className="btn-group hidden-md-down"  aria-label="First group">
                            <label className="btn btn-outline-secondary">
                                <button
                                    type="radio"
                                    name="options"
                                    id="add"
                                    onClick={this.setViewChange}
                                >
                                <i className="icon-plus"></i>&nbsp;Add
                                </button>
                            </label>
                            <label className="btn btn-outline-secondary active hidden-md-down">
                                <button
                                    type="radio"
                                    name="options"
                                    id="list"
                                    onClick={this.setViewChange}
                                >
                                <i className="icon-list"></i>&nbsp;List
                                </button>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

        )
    }


}

export default CompPageHeaderAddList;
import React from 'react';

import '../asset/styles/setting-employee.css';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';
import loaderImg from '../asset/ajax_loader.gif';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const serverEpwp = eConfig.serverEpwp;



export default class CustomInsertModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company:"",
            username:"",
            role:"",
            LoginUser:"",
            Contracts:[],
            Sites:[],
            currContract:"",
            site:"",
        }

        /**
         *
         * @type {function(this:CustomInsertModal)}
         */
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        this.setState(tmp);


        this.fetchContract = this.fetchContract.bind(this);
        this.fetchSites = this.fetchSites.bind(this);
        this.handleInputContract = this.handleInputContract.bind(this);
        this.renderSite = this.renderSite.bind(this);
        this.renderContract = this.renderContract.bind(this);


        /**
         * Load companies from api
         */
        this.fetchContract();
        this.fetchSites();
    }

    componentDidMount(){
    }
    componentWillMount() {
    }

    fetchSites(){
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/site/list-company";
        let url = serverEpwp + endpoint;
        let hub = {
            Company: this.state.company
        };
        hub.username = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Sites = responseData;
                this.setState(tmp);

                console.log("+++++>fetchSites response ", this.state);
            });
    }
    fetchContract() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {
            Company: this.state.company
        };
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Contracts = responseData;
                this.setState(tmp);

                console.log("+++++>fetchContracts response ", this.state);
            });

    }

    handleSaveBtnClick = () => {
        const {columns, onSave} = this.props;
        const newRow = {};
        columns.forEach((column, i) => {
            newRow[column.field] = this.refs[column.field].value;
        }, this);
        // You should call onSave function and give the new row
        onSave(newRow);
    }

    handleInputContract(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        let el=window.document.getElementById("currContract");
        tmp[key] = el.value;
        this.setState(tmp);
    }
    handleInputSite(e) {
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        let el=window.document.getElementById("currContract");
        tmp[key] = el.value;
        this.setState(tmp);
    }

    renderSite(){
        let ls  =[];
        ls.push({val:"",desc:"--select--"});
        for(let i in this.state.Sites){
            let row  = this.state.Sites[i];
            if(row.ContractName ===this.state.currContract){
                ls.push({val:row.SiteName,desc:row.SiteName});
            }
        }

        console.log("handleInputContract -->",ls,this.state.Sites);


        return ls.map((row)=>{
            return(
                <option value={row.val}>{row.desc}</option>
            )
        })
    }

    renderContract(){
        let ls  =[];
        ls.push({val:"",desc:"--select--"});
        for(let i in this.state.Contracts){
            let row  = this.state.Contracts[i];
            ls.push({val:row.Name,desc:row.Name});
        }

        return ls.map((row)=>{
            return(
                <option value={row.val}>{row.desc}</option>
            )
        })
    }

    render() {
        const {
            onModalClose,
            onSave,
            columns,
            validateState,
            ignoreEditable
        } = this.props;
        return (
            <div className="modal-content react-bs-table-insert-modal contentBody">
                <div className="modal-header react-bs-table-inser-modal-header">
                        <span>
                            <button type="button" className="close"  onClick={onModalClose}>
                            <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                            <h4 className="modal-title">Add Employee Row</h4>
                        </span>
                </div>
                <div className='modal-content myContentAddEmployee'>
                    <div>
                        <div className='form-group'>
                            <label>{"Contract"} : </label>
                            <select
                                ref={"Contract"}
                                name={"currContract"}
                                id={"currContract"}
                                className=" editor edit-text select"
                                type='text'
                                onChange={this.handleInputContract}
                                defaultValue={this.state.currContract}
                            >
                                {this.renderContract()}
                            </select>
                        </div>

                        <div className='form-group'>
                            <label>{"Site"} : </label>
                            <select
                                ref={"Site"}
                                name={"site"}
                                className="editor edit-text select"
                            >
                                {this.renderSite()}
                            </select>
                        </div>

                        <div className='form-group'>
                            <label>{"Cellphone Number"} : </label>
                            <input
                                ref={"CellphoneNumber"}
                                name={"CellphoneNumber"}
                                className="editor edit-text select"
                                type={"text"}
                            />

                        </div>
                        <div className='form-group'>
                            <label>{"Email"} : </label>
                            <input
                                ref={"Email"}
                                name={"Email"}
                                className="editor edit-text select"
                                type={"email"}
                            />

                        </div>




                        {
                            columns.map((column, i) => {
                                const {
                                    editable,
                                    format,
                                    field,
                                    name,
                                    hiddenOnInsert
                                } = column;

                                if (hiddenOnInsert) {
                                    // when you want same auto generate value
                                    // and not allow edit, for example ID field
                                    return null;
                                }

                                /**
                                 ** Let customer option box for Designation
                                 */
                                if(name==="Designation"){
                                    return (
                                        <div className='form-group' key={field}>
                                            <label>{name} : </label>
                                            <select
                                                ref={field}
                                                type='text'
                                                defaultValue={''}
                                                className=" editor edit-text select"
                                                placeholder={field}
                                            >
                                                <option value={"Worker"}>Worker</option>
                                                <option value={"Worker leader"}>Worker Leader</option>
                                            </select>
                                            {error}
                                        </div>
                                    );
                                }

                                /**
                                 ** Let customer option box for Designation
                                 */

                                if(name==="Gender"){
                                    return (
                                        <div className='form-group' key={field}>
                                            <label>{name} : </label>
                                            <select
                                                ref={field}
                                                type='text'
                                                defaultValue={''}
                                                className="editor edit-text select"
                                                placeholder={field}
                                            >
                                                <option value={"Male"}>Male</option>
                                                <option value={"Female"}>Female</option>
                                            </select>
                                            {error}
                                        </div>
                                    );
                                }
                                /**
                                 ** Let customer option box for Site
                                 */

                                if(name==="Site"){
                                    return ("");
                                }
                                if(name==="Id"){
                                    return ("");
                                }
                                if(name==="Email"){
                                    return ("");
                                }
                                if(name==="Contract"){
                                    return ("");
                                }
                                if(name==="CellphoneNumber"){
                                    return ("");
                                }


                                const error = validateState[field] ?
                                    (<span className='help-block bg-danger'>{validateState[field]}</span>) :
                                    null;
                                return (
                                    <div className='form-group' key={field}>
                                        <label>{name} : </label>
                                        <input
                                            ref={field}
                                            type='text'
                                            defaultValue={''}
                                            className="form-control editor edit-text"
                                            placeholder={field}
                                        />
                                        {error}
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="modal-footer react-bs-table-inser-modal-footer">
                        <button className='btn btn-default btn-secondary ' onClick={onModalClose}>Close</button>
                        &nbsp;&nbsp;<button className='btn btn-primary' onClick={() => this.handleSaveBtnClick(columns, onSave)}>
                            Save
                        </button>
                    </div>
                </div>


                {/*<div class="modal-content react-bs-table-insert-modal">
                    <div class="modal-header react-bs-table-inser-modal-header">
                        <span>
                            <button type="button" class="close">
                            <span aria-hidden="true">×</span>
                                <span class="sr-only">Close</span>
                            </button>
                            <h4 class="modal-title">Add Row</h4>
                        </span>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Code</label>
                            <input
                                placeholder="Code"
                                class=" form-control editor edit-text"
                                type="text"/>
                        </div>

                    </div>
                    <div class="modal-footer react-bs-table-inser-modal-footer">
                        <button type="button" class="btn btn-default btn-secondary ">Close</button>
                        <button type="button" class="btn btn-primary ">Save</button>
                    </div>
                </div>*/}
            </div>
        );
    }
}



import React from 'react';
import '../asset/styles/landing-page.css';
import logo from "../asset/images/landing-page/PMISLogoMockUp.png";
import imgManagement from "../asset/images/landing-page/Mangement.png";
import imgDatabase from "../asset/images/landing-page/database.png";
import imgEletronicInt from "../asset/images/landing-page/Payments.png";
import imgIntelligenceReport from  "../asset/images/landing-page/documents.png";
import imgOnSiteSupport from  "../asset/images/landing-page/assitance.png";

class LandingContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.renderTopMenu  = this.renderTopMenu.bind(this);
        this.renderBigImage = this.renderBigImage.bind(this);
        this.renderNearRealTimeView = this.renderNearRealTimeView.bind(this);
        this.renderIntuitiveFeaturesDiv = this.renderIntuitiveFeaturesDiv.bind(this);
        this.renderService = this.renderService.bind(this);
        this.blockService = this.blockService.bind(this);
    }

    renderTopMenu() {
        return(
            <div className="myTop " >
                <img src={logo} width={30} height={30} className="pull-left"/>
                <button className="btn btn-primary pull-right btnRegister"  onClick={()=>{window.location.href="#register"}}>Register</button>
                <button className="btn btn-success pull-right" onClick={()=>{window.location.href="#login"}}>Login</button>
            </div>
        )
    }

    renderBigImage(){
        return(

            <div className="large-8 large-offset-0 xlarge-6 small-12 medium-10 medium-offset-1 columns end" style={{minHeight:"75%",height:"450px"}}>
            <div className="jumbotron bigImageDiv">
                <p>Effortlessly Manage EPWP <br/>Payrool & Performance Reporting</p>
                <small className="col-lg-6">
                    Stay on top of EPWP contracts with our integrated management system which uses custom-developed processes and information to productively and efficiently manage your programme payroll.
                </small>

            </div>
            </div>

        )
    }

    renderNearRealTimeView(){
        return(
            <div className="rearRealtimeDiv">
                <p>Get a near real-time view of your payroll</p>
                <div className="small">log in online anywehere on your PC, tablet or phone and see up-to-date payroll data</div>
            </div>
        )
    }

    renderIntuitiveFeaturesDiv(){
        return(
            <div className="intuitiveFeaturesDiv">
                <p>Intuitive features that will automate processed accurately</p>
                <small>
                    EPWP apyroll and report generation sofware with all the production enhancing tools you need to manage your programme: up to 10 users<br/>
                    face-to-face support; with full programme and contract customisation
                </small>
            </div>
        )
    }

    blockService(imgSrc,title,content){
        return(
            <div className="col col-lg-4">
                <img src={imgSrc} width={60} height={60}/>
                <br/><br/>
                <p className="title">{title}</p>
                <br/>
                <p>{content}</p>
            </div>
        )
    }
    renderService(){
        return(
            <div>
            <div className="row myService container">

                {this.blockService(imgDatabase,"Database Management Integrity Validation",
                    `Customizable Company Set Up.
                       Electronic take-on of employees.
                       Employee records validated.
                       Validation warnings.
                       Employee database maintained per host company and linked to relative municipality and provincial or national government department
                `)}

                {this.blockService(imgManagement,"Payroll and Time & Attendance Management",
                    `Time & Attendance Management
                    Customisable wage rates
                    Payroll accuracy
                    Edit and approve employee times
                    View employee or global wage bill
                    Manage multiple EPWP contracts
                    Allows for weekly, fortnightly and monthly pay-runs
                    `)}

                {this.blockService(imgEletronicInt,"Eletronic Interfaces",`
                    Automated wage payment protocol to all banks

                    Host company control of automated payment

                    Secure 3rd party payments solution offered

                    Significant bank transaction fee savings to service provider companies

                    Seamless interface with Sage Pastel`
                )}
            </div>
            <div className="row myService container">
                {this.blockService(imgIntelligenceReport,"imgIntelligence Report Engien & Document",
                `All employee records are digitised and electronic copies archived
                HR data analytics provides actionable payroll insights
                Auto generate U-19 reports
                Auto generate municipality labour reports
                Municipality invoice preparation and calculation
                Big data views`
                )}

                {this.blockService(imgOnSiteSupport,"On-Site Support & Assistance",
                `System customization and initial on-take performed
                Support and assistance will be conducted on-site
                Systems administrator training`
                )}

                {this.blockService(imgManagement,"Payroll and Time & Attendance Management",
                    `Time & Attendance Management
                    Customisable wage rates
                    Payroll accuracy
                    Edit and approve employee times
                    View employee or global wage bill
                    Manage multiple EPWP contracts
                    Allows for weekly, fortnightly and monthly pay-runs
                    `)}


            </div>

            </div>


        )
    }


    render() {
        return (
            <div className="myPage">
                {this.renderTopMenu()}
                {this.renderBigImage()}
                {this.renderNearRealTimeView()}
                {this.renderIntuitiveFeaturesDiv()}
                <div className="row">
                    <div className="col col-lg-12"><br/><br/></div>
                </div>
                {this.renderService()}
            </div>

        )
    }
}


export default LandingContainer;


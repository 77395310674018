import React from 'react';

import {PostPimsRecord} from "../../api/post_pmis";

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
    root: {
        width: '50%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
});


export default class DocsSigned extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company:"",
            organization:"",
            ref:"",
            docCategory:"",
            List:[]
        }

        this.handleListItem = this.handleListItem.bind(this);
    }

    componentDidMount(){
        //console.log(":> State > ",this.props.state);
        if(this.props.company !==""){
            this.fetchHistory();
        }

    }
   async componentWillReceiveProps(nextProps){
        if(nextProps.state.company !==""){
            if(nextProps.company !==this.state.company){

                let tmp  =this.state;
                tmp.organization = nextProps.company;
                tmp.company = nextProps.company;
                tmp.ref =nextProps.state.Ref;
                tmp.docCategory = nextProps.docCategory;
                this.setState(tmp);

                console.log(":> props 2 > ",this.state);

                this.fetchHistory();
            }

        }
    }

    async fetchHistory(){
        let hub={
            organization:this.state.organization,
            company:this.state.company,
            ref:this.state.ref,
            docCategory:this.state.docCategory
        };
        let _this = this;
        const endpoint = "/edocs/response/history";
        console.log("fetchHistory t send > ", hub);
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("fetchHistory response > ", data);
            _this.setState({List:data.DATA});
        })
    }

    handleListItem(myUrl){
        console.log("handleListItem url > ",myUrl);
        if(myUrl ===""){
            alert("Sorry we can't open this blank url")
            return
        }
        window.open(myUrl,"_blank");
    }

    renderList=()=>{
        /*
        o.DocCategory  = obj.DocCategory
        o.Orgin = "system"
        //o.RequestBy = obj.
        o.DropboxIdDocument = obj.docMainDocLink
        o.DropboxIdSignature = obj.docSignatureLink
        o.DropboxIdInitial = obj.docInitialLink
         */
        return this.state.List.map((row,index)=>{
            return(
                <ListItem button key={index} onClick={()=>{
                    this.handleListItem(row.DropboxIdDocument);
                }}>
                    <ListItemIcon>
                        <img src={row.DropboxIdSignature} width={30} height={30}/>
                    </ListItemIcon>
                    <ListItemText  primary={row.DocCategory} />
                    <ListItemSecondaryAction>
                        <img src={row.DropboxIdInitial} width={25} height={25}/>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    render(){
        return(
            <div className={"container"} >
                <div className={"row"}>
                    <div className="col col-lg-10">
                        <div className={styles.root}>
                            <List component="nav">
                                <ListItem button>
                                    <ListItemText primary="Available Signed Document" />
                                </ListItem>
                            </List>
                            <Divider />
                            <List component="nav">
                                {this.renderList()}
                            </List>
                        </div>
                    </div>
                </div>

            </div>
        )
    }



}
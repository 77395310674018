import React from 'react';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';


import TokenService from '../api/tokeninfo';
//import CompDashBox from "../component/dash-box";
import CompPageHeaderAddList from "../component/page-header-add-list";
import Footer from "../component/common/footer";
import CompHeader from "../component/common/top-nav";
import CompNavInfo from "../component/nav-info";
import eConfig from '../epwp_config.json';
import MyComponentModal from "../component/common/modal-bootstrap";

const serverEpwp = eConfig.serverEpwp;


class companyContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},
            Companies: [],
            currentCompany: {},
            openclose: false,
            Name: "",
            Description: "",
            Industry: "",
            RegustrationNumber: "",
            VatNumber: "",
            Address: "",
            Province: "",
            City: "",
            Suburb: "",
            PostalCode: "",
            ContactNumber: "",
            ContactEmail: "",
            ContactPerson: "",
            Website: "",

        };


        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchCompanies = this.fetchCompanies.bind(this);
        this.renderCompanies = this.renderCompanies.bind(this);
        this.actionViewompany = this.actionViewompany.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.setViewChange = this.setViewChange.bind(this);
        this.renderNewCompanyForm = this.renderNewCompanyForm.bind(this);
        this.cancelAddForm = this.cancelAddForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchCompanies();
    }


    fetchCompanies() {
       // let company = this.state.company.toLocaleLowerCase();
        let endpoint = "/api/company/list";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = this.state.company;
        let _this = this;
        this.loadList(url, hub, "Companies", _this);
        console.log("--)(--> ", this.state);

    }

    setViewChange(val) {
        let tmp = this.state;
        tmp.view = val;
        this.setState(tmp);
    }

    openModal() {
        var tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }

    closeModal() {
        var tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }


    actionViewompany(row) {
        let tmp = this.state;
        tmp.currentCompany = row;
        this.setState(tmp);
        this.openModal()
        // alert("View company request popup");

    }

    cancelAddForm() {
        let el = window.document.getElementById("fmSubmit");
        el.reset();
        this.setState({view: "list"});
    }

    submitForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you sure that you want to submit this form?")
        if (!text) {
            return;
        }


        let endpoint = "/api/company/new";
        let url = serverEpwp + endpoint;
        let hub = this.state;
        /*delete(hub,"Companies");
        window.delete(hub,"view");
        window.delete(hub,"currentCompany");
        window.delete(hub,"openclose");*/
        let _this = this;
        this.loadList(url, hub, "feedbackSubmit", _this);



        console.log("CALL TO API > ",this.state.feedbackSubmit);
        this.fetchCompanies();
    }

    renderNewCompanyForm() {
        let _this = this;
        if (this.state.view !== "add") {
            return "";
        }
        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    New Company
                </label>

                <form className={"form"} onSubmit={this.submitForm} id={"fmSubmit"}>

                    {this._render2ColInput("Name", "Name", "text", "", true,
                        "Description", "Description", "text", "", true, _this)}

                    {this._render2ColInput("Industry", "Industry", "text", "", true,
                        "Regustration Number", "RegustrationNumber", "text", "", true, _this)}

                    {this._render2ColInput("Vat Number", "VatNumber", "text", "", true,
                        "Address", "Address", "text", "", true, _this)}

                    {this._render2ColInput("Province", "Province", "text", "", true,
                        "City", "City", "text", "", true, _this)}

                    {this._render2ColInput("Suburb", "Suburb", "text", "", true,
                        "PostalCode", "PostalCode", "text", "", true, _this)}

                    {this._render2ColInput("ContactPerson", "ContactPerson", "text", "", true,
                        "ContactNumber", "ContactNumber", "text", "", true, _this)}
                    {this._render2ColInput("ContactEmail", "ContactEmail", "text", "", true,
                        "Website", "Website", "text", "", true, _this)}



                    <button type="submit" className={"btn btn-primary pull-right"}>Submit</button>
                    &nbsp;&nbsp;
                    <button type={"button"} className={"btn btn-dander pull-right"} onClick={() => {this.cancelAddForm()}}>Cancel
                    </button>
                </form>


            </div>
        )


    }

    renderCompanies() {
        if (this.state.view !== "list") {
            return "";
        }
        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    Available Company List
                </label>

                <table className={"table"} style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>ContactPerson</th>
                        <th>ContactEmail</th>
                        <th>ContactPhone</th>
                        <th>Created</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.state.Companies.map((row) => {
                        return (
                            <tr key={row.Id}>
                                <td>{row.Name}</td>
                                <td>{row.ContactNumber}</td>
                                <td>{row.Address}, {row.goodwood}, {row.City}</td>
                                <td>{row.ContactPerson}</td>
                                <td>{row.ContactEmail}</td>
                                <td>{row.ContactNumber}</td>
                                <td>{row.OrgDateTime}</td>
                                <td>
                                    <button className={"clickMe btn btn-primary"} onClick={() => {
                                        this.actionViewompany(row)
                                    }}>View
                                    </button>
                                    &nbsp;
                                </td>
                                <td>
                                    <button className={"clickMe btn btn-danger"} onClick={() => {
                                        alert("Feature not implemented")
                                    }}>Delete
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        return (
            <div>

                <main className="main">

                    <CompNavInfo mainmenu={"Super"} pagename={"Company"} pagelink={"#/company"}/>


                    <div className="container-fluid">

                        <div className="animated fadeIn">

                            <div className="card">
                                <div className="card-block">
                                    <div className="row">
                                        <CompPageHeaderAddList
                                            pagename={"Company"}
                                            pagedesc={"Super user only access,you can add or disable"}
                                            pagelink={"#/company"}
                                            setViewChange={this.setViewChange}
                                        />
                                    </div>
                                    <div className="row">
                                        <hr/>
                                    </div>
                                    <div className="chart-wrapper" style={{"min-height": "300px", "marginTop": "10px","padding": "15px"}}>

                                        {this.renderCompanies()}

                                        {this.renderNewCompanyForm()}

                                    </div>
                                </div>
                                <div className="card-footer">
                                    <MyComponentModal
                                        obectname={"Company Detail [" + this.state.currentCompany.Name + "]"}
                                        openclose={this.state.openclose}
                                        close={this.closeModal}
                                        open={this.openModal}
                                        data={this.state.currentCompany}
                                    />
                                </div>
                            </div>


                        </div>


                    </div>

                </main>

                <Footer/>

            </div>
        );
    }

}

export default companyContainer;

import React from 'react';
import Workbook from 'react-excel-workbook';
import xlsx from 'xlsx';
import fileSave from 'file-saver';
import moment from 'moment';


class ComExcelBook extends React.Component {

    constructor(props) {
        super(props);
         this._handleClick = this._handleClick.bind(this);
    }

    _handleClick(){
        let wb = xlsx.utils.book_new();
        wb.Props = {
            Title: "SheetJS Tutorial",
            Subject: "Test",
            Author: "Red Stapler",
            CreatedDate: new Date(2017,12,19)
        };

        wb.SheetNames.push("Test Sheet");

        let data = this.props.data;

        let ws_data = [['' , '','','','Effective',moment().format("DD MMM YYYY")]];  //a row with 2 columns
        ws_data.push(['Payroll Number' , 'Name','Surname','Nett Pay','ID Number','Description']);
        let total = 0;


        for(let i in data){
            let row = data[i];

            let company = row.Company;
            if(company ==="Mshengu Toilet Hire"){
                company = "Mshengu TH"
            }

            total = total + parseFloat(row.NetPay);
            ws_data.push([
                row.EmployeeCode,
                row.Name,
                row.Surname,
                row.NetPay,
                row.EmployeeIDNo,
                company
            ]);
        }

        ws_data.push(['' , '','Total:',total.toFixed(2),'','']);

        let ws = xlsx.utils.aoa_to_sheet(ws_data);

        wb.Sheets["Test Sheet"] = ws;
        let wbout = xlsx.write(wb, {bookType:'xlsx',  type: 'binary'});

        function s2ab(s) {
            let  buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
            let view = new Uint8Array(buf);  //create uint8array as viewer
            for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
            return buf;
        }


        fileSave.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'test.xlsx');



    }
    render() {


        /*const data = [
            {
                aaa: 1,
                bbb: 2,
                ccc: 3
            },
            {
                aaa: 4,
                bbb: 5,
                ccc: 6
            }
        ];*/

        //let data = this.props.data;
        return (
            <div className="row text-center" style={{marginTop: '10px'}}>
                <a className="btn btn-lg btn-primary clickMe" onClick={()=>{this._handleClick()}}>
                    <i className="fa fa-cloud-download"/>
                    Download Excel
                </a>
                {/*<Workbook filename="procard.xlsx" element={
                    <button className="btn btn-lg btn-primary">
                        <i className="fa fa-cloud-download"/>
                        Download Excel
                    </button>
                }>
                    <Workbook.Sheet data={data} name="Procard">
                        <Workbook.Column label="Payrool Number" value={row => row.EmployeeCode}/>
                        <Workbook.Column label="Name" value={row => row.Name}/>
                        <Workbook.Column label="Surname" value={row => row.Surname}/>
                        <Workbook.Column label="Nett Pay" value={row => row.NetPay}/>
                        <Workbook.Column label="ID Number" value={row => row.EmployeeIDNo}/>
                        <Workbook.Column label="Descrption" value={row => row.Company}/>
                    </Workbook.Sheet>
                </Workbook>*/}
            </div>
        )
    }
}


export default ComExcelBook


import React from 'react';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {Button, Modal} from 'react-bootstrap';
import ReactDOM from 'react-dom'

import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';

import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';
import '../asset/styles/setting-contract.css';
import '../asset/styles/stepbystepform.css';

import DatePicker from 'material-ui/DatePicker';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import moment from 'moment';

import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';
import loaderImg from '../asset/ajax_loader.gif';
import CustomInsertModal from '../component/insertEmployeeModal';
import MyComponentModal from "../component/common/modal-bootstrap";

let serverEpwp = eConfig.serverEpwp;

const style = {
    margin: 12,
};


class SettingEmployeeContractPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},
            Employees: [],
            Contracts: [],
            mycontract: "",
            currentEmployee: {},
            employeeEdit: {},
            openclose: false,
            openclosestatus: false,
            opencloseedit: false,
            terminationReason: "",
            terminationDate: moment().toISOString().slice(0, 10),
            hideColumnVariable: {
                Address: false,
                Gender: false,
                Email: false,
                CellphoneNumber: false,
            },
            empStatus: "trial",
            filtereedEmployee: 0,
            pips: 0,
            count: 0,
            searchText: "",

        };


        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchEmployees = this.fetchEmployees.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.renderEmployees = this.renderEmployees.bind(this);
        this.actionViewEmployee = this.actionViewEmployee.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.setViewChange = this.setViewChange.bind(this);
        this.renderNewEmployeeForm = this.renderNewEmployeeForm.bind(this);
        this.cancelAddForm = this.cancelAddForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.addEmployeeSave = this.addEmployeeSave.bind(this);
        this.terminateEmployee = this.terminateEmployee.bind(this);
        this.openModalTerminateEmployee = this.openModalTerminateEmployee.bind(this);
        this.closeModalTerminalEmployee = this.closeModalTerminalEmployee.bind(this);
        this.renderPopupForm = this.renderPopupForm.bind(this);
        this.submitTerminationForm = this.submitTerminationForm.bind(this);
        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);
        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);

        this.popupViewForm = this.popupViewForm.bind(this);
        this.popupEditForm = this.popupEditForm.bind(this);
        this.closeModalEditFrom = this.closeModalEditFrom.bind(this);
        this.renderEditForm = this.renderEditForm.bind(this);
        this.handleInputEdit = this.handleInputEdit.bind(this);
        this.submitEditForm = this.submitEditForm.bind(this);


        this.handleSearchChange = this.handleSearchChange.bind(this);

    }

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
            tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchEmployees();
        this.fetchContracts();
    }

    convertMomentDateToStringISO(dt) {
        return dt.toISOString().slice(0, 10);
    }

    convertStringDateIntoMomentDate(dateString) {
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    handleChangeClockDate(e, eDate, empCode) {
        let key = "terminationDate";
        let val = this.convertMomentDateToStringISO(eDate);
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("handleChangeClockDate >> ", val, " > ", this.state.terminationDate);
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Contracts", _this);
        console.log("--)Contracts(--> ", this.Contracts);
    }

    fetchEmployees() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Employees", _this);

        let emp = [];

        for (let i in this.state.Employees) {
            let row = this.state.Employees[i];
            row.Id = parseInt(row.Id);
            emp.push(row);
        }
        let tmp = this.state;
        tmp.Employees = emp;
        this.setState(tmp);
        console.log("--)(--> ", this.state);

    }

    setViewChange(val) {
        let tmp = this.state;
        tmp.view = val;
        this.setState(tmp);
    }

    openModal() {
        var tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }

    popupViewForm(row) {
        var tmp = this.state;
        tmp.currentEmployee = row;
        tmp.openclose = true;
        this.setState(tmp);
    }

    popupEditForm(row) {
        var tmp = this.state;
        tmp.employeeEdit = row;
        tmp.opencloseedit = true;
        this.setState(tmp);
    }


    openModalTerminateEmployee() {
        var tmp = this.state;
        tmp.openclosestatus = true;
        this.setState(tmp);

    }

    closeModal() {
        var tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }

    closeModalTerminalEmployee() {
        var tmp = this.state;
        tmp.openclosestatus = false;
        this.setState(tmp);
    }


    actionViewEmployee(row) {
        let tmp = this.state;
        tmp.currentEmployee = row;
        this.setState(tmp);
        this.openModal()
        // alert("View company request popup");

    }

    cancelAddForm() {
        let el = window.document.getElementById("fmSubmit");
        el.reset();
        this.setState({view: "list"});
    }

    addEmployeeSave(row) {

        let newRowStr = "";
        for (const prop in row) {
            newRowStr += prop + ': ' + row[prop] + ' \n';
        }
        alert('The new employee is:\n ' + newRowStr);

        let endpoint = "/employee/new";
        let url = serverEpwp + endpoint;
        let hub = row;
        hub.company = this.state.company;
        hub.username = this.state.username;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.status === "SUCCESS") {
                    alert("SUCCESS Adding new employee ");
                    this.fetchEmployees();
                    return
                }
                alert("FAILED to add new employee error: " + responseData.error);
                //this.setState({view: "list"});
            });

    }

    submitForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you sure that you want to submit this form?")
        if (!text) {
            return;
        }


        let endpoint = "/employee/csv";
        let url = serverEpwp + endpoint;
        var inputUpload = document.getElementById("myfile");
        var myfile = inputUpload.files[0];
        if (!myfile) {
            alert("No file selected.");
            return;
        }

        const filename = myfile.name;


        let hub = {};
        hub = this.state;
        hub.FileName = filename;
        hub.Contract = this.state.mycontract;
        hub.company = this.state.company;

        var reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            hub.Base64String = reader.result;

            fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hub)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    alert("SUCCESS Uploaded you will get feedback on your email  ");
                    this.setState({view: "list"});
                });

        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };


        console.log("CALL TO API > ", this.state.feedbackSubmit);
        this.fetchEmployees();
    }

    submitTerminationForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you that you want to terminate this employee contract? ");
        if (!text) {
            return;
        }
        /**
         * Please implement this submission
         */

        /*
        terminationReason: "1--Temporary employees (less that 24 hours per month)"
         */
        let terReason, terCode;
        let terminationReason = this.state.terminationReason;

        if (terminationReason !== "" && typeof terminationReason !== "undefined") {
            terCode = terminationReason.split("--")[0];
            terReason = terminationReason.split("--")[1];
        }

        let endpoint = "/employee/terminate";
        let url = serverEpwp + endpoint;
        let hub = this.state.currentEmployee;
        //hub.company = this.state.company;
        hub.Username = this.state.username;

        hub.TerminationDate = this.state.terminationDate;
        hub.TerminationCode = terCode;
        hub.ReasonForTermination = terReason;
        hub.Status = "termination";


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.STATUS === "OK") {
                    alert("Employee terminated");
                    this.fetchEmployees();
                    return
                }
                alert("FAILED to terminate  employee error: " + responseData.error);
                //this.setState({view: "list"});
            });


        let tmp = this.state;
        tmp.openclosestatus = false;
        this.setState(tmp);
    }

    renderNewEmployeeForm() {
        let _this = this;

        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    Import Employee Csv
                </label>

                <form className={"form"} onSubmit={this.submitForm} id={"fmSubmit"}>
                    {this._render2ColInput("Csv File", "myfile", "file", "", true,
                        "", "", "", "", true, _this)}

                    <div className="form-row">
                        <div className={"form-group col-md-" + 12}>
                            <div className={""}>
                                <label>Contact:</label>
                                <select
                                    className="select"
                                    id={"mycontract"}
                                    name={"mycontract"}
                                    ref={"mycontract"}
                                    onChange={_this._handleInput}
                                    required={true}
                                >
                                    <option value={""}> --Select Contract--</option>
                                    {
                                        this.state.Contracts.map((row) => {
                                            return ( <option value={row.Name}>{row.Name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <button type="submit" className={"btn btn-primary pull-right"}>Submit</button>
                    &nbsp;&nbsp;
                    <button type={"button"} className={"btn btn-dander pull-right"} onClick={() => {
                        this.cancelAddForm()
                    }}>Cancel
                    </button>
                </form>


            </div>
        )


    }

    renderEmployees() {
        if (this.state.view !== "list") {
            return "";
        }
        return (
            <div className={"row"}>
                <label className="alert alert-primary companyList">
                    Available Employees List (<span className="badge"> {this.state.Employees.length}</span>)
                </label>

                <table className={"table"} style={{width: "100%"}}>
                    <thead>
                    <tr>
                        <th>Code</th>
                        <th>Fullname</th>
                        <th>IDNo</th>
                        <th>Site</th>
                        <th>Gender</th>
                        <th>Designation</th>
                        <th>Created</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>

                    {this.state.Employees.map((row) => {
                        return (
                            <tr key={row.Id}>
                                <td>{row.EmployeeCode}</td>
                                <td>{row.Name} {row.Surname}</td>
                                <td>{row.EmployeeIDNo}</td>
                                <td>{row.Site}</td>
                                <td>{row.Gender}</td>
                                <td>{row.Designation}</td>
                                <td>{row.OrgDateTime}</td>
                                <td>
                                    <button className={"clickMe btn btn-primary"} onClick={() => {
                                        this.actionViewEmployee(row)
                                    }}>View
                                    </button>
                                    &nbsp;
                                </td>
                                <td>
                                    <button className={"clickMe btn btn-danger"} onClick={() => {
                                        alert("Feature not implemented")
                                    }}>Delete
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    goToDocs=(row)=>{
        window.localStorage.setItem("@RECORD-EMPLOYEE", JSON.stringify(row));
        window.location.href = "#/record";
    }

    goToProfile(row) {
        /*let text = window.confirm("Are you sure that you want to manage this employee profile code: " + row.EmployeeCode + " ?");
        if (!text) {
            return;
        }*/
        window.localStorage.setItem("PROFILE-EMPLOYEE", JSON.stringify(row));
        window.location.href = "#/profile";
    }

    goToEdit(row){
        alert("Edit employee will be emplement here");
    }

    createCustomModal(onModalClose, onSave, columns, validateState, ignoreEditable) {
        const attr = {
            onModalClose, onSave, columns, validateState, ignoreEditable
        };
        return (
            <CustomInsertModal {...attr} />
        );
    }

    popupTerminateForm(row) {
        let tmp = this.state;
        tmp.currentEmployee = row;
        this.setState(tmp);
        this.openModalTerminateEmployee();
        console.log("PopUp Terminateion > ", row);
    }

    terminateEmployee(cell, row) {

        return (
            <div>
                <button type="button" className={"btn btn-default"} onClick={() => {
                    this.popupViewForm(row)
                }}>View
                </button>
                &nbsp;
                <button type="button" className={"btn btn-default"} onClick={() => {
                    this.popupEditForm(row)
                }}>Edit
                </button>
                &nbsp;
                <button type="button" className={"btn btn-default"} onClick={() => {
                    this.popupTerminateForm(row)
                }}>Termination
                </button>
                &nbsp;
                <button type="button" className={"btn btn-default"} onClick={() => {
                    this.goToProfile(row)
                }}>Profile
                </button>

                &nbsp;
                <button type="button" className={"btn btn-default"} onClick={() => {
                    this.goToDocs(row)
                }}>Docs
                </button>
            </div>
        );
        //alert("Choose to Terminate employee: "+row.EmployeeCode);
    }

    closeModalEditFrom() {
        var tmp = this.state;
        tmp.opencloseedit = false;
        this.setState(tmp);
    }

    submitEditForm(e) {
        e.preventDefault();
        let text = window.confirm("Are you that you want to edit this employee? ");
        if (!text) {
            return;
        }
        /** delete this following line before production **/
            //serverEpwp = "http://localhost:33345";

        let endpoint = "/employee/edit";
        let url = serverEpwp + endpoint;
        let hub = this.state.employeeEdit;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                if (responseData.STATUS === "OK") {
                    alert("Employee edited");
                    this.closeModalEditFrom();
                    this.fetchEmployees();
                    return
                }
                alert("FAILED to edit  employee error: " + responseData.error);
                //this.setState({view: "list"});
            });


    }

    handleInputEdit(e, key) {
        var tmp = this.state;
        tmp.employeeEdit[key] = e.target.value;
        this.setState(tmp);
        console.log("EDIT HANDLE > ", key, e.target.value);
    }

    renderEditForm() {
        let avoidedKeys = {
            Id: true,
            Status: true,
            Date: true,
            Time: true,
            OrgDateTime: true
        };
        let primaryKeys = {
            Company: true,
            EmployeeCode: true,
            Contract: true,
            Name: true,
            Surname: true,
            DailyRate: true,
            TerminationDate: true,
            TerminationCode: true,
            ReasonForTermination: true,
            Site: true,
        };

        let ls = [];
        for (let i in this.state.employeeEdit) {
            const item = this.state.employeeEdit[i];
            let o = {};
            o.key = i;
            o.val = item;
            o.editable = true;
            if (typeof primaryKeys[o.key] !== "undefined") {
                o.editable = false;
            }

            if (typeof avoidedKeys[o.key] === "undefined") {
                ls.push(o);
            }

        }


        return (
            <div className="container ">
                <Modal show={this.state.opencloseedit} onHide={this.closeModalEditFrom} className="">
                    <Modal.Header closeButton>
                        <Modal.Title>Manage Termination Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "80%", overflow: "scroll"}}>

                        <div className="row">
                            <div className="col-lg-12">

                                <h5>EmployeeCode: {this.state.employeeEdit.EmployeeCode}</h5>
                                <h5>EmployeeIDNo: {this.state.employeeEdit.EmployeeIDNo}</h5>
                                <h5>Site: {this.state.employeeEdit.Site}</h5>
                                <h5>
                                    Fullname: {this.state.employeeEdit.Name + "  " + this.state.employeeEdit.Surname}</h5>

                                <form onSubmit={this.submitEditForm}>
                                    <table className="table">
                                        {ls.map((row) => {
                                            return (
                                                <tr key={row.key}>
                                                    <th style={{textAlign: "right"}}>
                                                        {row.key}: &nbsp;
                                                    </th>

                                                    {!row.editable && (
                                                        <td style={{textAlign: "left"}}>{row.val}</td>
                                                    )}
                                                    {row.editable && (
                                                        <td style={{textAlign: "left"}}>
                                                            <input
                                                                name={row.key}
                                                                className="form-control"
                                                                onChange={(e) => {
                                                                    this.handleInputEdit(e, row.key)
                                                                }}
                                                                defaultValue={this.state.employeeEdit[row.key]}

                                                            />
                                                        </td>
                                                    )}

                                                </tr>
                                            )
                                        })}

                                        {/*<select className="select" name="terminationReason" onChange={this._handleInput}
                                                required={true}>
                                            {TerminationCodes.map((row) => {
                                                return (
                                                    <option value={row.val}>{row.desc}</option>
                                                )
                                            })}
                                        </select>*/}
                                    </table>


                                    <button type="submit" className="btn btn-success">Submit</button>

                                </form>


                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModalEditFrom}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    renderPopupForm() {
        return (
            <div className="container ">
                <Modal show={this.state.openclosestatus} onHide={this.closeModalTerminalEmployee} className="">
                    <Modal.Header closeButton>
                        <Modal.Title>Manage Termination Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{height: "80%", overflow: "scroll"}}>

                        <div className="row">
                            <div className="col-lg-12">

                                <h5>EmployeeCode: {this.state.currentEmployee.EmployeeCode}</h5>
                                <h5>EmployeeIDNo: {this.state.currentEmployee.EmployeeIDNo}</h5>
                                <h5>Site: {this.state.currentEmployee.Site}</h5>
                                <h5>
                                    Fullname: {this.state.currentEmployee.Name + "  " + this.state.currentEmployee.Surname}</h5>

                                <form onSubmit={this.submitTerminationForm}>
                                    <div className="form-group">
                                        <select className="select" name="terminationReason" onChange={this._handleInput}
                                                required={true}>
                                            {TerminationCodes.map((row) => {
                                                return (
                                                    <option value={row.val}>{row.desc}</option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <MuiThemeProvider>
                                            <DatePicker
                                                hintText="--Select--"
                                                floatingLabelText="Choose Terminate Date"
                                                onChange={this.handleChangeClockDate}
                                            />
                                        </MuiThemeProvider>
                                        <br/>
                                        <br/>
                                    </div>

                                    <button type="submit" className="btn btn-success">Submit</button>

                                </form>


                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeModalTerminalEmployee}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }

    handleSearchChange = (searchText, result) => {
        /* //... your stuff on here
         console.log("handleSearchChange --> ",searchText, result);
         let tmp = this.state;
         tmp.filtereedEmployee = result.length;
         this.setState(tmp);*/

        var count = result.length;
        var tmpThis = result.pips;
        var pips = 0;
        for (var i = 0; i < result.length; i++) {
            pips = pips + result[i].Pips;
        }
        if (this.state.searchText !== searchText) { // <- this stops the infinite loop
            this.setState({
                pips: pips,
                count: count,
                searchText: searchText
            });
        }

        console.log("handleSearchChange --> ", searchText, result);

    }


    render() {

        let ls = [];
        for (var i in this.state.Employees) {
            let row = this.state.Employees[i];
            if (row.Status === this.state.empStatus) {
                ls.push(row)
            }
        }

        let data = ls;

        const selectRowProp = {
            mode: 'radio',
            clickToSelect: false  // enable click to select
        };

        let _this = this;

        const options = {
            defaultSortName: 'Id',
            defaultSortOrder: 'asc',
            paginationSize: 20,
            actionViewEmployee: this.actionViewEmployee,
            addEmployee: this.addEmployeeSave,
            /*onRowClick: function (row, columnIndex, rowIndex, e) {
                console.log(`You click row id: ${row.EmployeeCode}, column index: ${columnIndex}, row index: ${rowIndex}`);
                this.actionViewEmployee(row);
                console.log(e);
            },*/
            onRowDoubleClick: function (row, e) {
                this.actionViewEmployee(row);
                console.log(e);
            },
            clearSearch: true,
            searchField: (props) => (<MySearchField {...props}/>),
            onAddRow: this.addEmployeeSave,
            insertModal: this.createCustomModal,
            searchDelayTime: 1500,
            afterSearch: (e, ee) => {
                this.handleSearchChange(e, ee)
            },
            that: _this,
        };

        let myStyle = {
            overflow: 'visible',
            width: '100%'
        };

        myStyle.color = "red";

        let styleEmpStatus = {};
        let myClassGray = "";
        if (this.state.empStatus !== "trial") {
            styleEmpStatus.color = "#505050";
            myClassGray = "myClassGray";
        }

        let Statuses={trial:"Active Employee",termination:"Termination Employee"};

        return (
            <div className="">
                <TopMenu page="Employee Contract setup" menu={this.props.location.pathname}/>

                <MuiThemeProvider>
                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>
                                <div className="centerContent ">
                                    <form className="">
                                        <div className="form-group">
                                            <label>Database Query</label>
                                            <select
                                                className="select"
                                                name="empStatus"
                                                onChange={this._handleInput}
                                                defaultValue={this.state.statusView}
                                                style={{width:"50%"}}
                                            >
                                                <option value="trial">Active Employee</option>
                                                <option value="termination">Termination Employee</option>

                                            </select>
                                            <span className="pull-right totDatabaseQuery"> <b>{data.length}</b></span>
                                        </div>
                                    </form>
                                    <hr/>

                                    <p><span
                                        className="pull-right">Key search: <b>{this.state.searchText}</b> &nbsp;&nbsp;
                                        Count: <b>{this.state.count}</b></span></p>

                                    <BootstrapTable
                                        id="tbEmployee"
                                        style={myStyle}
                                        data={data}
                                        striped={false}
                                        hover={true}
                                        pagination
                                        options={options}
                                        insertRow deleteRow exportCSV
                                        search
                                        hidden={this.state.hideColumnVariable}
                                        className={myClassGray}

                                    >
                                        <TableHeaderColumn dataField="button" width='380px'
                                                           dataFormat={this.terminateEmployee}>#</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Id" isKey={true} dataSort width='300px'
                                                           style={styleEmpStatus}
                                                           sortFunc={numericSortFunc} hidden={true}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="EmployeeCode" width='70px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Code</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Name" width='300px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Name</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Surname" width='300px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Surname</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Designation" width='300px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Designation</TableHeaderColumn>
                                        <TableHeaderColumn dataField="DailyRate" width='200px' style={styleEmpStatus}
                                                           filter={{type: 'TextFilter', delay: 1000}}
                                                           hidden={false}>Daily
                                            Rate</TableHeaderColumn>
                                        <TableHeaderColumn dataField="EmployeeIDNo" width='150px' style={styleEmpStatus}
                                                           filter={{type: 'TextFilter', delay: 1000}}>Employee
                                            ID No</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Site" width='200px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Site</TableHeaderColumn>
                                        <TableHeaderColumn hidden={false} dataField="Address" width='200px'
                                                           style={styleEmpStatus} filter={{
                                            type: 'TextFilter',
                                            delay: 1000
                                        }}>Address</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Gender" width='10px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Gender</TableHeaderColumn>
                                        <TableHeaderColumn dataField="Bank" width='200px' style={styleEmpStatus}
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }} hidden={false}>Bank</TableHeaderColumn>
                                        <TableHeaderColumn dataField="AccountNumber" width='200px'
                                                           style={styleEmpStatus}
                                                           filter={{type: 'TextFilter', delay: 1000}}
                                                           hidden={false}>Account
                                            Number</TableHeaderColumn>
                                        <TableHeaderColumn dataField="BranchCode" width='200px' style={styleEmpStatus}
                                                           filter={{type: 'TextFilter', delay: 1000}}
                                                           hidden={false}>Branch
                                            Code</TableHeaderColumn>
                                        <TableHeaderColumn dataField="PaymentMethod" width='200px'
                                                           style={styleEmpStatus}
                                                           filter={{type: 'TextFilter', delay: 1000}}
                                                           hidden={false}>Payment
                                            Method</TableHeaderColumn>
                                        <TableHeaderColumn hidden={false} dataField="CommencementDate" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>CommencementDate</TableHeaderColumn>
                                        <TableHeaderColumn dataField="CellphoneNumber" width='100px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Cellphone Number</TableHeaderColumn>

                                        <TableHeaderColumn hidden={false} dataField="Email" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Email</TableHeaderColumn>

                                        <TableHeaderColumn hidden={false} dataField="Contract" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Contract</TableHeaderColumn>

                                        <TableHeaderColumn hidden={false} dataField="TerminationDate" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Termination Date</TableHeaderColumn>
                                        <TableHeaderColumn hidden={false} dataField="TerminationCode" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Termination Code</TableHeaderColumn>
                                        <TableHeaderColumn hidden={false} dataField="ReasonForTermination" width='200px'
                                                           filter={{
                                                               type: 'TextFilter',
                                                               delay: 1000
                                                           }}>Reason For Termination</TableHeaderColumn>

                                    </BootstrapTable>

                                    {this.renderPopupForm()}

                                    {this.renderEditForm()}

                                    <MyComponentModal
                                        obectname={"Employee Detail [" + this.state.currentEmployee.Name + "]"}
                                        openclose={this.state.openclose}
                                        close={this.closeModal}
                                        open={this.openModal}
                                        data={this.state.currentEmployee}
                                    />


                                </div>


                            </Paper>
                        </div>

                    </div>
                </MuiThemeProvider>


            </div>
        )
    }

}


export default SettingEmployeeContractPage;

function getNumFilteredRows(id) {
    let $ = window.$;
    var info = $(id).DataTable().page.info();
    return info.recordsDisplay;
}


let TerminationCodes = [
    {val: "", desc: "--- Select Reason --"},
    {
        val: "13--Absconded",
        desc: "Absconded"
    },
    {
        val: "14--Business Closed",
        desc: "Business Closed"
    },
    {
        val: "7--Constructive Dismissal",
        desc: "Constructive Dismissal"
    },
    {
        val: "5--Contract Expired",
        desc: "Contract Expired"
    },
    {val: "15--Death of Domestic Employer", desc: "Death of Domestic Employer"},
    {val: "2--Deceased", desc: "Deceased"},
    {
        val: "4--Dismissed",
        desc: "Dismissed"
    },
    {
        val: "10--Illness/Medically Boarded",
        desc: "Illness/Medically Boarded"
    },
    {val: "8--Insolvency/Liquidation", desc: "Insolvency/Liquidation"},
    {val: "9--Maternity/Adoption Leave", desc: "Maternity/Adoption Leave"},

    {val: "6--Resigned", desc: "Resigned"},

    {val: "3--Retired", desc: "Retired"},

    {val: "11--Retrenched/Staff Reduction", desc: "Retrenched/Staff Reduction"},

    {val: "12--Transfer to another Branch", desc: "Transfer to another Branch"},

    {val: "16--Voluntary Severance Package", desc: "Voluntary Severance Package"},
];

class MySearchField extends React.Component {
    // It's necessary to implement getValue
    getValue() {
        return ReactDOM.findDOMNode(this).value;
    }

    // It's necessary to implement setValue
    setValue(value) {
        ReactDOM.findDOMNode(this).value = value;
    }

    render() {
        return (
            <input
                className={`form-control`}
                type='text'
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onKeyUp={this.props.search}/>
        );
    }
}

function numericSortFunc(a, b, order) {
    if (order === 'desc') {
        return Number(b.Id) - Number(a.Id);
    } else {
        return Number(a.Id) - Number(b.Id);
    }
}


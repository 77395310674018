import React from "react";
import {PostPims, PostPimsRecord} from "../../api/post_pmis";
import Button from "@material-ui/core/Button/Button";
import Divider from "material-ui/Divider";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

const style = {
    margin: 12,
};


export default class DocsPlaylisp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            company: "",
            organization: "",
            ref: "",
            docCategory: "",
            Employee: null,
            downloadLink: "",
            showRequestForm: false,
            Year: "",
            Month: "",
        }

        this.submitObject = this.submitObject.bind(this);
    }

    componentDidMount() {
        const row = JSON.parse(localStorage.getItem("@RECORD-EMPLOYEE"));
        let tmp = this.state;
        tmp.company = row.Company;
        tmp.organization = row.Company;
        tmp.ref = row.EmployeeCode;
        tmp.Employee = row;
        this.setState(tmp);
    }

    async submitObject() {

        this.setState({downloadLink: ""});
        const hub = {
            Company: this.state.company,
            Year: this.state.Year,
            Month: this.state.Month,
            EmployeeCode: this.state.ref,
        };
        let _this = this;

        console.log("send payslip hub---->>> ",hub,this.state);

        const endpoint = "/api/services/payslip/generate-single";
        await PostPims(hub, endpoint, function (data) {
            console.log("submitObject response > ", data);
            _this.setState({downloadLink: data.download});
            if(data.download ===""){
                alert("No datq find! Empty payslip for the target Period")
            }
        })
    }

    openDocNewWindown = () => {
        console.log("Opening link pdf link request > ", this.state.downloadLink);
        document.title = this.state.Ref + " " + this.state.Employee.Name;
        window.open(this.state.downloadLink, '_blank');
    }


    renderDownloadButton = () => {

        if (this.state.downloadLink === "") {
            return (
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled
                    className={styles.button}

                >
                    DOWNLOAD
                </Button>
            )
        }

        return (
            <Button
                variant="outlined"
                color="secondary"
                className={styles.button}
                onClick={() => this.openDocNewWindown()}
            >
                DOWNLOAD
            </Button>
        )
    }

    handleInputChange(e,index,val,key){
        this.handleChange(key, val)

        console.log("handleInputChange > ",key," > ",val," > ",e)
    }
    handleChange(key, val) {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("-->handleChange > ", key, " > ", val);
    }


    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10">
                        <p>Geneate Payslip</p>
                    </div>
                </div>



                <div className="row">
                    <div className="col col-lg-6">

                        <Divider/>
                        <form onSubmit={this.submitObject}>

                            <SelectField
                                floatingLabelText="Year"
                                name="selectedYear"
                                onChange={(e,index,value)=>this.handleInputChange(e, index, value,"Year")}
                                value={this.state.Year}
                                style={{width:"25%"}}
                                required={true}
                            >
                                <MenuItem value={"2018"} primaryText={"2018"}/>

                            </SelectField>
                            &nbsp; &nbsp; &nbsp; &nbsp;

                            <SelectField
                                floatingLabelText="Month"
                                name="selectedMonth"
                                onChange={(e,index,value)=>this.handleInputChange(e, index, value,"Month")}
                                value={this.state.Month}
                                style={{width:"25%"}}
                                required={true}
                            >
                                <MenuItem value={"01"} primaryText={"Jan"}/>
                                <MenuItem value={"02"} primaryText={"Feb"}/>
                                <MenuItem value={"03"} primaryText={"Marc"}/>
                                <MenuItem value={"04"} primaryText={"Apr"}/>
                                <MenuItem value={"05"} primaryText={"May"}/>
                                <MenuItem value={"06"} primaryText={"Jun"}/>
                                <MenuItem value={"07"} primaryText={"Jul"}/>
                                <MenuItem value={"08"} primaryText={"Aug"}/>
                                <MenuItem value={"09"} primaryText={"Sep"}/>
                                <MenuItem value={"10"} primaryText={"Oct"}/>
                                <MenuItem value={"11"} primaryText={"Nov"}/>
                                <MenuItem value={"12"} primaryText={"Dec"}/>
                            </SelectField>
                            <br/>



                            <RaisedButton
                                label="Submit"
                                primary={true}
                                style={style}
                                type={"submit"}
                                onClick={() => {
                                    this.submitObject();
                                }}
                            />

                            &nbsp;
                            {this.renderDownloadButton()}
                        </form>

                    </div>
                </div>
            </div>

        )
    }


}


const styles = {
    button: {},
    tbSource: {
        textAlign: "right"
    },
    input: {
        width: "100%",
    },
    tbDescription: {
        width: "5%",
        textAlign: "center"
    },

}
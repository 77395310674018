import React from 'react';
import TopMenu from "../component/common/top-nav-new";
import SideMenu from "../component/common/side-menu-nav";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import Paper from "material-ui/Paper";
import {Tab, Tabs} from "material-ui/Tabs";
import DocsGenerateComponent from '../component/record-compliance/docs-generate';
import DocsPayslipComponent from '../component/record-compliance/docs-payslip';


export default class RecordDocs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            company: "",
            employeeCode: "",
            Employee: null,
            TemplateList:[
                {TemplateName:"contract signed",Description:"Contract Signed",TemplateType:"generate"},
                {TemplateName:"Copy of Payslips",Description:"Copy of Payslips",TemplateType:"generate"}
            ],
            Edocs:{},
            showEdocRequestForm:false,
            SignedDocList :[],

        }

        this.fetchSignedDocs  = this.fetchSignedDocs.bind(this);
    }

    async componentDidMount(){
        const row  = JSON.parse( localStorage.getItem("@RECORD-EMPLOYEE"))
        this.setState({Employee: row,company:row.Company,employeeCode:row.EmployeeNumber});
        this.fetchSignedDocs()
    }
    componentWillUnmount(){
       // localStorage.removeItem("@RECORD-EMPLOYEE");
    }

   async fetchSignedDocs(){

    }

    renderTabsDocs=()=>{

        return this.state.TemplateList.map((row,index)=>{
            if(row.TemplateName ==="Copy of Payslips"){
                return(
                    <Tab key={index} label={(index+1)+". "+row.Description}>
                        <DocsPayslipComponent  TemplateName={row.TemplateName} />
                    </Tab>
                )
            }
            return(
                <Tab key={index} label={(index+1)+". "+row.Description}>
                    <DocsGenerateComponent  TemplateName={row.TemplateName} />
                </Tab>
            )
        })
    }
    getEmployeeNames=()=>{
        const emp = this.state.Employee;
        if(this.state.Employee ===null){
            return "---/---"
        }

        return(
            <small>
                {emp.EmployeeIDNo} / {emp.Name} / {emp.Surname} / {emp.Designation} / {emp.Site}
            </small>
        )
    }
    render() {
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="CLocking" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>


                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>



                                    <h3>Record compliance Generate</h3>

                                <p>{this.getEmployeeNames()}</p>
                                    <div className="centerContent">

                                        <Tabs>
                                            {this.renderTabsDocs()}

                                        </Tabs>
                                    </div>

                            </Paper>
                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
}


const style = {
    margin: 12,
};
import React from 'react';
import TopMenu from '../component/common/top-nav-new';
import '../asset/styles/global-main.css';

import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

const serverEpwp = eConfig.serverEpwp;

class DemoPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            LoginUser: {},
            company: "",
            username: "",
            role: "",
            NewUsers: [],
            Organisation: {},
            logoData: "",
        };

        this.fetchOrganisation = this.fetchOrganisation.bind(this);
        this.changeLogoClick = this.changeLogoClick.bind(this);
        this.onChangeLogo = this.onChangeLogo.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.submitOrganisation = this.submitOrganisation.bind(this);


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchOrganisation();
    }

    fetchOrganisation() {
        let company = this.state.company;
        let endpoint = "/api/organisation/find/owner";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Organisation = responseData.DATA;
                tmp.logoData = tmp.Organisation.Logo;
                this.setState(tmp);
                console.log("+++++>fetchOrganisation response ", responseData);
            });

    }

    changeLogoClick() {
        let el = window.document.getElementById("btnHiddenFile");
        el.click();
    }

    onChangeLogo(e) {

        e.preventDefault();
        let _this = this;
        var inputUpload = document.getElementById("btnHiddenFile");
        var myfile = inputUpload.files[0];
        if (!myfile) {
            alert("No file selected.");
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.logoData = reader.result;
            tmp.Organisation.Logo = reader.result;
            _this.setState(tmp);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

    }

    submitOrganisation(e){
        e.preventDefault();

        let text  = window.confirm("Are you sure that you want to submit this form?");
        if(!text){
            return
        }

        let company = this.state.company;
        let endpoint = "/api/organisation/new";
        let url = serverEpwp + endpoint;

        let hub = this.state.Organisation;
        hub.OwnerId = this.state.username;
        hub.OwnerFullname =this.state.LoginUser.Name+" "+this.state.LoginUser.Surname;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Thank you record "+responseData.STATUS);
                console.log("+++++>submitOrganisation response ", responseData);
                this.fetchOrganisation();
            });


    }

    handleInput(e) {
        let key = e.target.name;
        let val = e.target.value;
        if(key ==="PhysicalSameAsPostal"){
            if(e.target.checked){
                val ="yes";
            }else{
                val ="no";
            }
        }
        let tmp = this.state;
        tmp.Organisation[key] = val;
        this.setState(tmp);
    }

    render() {

        const org = this.state.Organisation;
        let myCheck = false;
        if(this.state.Organisation.PhysicalSameAsPostal ==="yes"){
            myCheck = true
        }else{
            myCheck = false;
        }

        return (
            <div className="">
                <TopMenu page="Organisation" menu={this.props.location.pathname} organisation={this.state.Organisation.OrganisationDescription}/>

                <div className="pageContentWhite">
                    <div className="container borderShadon">
                        <h3>Basic Information</h3>
                        <form onSubmit={this.submitOrganisation}>
                            <table className="table">
                                <tr>
                                    <td className="cOrg12">Display name</td>
                                    <td>
                                        <input name="DisplayName" onChange={this.handleInput} value={org.DisplayName}
                                               className="form-control" placeholder="Display name..."/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="cOrg12">Legal / Trading name</td>
                                    <td className="">
                                        <input name="LegalTradingName" onChange={this.handleInput}
                                               value={org.LegalTradingName} className="form-control"
                                               placeholder="Legal Trading name..."/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12">Logo</td>
                                    <td className="">
                                        <div className="row">
                                            <div className="col-lg-2 logoColoum img-rounded">
                                                <img src={this.state.logoData} alt={"Logo " + this.state.company}
                                                     width={110} height={80} className="img-rounded myImgBackground"/>
                                            </div>
                                            <div className="col-lg-2 ">
                                                <button className="btn btn-default" onClick={() => {
                                                    this.changeLogoClick()
                                                }}>Change
                                                </button>
                                                &nbsp;<br/>
                                                <br/>
                                                <button className="btn btn-default">Remove</button>
                                                <br/>
                                                <input type="file" id="btnHiddenFile" className="btn btn-default hidden"
                                                       onChange={this.onChangeLogo}/>&nbsp;<br/>
                                            </div>
                                        </div>

                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12">What is your line Business</td>
                                    <td className="">
                                        <input name="LineOfBusiness" onChange={this.handleInput}
                                               value={org.LineOfBusiness} className="form-control"
                                               placeholder="Line Business..."/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12">Organisation Type</td>
                                    <td className="">
                                        <select className="form-control" name="OrganisationType"
                                                onChange={this.handleInput} selected={org.OrganisationType}>
                                            <option value="company">Company</option>
                                            <option value="ong">ONG</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12">Business Registration Number</td>
                                    <td className="">
                                        <input name="BusinessRegistration" onChange={this.handleInput}
                                               value={org.BusinessRegistration} className="form-control"
                                               placeholder="Business Registration Number..."/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12">Organisation Description</td>
                                    <td className="">
                                        <textarea name="OrganisationDescription" onChange={this.handleInput}
                                                  value={org.OrganisationDescription} className="form-control"
                                                  placeholder="Organisation Description..."/>
                                    </td>
                                </tr>

                            </table>

                            <hr/>

                            <h3>Contact Detail</h3>

                            <table className="table">
                                <tr>
                                    <td className="cOrg12"><b>Postal address</b></td>
                                    <td className="">
                                        <textarea name="PostalStreetAddress" onChange={this.handleInput}
                                                  value={org.PostalStreetAddress} className="form-control"
                                                  placeholder="Postal address..."/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PostalCity" onChange={this.handleInput} value={org.PostalCity}
                                               className="form-control" placeholder="Town / City"/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PostalState" onChange={this.handleInput} value={org.PostalState}
                                               className="form-control" placeholder="State / Region"/>
                                        <input name="PostalPostalCode" onChange={this.handleInput}
                                               value={org.PostalPostalCode} className="form-control"
                                               placeholder="Postal Code"/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <select name="PostalCountry" onChange={this.handleInput}
                                                selected={org.PostalCountry} className="form-control">
                                            <option value="">Country</option>
                                            <option value="south africa">South Africa</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PostalAttention" onChange={this.handleInput}
                                               value={org.PostalAttention} className="form-control"
                                               placeholder="Attention"/>
                                    </td>
                                </tr>


                                <tr>
                                    <td className="cOrg12"><b>Physical address</b></td>
                                    <td className="">
                                        <input type="checkbox" name="PhysicalSameAsPostal" onChange={this.handleInput} checked={myCheck}
                                                className=""/>&nbsp;<b>Same as postal address</b>
                                    </td>
                                </tr>
                                <tr className="noborder">
                                    <td className="cOrg12">&nbsp;</td>
                                    <td className="">
                                        <textarea name="PhysicalStreetAddress" onChange={this.handleInput}
                                                  value={org.PhysicalStreetAddress} className="form-control"
                                                  placeholder="Physical address..."/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PhysicalCity" onChange={this.handleInput} value={org.PhysicalCity}
                                               className="form-control" placeholder="Town / City"/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PhysicalState" onChange={this.handleInput}
                                               selected={org.PhysicalState} className="form-control"
                                               placeholder="State / Region"/>
                                        <input name="PhysicalPostalCode" onChange={this.handleInput}
                                               value={org.PhysicalPostalCode} className="form-control"
                                               placeholder="Postal Code"/>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <select className="form-control" name="PhysicalCountry"
                                                onChange={this.handleInput} selected={org.PhysicalCountry}>
                                            <option value="">Country</option>
                                            <option value="south africa">South Africa</option>
                                        </select>
                                    </td>
                                </tr>

                                <tr className="cOrg12 noborder">
                                    <td className="cOrg12 ">&nbsp;</td>
                                    <td className="">
                                        <input name="PhysicalAttention" onChange={this.handleInput}
                                               value={org.PhysicalAttention} className="form-control"
                                               placeholder="Attention"/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12"><b>Telephone</b></td>
                                    <td className="">
                                        <input name="Telephone" onChange={this.handleInput} value={org.Telephone}
                                               className="form-control" placeholder="Telephone..."/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12"><b>Email</b></td>
                                    <td className="">
                                        <input name="Email" onChange={this.handleInput} value={org.Email}
                                               className="form-control" placeholder="Email..."/>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="cOrg12"><b>Website</b></td>
                                    <td className="">
                                        <input name="Website" onChange={this.handleInput} value={org.Website}
                                               className="form-control" placeholder="Website..."/>
                                    </td>
                                </tr>

                            </table>

                            <hr/>

                            <p className="mybuttonOrg">

                                <button type="submit" className="btn btn-success">Save</button>
                                &nbsp;&nbsp;
                                <button type="button" className="btn btn-default">Cancel</button>
                            </p>

                        </form>


                    </div>

                </div>
            </div>

        )
    }
}


export default DemoPage;

import React from 'react';

export  let handleInput=(e,_this) =>{
    let key = e.target.name;
    let val = e.target.value;
    let tmp = _this.state;
    tmp[key] = val;
    _this.setState(tmp);
    /*console.log("handleInput > ", key, val)*/
}

export let renderCheckbox=(label, inputName, inputType, requered,_this) =>{
    return (
        <div className={""}>
            <label>&nbsp;</label>
            <div className={"form-check"}>
                <input
                    type={inputType}
                    className="form-check-input"
                    id={inputName}
                    name={inputName}
                    ref={inputName}
                    onChange={_this._handleInput}
                    required={requered}
                />
                <label className={"form-check-label"}>{label}:</label>
            </div>
        </div>

    )
}

export let renderInput=(label, inputName, inputType, requered,_this) =>{
    return (
        <div className={""}>
            <label>{label}:</label>
            <input
                type={inputType}
                className="form-control"
                id={inputName}
                name={inputName}
                ref={inputName}
                placeholder={label}
                onChange={_this._handleInput}
                required={requered}
            />
        </div>
    )
}

export let renderTextArea=(label, inputName, inputType, requered,_this)=> {
    return (
        <div className={""}>
            <label>{label}:</label>
            <textarea
                className="form-control"
                id={inputName}
                name={inputName}
                ref={inputName}
                placeholder={label}
                onChange={_this._handleInput}
                required={requered}
            />
        </div>
    )
}

export let renderSelect=(label, inputName, options, requered,_this) =>{
    return (
        <div className={""}>
            <label>{label}:</label>
            <select
                className="form-control"
                id={inputName}
                name={inputName}
                ref={inputName}
                onChange={_this._handleInput}
                required={requered}
            >
                <option value={""}> --Select {label}--</option>
                {
                    options.map((row) => {
                        return ( <option value={row.value}>{row.label}</option>)
                    })
                }
            </select>
        </div>
    )
}

export let render2ColInput=(label1, inputName1, inputType1, val1, requered1, label2, inputName2, inputType2, val2, requered2,_this)=> {
    let lengCol = "6";
    if (inputName2 === "") {
        lengCol = "12";
    }

    //console.log("inputType1===> ",inputType1);
    
    return (
        <div className="form-row">
            <div className={"form-group col-md-" + lengCol}>
                {(inputType1 === "text") && (
                    _this._renderInput(label1, inputName1, inputType1, requered1,_this)
                )}
                {(inputType1 === "email") && (
                    _this._renderInput(label1, inputName1, inputType1, requered1,_this)
                )}
                {(inputType1 === "password") && (
                    _this._renderInput(label1, inputName1, inputType1, requered1,_this)
                )}
                {(inputType1 === "file") && (
                    _this._renderInput(label1, inputName1, inputType1, requered1,_this)
                )}
                {(inputType1 === "checkbox") && (
                    _this._renderCheckbox(label1, inputName1, inputType1, requered1,_this)
                )}
                {(inputType1 === "select") && (
                    _this._renderSelect(label1, inputName1, val1, requered1,_this)
                )}
                {(inputType1 === "textarea") && (
                    _this._renderTextArea(label1, inputName1, val1, requered1,_this)
                )}

            </div>
            {inputName2 !== "" && (
                <div className={"form-group col-md-6"}>
                    {(inputType2 === "text") && (
                        _this._renderInput(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "email") && (
                        _this._renderInput(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "password") && (
                        _this._renderInput(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "file") && (
                        _this._renderInput(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "textaera") && (
                        _this._renderTextArea(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "checkbox") && (
                        _this._renderCheckbox(label2, inputName2, inputType2, requered2,_this)
                    )}
                    {(inputType2 === "select") && (
                        _this._renderSelect(label2, inputName2, val2, requered2,_this)
                    )}

                </div>
            )}

        </div>
    )
}

export let loadList=(url,hub,tmpKey,_this) =>{


    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((responseData) => {
            var tmp = _this.state;
            tmp[tmpKey] = responseData;
            _this.setState(tmp);
            console.log("+++++> global loadList response ",  responseData);
        });
}

// handleInput, renderCheckbox, renderInput, renderTextArea, renderSelect, render2ColInput, loadList


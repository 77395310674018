import React from "react";
import axios from 'axios';
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import TopMenu from "../component/common/top-nav-new";
import SideMenu from "../component/common/side-menu-nav";
import Paper from "material-ui/Paper";

import html2canvas from 'html2canvas';
import  jsPDF from 'jspdf';

const apiURL = "http://localhost:33345/";
export default class PayslipTest extends React.Component {

    constructor(props){
        super(props);
        this.ifr = null;
    }

    anotherOne=()=>{

        var iframe = document.createElement('iframe');
        iframe.setAttribute('style','position:absolute;right:0; top:0; bottom:0; height:1025px; width:650px; padding:20px;');
        iframe.src = "http://localhost:33345/static";
        document.body.appendChild(iframe);

        document.querySelector('#myframe').addEventListener("load", ev => {
            // your stuff
        });

        let doc, bod, htm, C, E, T; // for use on other loads
        /*addEventListener('load', function(){ // load start

            // I threw in a few goodies to study - it will help you later
            doc = document; bod = doc.body; htm = doc.documentElement;
            C = function(tag){
                return doc.createElement(tag);
            }
            E = function(id){
                return doc.getElementById(id);
            }
            T = function(tag){ // returns an Array of Elements by tag name
                return doc.getElementsByTagName(tag);
            }
            // notice that `window` is implicit, so you don't actually need to use it to access its properties
            setTimeout(function(){ // unexecuted functions and Anonymous functions behave the same
                location = 'http://localhost:33345/static'; // it's really this easy to set the `window.location.href`
            }, 3000);

        });// load end*/




    }

    callExternalFile=()=>{
        this.anotherOne();
        return;
        /*console.log("this.ifr.contentWindow >>> ",this.ifr.srcdoc);
        const input = document.getElementById('myiframe');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('text/html');
                let doc = new jsPDF('p', 'pt', 'a4', false);
                doc.setFontSize(40);
                doc.setDrawColor(0);
                doc.setFillColor(238, 238, 238);
                doc.rect(0, 0, 595.28,  841.89, 'F');
                doc.text(35, 100, "Generate Payslip");
                doc.addImage(imgData, "pdf", 100, 200, 280, 210, undefined, "medium");

                doc.save( 'download.pdf')
            })
        ;*/

    }
    render() {
        let myStyle = {
            overflow: 'visible',
        };
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="CLocking" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">

                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9 ">


                                <div className="centerContent">
                                <h3>Payslip To PDF</h3>

                                    <iframe src={apiURL} id={"myiframe"} ref={(f) => {this.ifr = f}} />


                                    <button onClick={()=>this.callExternalFile()}>Call FIle</button>
                                </div>

                        </div>
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }


}


var base64Img = null;


const margins = {
    top: 70,
    bottom: 40,
    left: 30,
    width: 550
};

const generate = function()
{
    var pdf = new jsPDF('p', 'pt', 'a4');
    pdf.setFontSize(18);
    pdf.fromHTML(document.getElementById('html-2-pdfwrapper'),
        margins.left, // x coord
        margins.top,
        {
            // y coord
            width: margins.width// max width of content on PDF
        },function(dispose) {
            headerFooterFormatting(pdf, pdf.internal.getNumberOfPages());
        },
        margins);

    var iframe = document.createElement('iframe');
    iframe.setAttribute('style','position:absolute;right:0; top:0; bottom:0; height:100%; width:650px; padding:20px;');
    document.body.appendChild(iframe);

    iframe.src = pdf.output('datauristring');
};
function headerFooterFormatting(doc, totalPages)
{
    for(var i = totalPages; i >= 1; i--)
    {
        doc.setPage(i);
        //header
        header(doc);

        footer(doc, i, totalPages);
        doc.page++;
    }
};

function header(doc)
{
    doc.setFontSize(30);
    doc.setTextColor(40);
    doc.setFontStyle('normal');

    if (base64Img) {
        doc.addImage(base64Img, 'JPEG', margins.left, 10, 40,40);
    }

    doc.text("Report Header Template", margins.left + 50, 40 );
    doc.setLineCap(2);
    doc.line(3, 70, margins.width + 43,70); // horizontal line
};

// You could either use a function similar to this or pre convert an image with for example http://dopiaza.org/tools/datauri
// http://stackoverflow.com/questions/6150289/how-to-convert-image-into-base64-string-using-javascript
function imgToBase64(url, callback, imgVariable) {

    if (!window.FileReader) {
        callback(null);
        return;
    }
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            imgVariable = reader.result.replace('text/html', 'image/jpeg');
            callback(imgVariable);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.send();
};

function footer(doc, pageNumber, totalPages){

    var str = "Page " + pageNumber + " of " + totalPages

    doc.setFontSize(10);
    doc.text(str, margins.left, doc.internal.pageSize.height - 20);

};

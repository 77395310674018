'use strict';

import React, { Component } from 'react';
import Promise from 'promise';

export default class Step5 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saving: false,
            ...props
        };


    }

    componentWillReceiveProps(nextProps){
        this.setState({
            ...this.state,
            ...this.props.getStore(),
        });
        //console.log("componentWillReceiveProps+++++++++>Step5----> ",this.state,nextProps)
    }
    componentDidMount() {

    }

    componentWillUnmount() {}

    render() {
        const savingCls = this.state.saving ? 'saving col-md-12 show' : 'saving col-md-12 hide';
        console.log("+++++++++>Step5----> ",this.state)

        return (
                <div className="row">
                    <form id="Form" className="form-horizontal">
                        <div className="form-group">
                            <label className="col-md-12 control-label stepLable">
                                <h1 className={"companyList"}>Step 5: Review your Details and 'Save'</h1>
                            </label>
                        </div>
                        <div className="form-group">
                            <div className="col-md-12 control-label">
                                <div className="col-md-12 txt">
                                    <div className="col-md-4">
                                        Contract Name
                                    </div>
                                    <div className="col-md-4">
                                        {this.props.getStore().name}
                                    </div>
                                </div>
                                <div className="col-md-12 txt">
                                    <div className="col-md-4">
                                        Description
                                    </div>
                                    <div className="col-md-4">
                                        {this.props.getStore().description}
                                    </div>
                                </div>

                                <div className="col-md-12 eg-jump-lnk">
                                    <a href="#" onClick={() => alert("Thank you")}>e.g. showing how we use the jumpToStep method helper method to jump back to step 1</a>
                                </div>
                                <h2 className={savingCls}>Saving to Cloud, pls wait (by the way, we are using a Promise to do this :)...</h2>
                            </div>
                        </div>
                    </form>
                </div>
        )
    }
}
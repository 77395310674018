import React from 'react';
import {PostPimsRecord} from "../api/post_pmis";
import Button from '@material-ui/core/Button';
import SignaturePad from '../component/com-signature-pad';



const styles = {
    root: {
        width: 500,

    },
    buttonNav:{
        margin: 10,
        fontWeight:"bold",

    }
};

export default class EDocsSign extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            OtpIn: "",
            Otp: "",
            Request: null,
            Signatures: null,
            selectedMenu: "signature"
        }

        this.submitOTP = this.submitOTP.bind(this);
        //this.handleInput = this.handleInput.bind(this);
    }

    componentDidMount() {
        const loc = this.props.location.pathname;
        const arr = loc.split("/edocs/");
        const otp = arr[1];
        let tmp = this.state;
        tmp.OtpIn = otp;
        this.setState(tmp);
        console.log("Edoc otp > ", otp);
    }

    handleInput = (e, key) => {
        this.setState({[key]: e.target.value});
    }
    getHandInput = (key) => {
        return this.state[key];
    }

    async submitOTP(e) {
        e.preventDefault();
        let hub = {otp: this.state.OtpIn};
        let _this = this;
        const endpoint = "/edocs/request/checking";
        console.log("submitForm Edoc request send > ", hub);
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("submitForm Edoc request response > ", data);
            if (data.BOO) {
                _this.setState({Request: data.DATA});
                //alert("OTP result: SUCCESS");
                return;
            }
            alert("OTP result: FAIL! PLEASE TRY AGAIN");
        })
    }

    enterOtp = () => {
        if (this.state.Request !== null) {
            return ""
        }
        return (
            <form onSubmit={this.submitOTP}>
                <div className="form-group row">
                    <label htmlFor="staticEmail" className="col-sm-2 col-form-label">OTP:</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="form-control-plaintext"
                            value={this.getHandInput("OtpIn")}
                            onChange={(e) => this.handleInput(e, "OtpIn")}
                            placeholder={"Enter your OTP send to your phone..."}
                        />
                    </div>
                    <div className="col-sm-1">
                        <button type={"submit"}>Verify</button>
                    </div>
                </div>
            </form>
        )
    }


    renderMenuSignature = () => {
        return (
            <div className="">
                <SignaturePad  docs={this.state.Request}/>
                </div>

        );
    }
    renderMenus = () => {

        if (this.state.Request !== null) {
            if (this.state.selectedMenu === "signature") {
                return this.renderMenuSignature();
            }
        }
        return "";
    }

    setMenu=(menu)=>{
        this.setState({selectedMenu:menu})
    }

    render() {

        const { classes } = this.props;
        const { selectedMenu } = this.state;


        return (
            <div className={"container"}>
                <div className="row">
                    <div className="col col-lg-12" style={{backgroundColor: "blue", color: "white"}}>
                        <h4>PMIS@ eSignature</h4>
                    </div>
                </div>
                <br/>

                {this.state.Request !==null &&(
                    <div>
                    <p className={""}>
                        <Button onClick={()=>{this.setMenu("signature")}} variant="outlined" style={styles.buttonNav}>
                            New Signature
                        </Button>
                        <Button onClick={()=>{this.setMenu("view")}} variant="outlined" color="primary"  style={styles.buttonNav}>
                            View Docs
                        </Button>
                        <Button onClick={()=>{this.setMenu("upload")}} variant="outlined" style={styles.buttonNav}>
                            Upload Doc
                        </Button>
                        <Button onClick={()=>{this.setMenu("info")}} variant="outlined" style={styles.buttonNav}>
                            OTP Info
                        </Button>

                    </p>
                        <hr style={{maxWidth: "700px",marginLeft:0}}/>
                    </div>
                )}

                {this.enterOtp()}
                {this.renderMenus()}
            </div>
        )
    }
}


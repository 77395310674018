
import eConfig from '../epwp_config.json';

const serverEpwp = eConfig.serverEpwp;
const serverRecord = eConfig.serverRecords;


let PostPims = (hub, endpoint, callback) => {
    let url = serverEpwp + endpoint;
    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            const status = response.status;
            callback(response, null, status);
        }).catch((e) => {
        callback(null, e, null);
    });
}

let PostPimsRecord = (hub, endpoint, callback) => {
    let url = serverRecord + endpoint;
    fetch(url, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(hub)
    })
        .then((response) => response.json())
        .then((response) => {
            const status = response.status;
            callback(response, null, status);
        }).catch((e) => {
        callback(null, e, null);
    });
}

export {PostPims,PostPimsRecord};
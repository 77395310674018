/**
 * Created by dev1 on 2016/11/09.
 */

var MAX_XHR_WAITING_TIME = 5000;// in ms

export  function sendAjax(params) {
    var xhr = new XMLHttpRequest(),
        url = params.cache ? params.url + '?' + new Date().getTime() : params.url,
        timer = setTimeout(function () {// if xhr won't finish after timeout-> trigger fail
            xhr.abort();
            params.error && params.error();
            params.complete && params.complete();
        }, MAX_XHR_WAITING_TIME);
    xhr.open(params.type, url,params.data);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            clearTimeout(timer);
            if (xhr.status === 200 || xhr.status === 0) {// 0 when files are loaded locally (e.g., cordova/phonegap app.)
                params.success && params.success(xhr.responseText);
                params.complete && params.complete();
            } else {
                params.error && params.error(xhr.responseText);
                params.complete && params.complete();
            }
        }
    };
    params.beforeSend && params.beforeSend(xhr);
    xhr.send();
}

export function getApiXhrJSON  (params,METHOD) {
    const token = localStorage.getItem("token");
    console.log("*****getApiXhrJSON > ",token);
    sendAjax({
        type: METHOD,
        url: params.url,
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Accept', 'application/json, text/javascript');
            xhr.setRequestHeader('Authorization', token);
        },
        success: function (res) {
            params.success && params.success(JSON.parse(res));
        },
        error: params.error,
        complete: params.complete,
        cache: true
    });
};


/*
POST WITH JSON DATA
 */

export function getApiXhrJSON2  (params,METHOD,data) {
    const token = localStorage.getItem("token");

    sendAjax2({
        type: METHOD,
        url: params.url,
        data:JSON.stringify(data),
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', token);
        },
        success: function (res) {
            params.success && params.success(JSON.parse(res));
        },
        error: params.error,
        complete: params.complete,
        cache: true
    });
};

export  function sendAjax2(params) {
    var xhr = new XMLHttpRequest(),
        url = params.cache ? params.url  : params.url,
        timer = setTimeout(function () {// if xhr won't finish after timeout-> trigger fail
            xhr.abort();
            params.error && params.error();
            params.complete && params.complete();
        }, MAX_XHR_WAITING_TIME);
    xhr.open(params.type, url);
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            clearTimeout(timer);
            if (xhr.status === 200 || xhr.status === 0) {// 0 when files are loaded locally (e.g., cordova/phonegap app.)
                params.success && params.success(xhr.responseText);
                params.complete && params.complete();
            } else {
                params.error && params.error(xhr.responseText);
                params.complete && params.complete();
            }
        }
    };
    params.beforeSend && params.beforeSend(xhr);
    xhr.send(params.data);
}
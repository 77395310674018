import React from 'react';
import {PostPimsRecord} from '../../api/post_pmis';
import Button from '@material-ui/core/Button';
import DocsSignRequest from './docs-sign-request';
import DocsSigned from './docs-signed';

export default class DocsGenerateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Company: "",
            Ref: "",
            TemplateName: null,
            Employee: null,
            Params: null,
            downloadLink: "",
            showRequestForm: false,
        }
        this.fetchTemplateData = this.fetchTemplateData.bind(this);
        this.submitGenerate = this.submitGenerate.bind(this);
    }

    async componentDidMount() {
        const row = JSON.parse(localStorage.getItem("@RECORD-EMPLOYEE"));
        let tmp = this.state;
        tmp.Company = row.Company;
        tmp.Ref = row.EmployeeCode;
        tmp.TemplateName = this.props.TemplateName;
        tmp.Employee = row;
        this.setState(tmp);

        console.log("-------->>>>> ", this.state, localStorage.getItem("@RECORD-EMPLOYEE"));
        this.fetchTemplateData()
    }

    async fetchTemplateData() {
        const hub = {
            Ref: this.state.Ref,
            Company: this.state.Company,
            TemplateName: this.state.TemplateName
        };
        let _this = this;

        const endpoint = "/docs/get-template-data";
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("fetchTemplateData response > ", data);
            _this.setState({Params: data.DATA});
        })
    }

    async submitGenerate() {
        this.setState({downloadLink: ""});
        const hub = {};
        hub.Operation = "dropbox"
        hub.VendorName = "pmis"
        hub.Organization = this.state.Company;
        hub.Company = this.state.Company;
        hub.Ref = this.state.Ref;
        hub.DocCategory = this.state.TemplateName; // "contract signed"

        let params = {};
        for (let i in this.state.Params) {
            const row = this.state.Params[i];
            params[i] = row.Value;
        }

        hub.Data = params;

        console.log("fetchTemplateData send > ", hub);

        let _this = this;

        const endpoint = "/docs/generate-from-template";
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("fetchTemplateData response > ", data);
            _this.setState({downloadLink: data.DATA});
        })
    }

    handelInput = (e, key) => {
        let tmp = this.state;
        tmp.Params[key].Value = e.target.value;
        this.setState(tmp);
        console.log("handelInput > ", key, " > ", this.state.Params[key].Value);
    }

    renderDataTableInput = () => {
        let ls = [];
        for (let i in this.state.Params) {
            const row = this.state.Params[i];
            if (row.Source === "input") {
                ls.push(row);
            }
        }
        return ls.map((row, index) => {
            const key = index + "#" + row.VarName;
            return (
                <tr key={key}>
                    <td>
                        <input
                            style={styles.input}
                            onChange={(e) => this.handelInput(e, row.VarName)}
                        />
                    </td>
                    <td>{row.Detail}</td>
                </tr>
            )
        })
    }
    renderDataTableOthers = (source) => {
        let ls = [];
        for (let i in this.state.Params) {
            const row = this.state.Params[i];
            if (row.Source === source) {
                ls.push(row);
            }
        }
        return ls.map((row, index) => {
            const key = index + "#" + row.VarName;
            return (
                <tr key={key}>
                    <td>
                        <span><b>{row.Value}</b></span>
                    </td>
                    <td>{row.Detail}</td>
                </tr>
            )
        })
    }

    openDocNewWindown = () => {
        console.log("Opening link pdf link request > ", this.state.downloadLink);
        document.title = this.state.Ref + " " + this.state.Employee.Name;
        window.open(this.state.downloadLink, '_blank');
    }
    renderDownloadButton = () => {

        if (this.state.downloadLink === "") {
            return (
                <Button
                    variant="outlined"
                    color="secondary"
                    disabled
                    className={styles.button}

                >
                    DOWNLOAD
                </Button>
            )
        }

        return (
            <Button
                variant="outlined"
                color="secondary"
                className={styles.button}
                onClick={() => this.openDocNewWindown()}
            >
                DOWNLOAD
            </Button>
        )
    }

    renderSignRequest = () => {

        if (this.state.downloadLink === "") {
            return (<span/>)
        }

        return (
            <Button
                variant="outlined"
                color="secondary"
                className={styles.button}
                onClick={() => this.setState({showRequestForm: true})}
            >
                Request e-Sign
            </Button>
        )
    }

    handleClose = () => {
        this.setState({showRequestForm: false})
    }

    render() {

        let strUser = window.localStorage.getItem("@pmis-user");
        strUser = strUser.toLocaleLowerCase();
        console.log("pmis-user --> ", strUser);

        console.log("pmis-state --> ", this.state);

        const loginUser = JSON.parse(strUser);

        return (
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10">
                        <p>Geneate Doc Application Comming soon ! contract signed</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-10">
                        <p>

                            <Button
                                color="primary"
                                variant="outlined"
                                className={styles.button}
                                onClick={() => this.submitGenerate()}
                            >
                                GENERATE
                            </Button> &nbsp;
                            {this.renderDownloadButton()}
                            &nbsp;
                            {this.renderSignRequest()}


                        </p>
                    </div>
                </div>

                <div className={"row"}>
                    <div className="col col-lg-10">
                        <DocsSigned
                            organization={this.state.Company}
                            company={this.state.Company}
                            ref={this.state.Ref}
                            docCategory={this.state.TemplateName}
                            state={this.state}
                            props={this.props}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col col-lg-10">
                        <br/><br/>
                        {this.state.showRequestForm && (
                            <DocsSignRequest
                                state={this.state}
                                loginUser={loginUser}
                                open={this.state.showRequestForm}
                                handleClose={this.handleClose}
                            />
                        )}

                        <table className={"table myTb"} style={{width: "85%"}}>
                            <thead>
                            <tr>
                                <th>Value</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderDataTableInput("input")}
                            {this.renderDataTableOthers("setting")}
                            {this.renderDataTableOthers("system")}
                            {this.renderDataTableOthers("employee")}
                            {this.renderDataTableOthers("contract")}
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        )
    }
}

const styles = {
    button: {},
    tbSource: {
        textAlign: "right"
    },
    input: {
        width: "100%",
    },
    tbDescription: {
        width: "5%",
        textAlign: "center"
    },

}
import React from 'react';
import SignatureCanvas from "react-signature-canvas";
import '../asset/css/edoc-signature.css';
import {PostPimsRecord} from "../api/post_pmis";

export default class SignaturePad extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Request: null,
            trimmedDataURLSignature: null,
            trimmedDataURLInitial: null

        }

        this.submitRequest = this.submitRequest.bind(this);
    }

    componentDidMount() {
        let tmp = this.state;
        tmp.Request = this.props.docs.RequestedSignature;
        this.setState(tmp);
    }

    SaveSignature = () => {

    }

    sigPadSignature = {}
    sigPadInitial = {}

    clearSignature = () => {
        this.sigPadSignature.clear()
        this.setState({trimmedDataURLSignature:null})
    }
    trimSignature = () => {
        this.setState({
            trimmedDataURLSignature: this.sigPadSignature.getTrimmedCanvas()
                .toDataURL('image/png')
        })
    }

    clearInitial = () => {
        this.sigPadInitial.clear()
        this.setState({trimmedDataURLInitial:null})
    }
    trimInitial = () => {
        this.setState({
            trimmedDataURLInitial: this.sigPadInitial.getTrimmedCanvas()
                .toDataURL('image/png')
        })
    }

    validation=()=>{
        if(this.state.trimmedDataURLSignature ===null){
            alert("Validation Fail! Signature can't be empty")
            return false
        }
        if(this.state.trimmedDataURLInitial===null){
            alert("Validation Fail! Initial can't be empty")
            return false
        }

        return true
    }
    async submitRequest() {
        if(!this.validation()){
            return
        }

        const req  = this.props.docs;
        let hub = {};

        hub.OtpUUID=req.OtpUUID;

        hub.Otp          =req.Otp;
        hub.Organization =req.Company;
        hub.Company      =req.Company;
        hub.Ref          =req.Ref;
        hub.RequestName  =req.RequestName;
        hub.Request      =req;
        //hub.Attachments        map[string]string
        hub.RequestedSignature ={
            signature:this.state.trimmedDataURLSignature,
            initial: this.state.trimmedDataURLInitial
        }
        hub.Comment  ="No comment" ;





        let _this = this;
        const endpoint = "/edocs/response/posted";
        console.log("submitForm Edoc request send > ", hub);
        await PostPimsRecord(hub, endpoint, function (data) {
            console.log("submitForm Edoc request response > ", data);
            alert("Your signature and Initial have been submitted");
        })

    }

    render() {
        return (

            <div>
                <div className={"container"}>
                    <div className={"sigContainer"}>
                        <h2>Signature</h2>
                        <SignatureCanvas
                            canvasProps={{className: 'signature-pad'}}
                            ref={(ref) => {
                                this.sigPadSignature = ref
                            }}
                        />
                    </div>

                    <div className="signature-pad-actions">
                        <div>
                            <br/>
                            <button type="button" className="button clear" onClick={() => this.clearSignature()}>Clear
                            </button>
                            &nbsp;&nbsp;
                            <button type="button" className="button save"
                                    onClick={() => this.trimSignature()}>Save
                            </button>
                        </div>
                    </div>

                    {this.state.trimmedDataURLSignature
                        ? <img
                            className={"sigImage"}
                            src={this.state.trimmedDataURLSignature}
                        />
                        : null}
                </div>

                <div className={"container"}>
                    <div className={"sigContainer"}>
                        <h2>Initial</h2>
                        <SignatureCanvas
                            canvasProps={{className: 'signature-pad'}}
                            ref={(ref) => {
                                this.sigPadInitial = ref
                            }}
                        />
                    </div>

                    <div className="signature-pad-actions">
                        <div>
                            <br/>
                            <button type="button" className="button clear" onClick={() => this.clearInitial()}>Clear
                            </button>
                            &nbsp;&nbsp;
                            <button type="button"  className="button save"
                                    onClick={() => this.trimInitial()}>Save
                            </button>
                        </div>
                    </div>

                    {this.state.trimmedDataURLInitial
                        ? <img
                            className={"sigImage"}
                            src={this.state.trimmedDataURLInitial}
                        />
                        : null}
                </div>

                <div className={"container"}>
                    <hr/>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => this.submitRequest()}
                    >
                        Submit
                    </button>
                    <br/><br/>
                </div>


            </div>

        )
    }
}

const styles = {}

/*
<div id="signature-pad" className="signature-pad">
                <SignatureCanvas
                    penColor='white'
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                    backgroundColor="black"

                />
            </div>
 */
import React from 'react';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import {ListItem} from 'material-ui/List';
import Checkbox from 'material-ui/Checkbox';
import RaisedButton from 'material-ui/RaisedButton';
import {Tabs, Tab} from 'material-ui/Tabs';
import Badge from 'material-ui/Badge';
import moment from 'moment';

import {CSVLink, CSVDownload} from 'react-csv';
import CsvCreator from 'react-csv-creator';
import ComExcelBook from '../component/common/payroll-payment-download-excel-procard';


import Paper from 'material-ui/Paper';
import TopMenu from '../component/common/top-nav-new';


import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import SideMenu from '../component/common/side-menu-nav';
import '../asset/styles/global-main.css';


/**
 * LEt try this to make it work
 */

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const serverEpwp = eConfig.serverEpwp;

const styles = {
    customWidth: {
        width: 150,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    headline: {
        fontSize: 24,
        paddingTop: 16,
        marginBottom: 12,
        fontWeight: 400,
    },
};
const style = {
    margin: 12,
};

/**
 * OUr tables
 * */

const MyDownload=(rows,daytype)=>{
    //const rows = [["name1", "city1", "some other info"], ["name2", "city2", "more info"]];
    let datas = [];
    for(let  i in rows){
        const row = rows[i];
        datas.push([
            row.Code,
            row.Tans,
            row.Blanc1,
            row.Blanc2,
            row.Days,
            row.Rate,
            row.Blanc3,
            row.Override
        ]);
    }
    let csvContent = "data:text/csv;charset=utf-8,";
    datas.forEach(function(rowArray){
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    let encodedUri = encodeURI(csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pmis_pastel_"+daytype+".csv");
    link.innerHTML= "Click Here to download";
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
}


const BuildTable = ({data,dataCsv,daytype,totalDay})=>{
    const headers = [{
        id: 'first',
        display: 'First column'
    }, {
        id: 'second',
        display: 'Second column'
    }];

    const rows = [{
        first: 'foo',
        second: 'bar'
    }, {
        first: 'foobar',
        second: 'foobar'
    }];




    return(
        <div className="pastelDiv">
            <h3 className="pastelHeading">
                <span className="pastelTitle">{daytype}</span>
                &nbsp;&nbsp;
                <span className="pastelTitleDetail"><small>(People=<b>{data.length}</b>)</small></span>
                &nbsp;
                <span className="pastelTitleDetail"><small>(TotalDay=<b>{totalDay}</b>)</small></span>
                &nbsp;&nbsp;

                <a onClick={()=>(MyDownload(dataCsv,daytype))} className="pastelDownloadBtn">
                    <i className="fa fa-cloud-download pastelDownloadBtn"/>
                </a>

            </h3>
            <table className={"table pastelTable"}>
                <thead>
                <tr>
                    <th>Employee Code</th>
                    <th>Employee</th>
                    <th>Transaction Code</th>
                    <th>Job Code</th>
                    <th>Cost Centre</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Override</th>
                </tr>
                </thead>
                <tbody>
                {data.map((row)=>{
                    return(<tr>
                        <td>{row.EmployeeCode}</td>
                        <td>{row.Employee}</td>
                        <td>{row.TransactionCode}</td>
                        <td>{row.JobCode}</td>
                        <th>{row.CostCentre}</th>
                        <td>{row.Quantity}</td>
                        <td>{row.Rate}</td>
                        <td>{row.Amount}</td>
                        <td>{row.Override}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        </div>
    )
};

const findRate = (currRate,RateType,defaultWorkingHours)=>{
    let myRate = currRate;
    if(RateType ==="hourly"){
        myRate  = currRate * parseFloat(defaultWorkingHours);
    }
    return myRate;
}



class MyContainerReportPayrollPayment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view: "list",
            company: "",
            username: "",
            LoginUser: {},

            selectedContract: "",
            selectedSite: "",
            selectedDate: "",

            selectedSiteReport: "",
            selectedPaycycleReport: "",

            Conditions: [],
            Sites: [],
            Employees: [],
            EmployeesMap: {},
            Clocks: [],
            ClocksContract: [],
            ClockBasicReport: {},
            ClockBasicReportContract: {},
            PayCycles: [],
            csvDataPayaccsys: [],
            csvDataProcard: [],
            csvDataKey: [],
            selectedEmployees: {},
            selected: [1],
            startDate: "",
            endDate: "",

            empClocks: [],
            empCurrentViewClock: {},
            isViewClock: false,

            PeoplesSite: [],

            controlledDate: null,

        };

        this.searchClock = this.searchClock.bind(this);
        this.fetchEmployee = this.fetchEmployee.bind(this);
        this.fetchContractConditions  = this.fetchContractConditions.bind(this);
        this.handleChangePaycycle = this.handleChangePaycycle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderPaycycleOption = this.renderPaycycleOption.bind(this);
        this.renderDayClockReportPayAccSys = this.renderDayClockReportPayAccSys.bind(this);
        this.renderDayClockReportProcard = this.renderDayClockReportProcard.bind(this);


        this.convertStringDateIntoMomentDate = this.convertStringDateIntoMomentDate.bind(this);
        this.convertMomentDateToStringISO = this.convertMomentDateToStringISO.bind(this);

        this.isSelected = this.isSelected.bind(this);
        this.onDownloadExcel = this.onDownloadExcel.bind(this);
        this.renderPastel = this.renderPastel.bind(this);


    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.LoginUser = u;
        tmp.startDate = this.convertMomentDateToStringISO(moment());
        tmp.endDate = this.convertMomentDateToStringISO(moment());
        this.setState(tmp);

        /**
         * Load default from api
         */
        this.fetchContractConditions();
        this.fetchEmployee();
        this.fetchPayCycle();

    }

    onDownloadExcel() {

        return (<ComExcelBook data={this.state.csvDataProcard}/>)
    }

    isSelected(index) {
        return this.state.selected.indexOf(index) !== -1;
    }

    convertStringDateIntoMomentDate(dateString) {
        let momentObj = moment(dateString, 'YYYY-MM-DD');
        return momentObj;
    }

    convertMomentDateToStringISO(dt) {
        let momentObj = moment(dt);
        let momentString = momentObj.format('YYYY-MM-DD'); // 2016-07-15
        return momentString
        // let dttt = dt.format("YYYY-MM-DD");
        // console.log("!!!!!!!!!!---> ",momentString);
        // return dt.toISOString().slice(0, 10);
    }

    fetchContractConditions() {
        let company = this.state.company;
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");

        let endpoint = "/api/contracts/contract/conditions/findone";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = storeContract;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["Conditions"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchContractConditions List response ", responseData);
            });
    }


    searchClock() {
        /** Initialize clocks array **/
        var tmp = this.state;
        tmp["Clocks"] = [];
        this.setState(tmp);


        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/clock/report/basic";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.ContractName = storeContract;
        hub.SiteName = this.state.selectedSiteReport;
        hub.start = this.state.startDate;
        hub.end = this.state.endDate;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                let csvDataPayaccsys = [];
                let csvDataProcard = [];
                let csvDataKey = [];
                let x = 0;
                for (let i in responseData.report) {
                    let row = responseData.report[i];
                    row.NetPay = row.NetPay.toFixed(2);
                    const emp = this.state.EmployeesMap[row.EmployeeCode];
                    row.AccountNumber = emp.AccountNumber;
                    row.Bank = emp.Bank;
                    row.BranchCode = emp.BranchCode;
                    row.EmployeeIDNo = emp.EmployeeIDNo;
                    //todo let generate csv data

                    if (emp.PaymentMethod === "PayAccsys") {
                        csvDataPayaccsys.push([
                            row.EmployeeCode,
                            row.Name,
                            row.NetPay,
                            row.AccountNumber,
                            row.BranchCode,
                            row.Site,
                            row.Designation,
                            /* row.Bank*/
                        ]);


                    }

                    if (emp.PaymentMethod === "Procard") {
                        csvDataProcard.push({
                            EmployeeCode: row.EmployeeCode,
                            Name: emp.Name,
                            Surname: emp.Surname,
                            NetPay: row.NetPay,
                            Account: row.AccountNumber,
                            BranchCode: row.BranchCode,
                            Site: row.Site,
                            Designation: row.Designation,
                            Bank: row.Bank,
                            EmployeeIDNo: row.EmployeeIDNo,
                            Company: emp.Company,
                        });
                    }


                }


                var tmp = this.state;
                tmp["Clocks"] = responseData.clocks;
                tmp["ClocksContract"] = responseData.clocksContract;
                tmp["ClockBasicReport"] = responseData.report;
                tmp["ClockBasicReportContract"] = responseData.reportContract;
                tmp["csvDataPayaccsys"] = csvDataPayaccsys;
                tmp["csvDataProcard"] = csvDataProcard;

                this.setState(tmp);
                console.log("+++++>searchClock List response ", responseData);
            });
    }

    fetchEmployee() {
        let company = this.state.company;
        let endpoint = "/employee/list/" + company;
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                let empMap = {};
                for (let i in responseData) {
                    const row = responseData[i];
                    empMap[row.EmployeeCode] = row;
                }

                var tmp = this.state;
                tmp["Employees"] = responseData;
                tmp["EmployeesMap"] = empMap;
                this.setState(tmp);
                console.log("+++++>fetchEmployee List response ", responseData);
            });
    }

    fetchPayCycle() {
        //  api/contracts/contract/paycycle-detail/list-contract
        let storeContract = window.localStorage.getItem("SELECTED-CONTACT");
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/paycycle-detail/list-contract";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        hub.contractName = storeContract;


        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp["PayCycles"] = responseData;
                this.setState(tmp);
                console.log("+++++>fetchPayCycle List response ", responseData);
            });
    }

    handleChangePaycycle(e, index, value) {
        let arr = value.split("--");
        const startDate = arr[1];
        const endDate = arr[2];
        this.handleChange("startDate", startDate)
        this.handleChange("endDate", endDate);
        this.handleChange("selectedPaycycleReport", value);


    }

    handleChange(key, val) {
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);
        console.log("-->handleChange > ", key, " > ", val);
    }

    renderPaycycleOption() {
        let list = [];
        for (let i in this.state.PayCycles) {
            let row = this.state.PayCycles[i];
            let o = {
                val: row.Period + "--" + row.StartDate + "--" + row.EndDate + "--" + row.PayDate,
                label: "" + row.Period + ". -> Start: " + convertDateFormat_DDMMYYYY(row.StartDate) + " -> End: " + convertDateFormat_DDMMYYYY(row.EndDate) + " -> Pay date: " + convertDateFormat_DDMMYYYY(row.PayDate),
            };
            list.push(o)
        }
        return list.map((row) => {
            return (
                <MenuItem value={row.val} primaryText={row.label}/>
            )
        });
    }

    renderDayClockReportProcard() {
        let ls = this.state.csvDataProcard;
        console.log("renderDayClockReportProcard > ", ls);
        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Data for Procard</p>)
        }

        let index = 0;

        let alignLeft = {
            textAlign: "left"
        };

        let total = 0;
        return (
            <table className="table "
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th colSpan={5} style={{textAlign: "right"}}>
                        Effective
                    </th>
                    <th>
                        {moment().format("DD MMM YYYY")}
                    </th>
                </tr>
                <tr>
                    <th width='200px' style={alignLeft}>Payroll Number</th>
                    <th width='400px' style={alignLeft}>Name</th>
                    <th width='400px' style={alignLeft}>Surname</th>
                    <th width='200px' style={alignLeft}>NetPay</th>
                    <th width='200px' style={alignLeft}>ID Number</th>
                    <th width='150px' style={alignLeft}>Description</th>
                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        total = total + parseFloat(row.NetPay);
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.Name}</td>
                                <td width='200px'>{row.Surname}</td>
                                <td width='200px' align="left">{row.NetPay}</td>
                                <td width='150px'>{row.EmployeeIDNo}</td>
                                <td width='150px'>{row.Company}</td>
                            </tr>
                        )
                    })
                }
                </tbody>
                <tfoot>
                <tr>
                    <th colSpan={3} style={{textAlign: "right"}}>
                        <b>Total:</b>
                    </th>
                    <td>
                        <b>{total}</b>
                    </td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                </tfoot>
            </table>

        )
    }

    renderDayClockReportPayAccSys() {
        let csvData = [];
        let ls = [];
        for (let i in this.state.ClockBasicReport) {
            //PaymentMethod: "PayAccsys"

            let row = this.state.ClockBasicReport[i];


            //row.NetPay = row.NetPay;
            const emp = this.state.EmployeesMap[row.EmployeeCode];
            if (emp.PaymentMethod === "PayAccsys") {
                row.AccountNumber = emp.AccountNumber;
                row.Bank = emp.Bank;
                row.BranchCode = emp.BranchCode;
                ls.push(row);
            } else {
                console.log("row.PaymentMethod > ", emp.PaymentMethod)
            }


        }

        //todo let state this csvData
        //this.handleChange("csvData",csvData);

        console.log("renderDayClockReport > ", ls.length, ls);

        if (ls.length === 0) {
            return (<p className="alert alert-danger">No Clocking</p>)
        }

        let index = 0;

        let alignLeft = {
            textAlign: "left"
        }
        return (
            <table className="table "
                   style={{width: "99%", height: "90%", overflow: "visible"}}>
                <thead style={{width: "99%", height: "90%", overflow: "visible"}}>
                <tr>
                    <th colSpan={7}>Total Employee <span className="pull-right">{ls.length}</span></th>
                </tr>
                <tr>
                    <th width='200px' style={alignLeft}>Code</th>
                    <th width='400px' style={alignLeft}>Name</th>
                    <th width='200px' style={alignLeft}>NetPay</th>
                    <th width='200px' style={alignLeft}>Account</th>
                    <th width='150px' style={alignLeft}>BranchCode</th>
                    <th width='200px' style={alignLeft}>Site</th>
                    <th width='200px' style={alignLeft}>Designation</th>

                </tr>
                </thead>
                <tbody style={{width: "99%", height: "90%", overflow: "scroll"}}>
                {
                    ls.map((row) => {
                        index++;
                        let accountNumber, branchCode;
                        return (
                            <tr selected={this.isSelected(index)}>
                                <td width='200px'>{row.EmployeeCode}</td>
                                <td width='200px'>{row.Name}</td>
                                <td width='200px' align="left">{row.NetPay}</td>
                                <td width='150px'>{row.AccountNumber}</td>
                                <td width='150px'>{row.BranchCode}</td>
                                <td width='150px'>{row.Designation}</td>
                                <td width='200px'>{row.Site}</td>

                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

        )

    }

    renderPastel() {

        let reports = this.state.ClockBasicReport;
        if (reports.length === 0) {
            return
        }

        let lsNormal = [];
        let lsNormalCsv = [];
        let lsSaturday = [];
        let lsSaturdayCsv = [];
        let lsSunday = [];
        let lsSundayCsv = [];
        let lsPhh  = [];
        let lsPhhCsv  = [];

        let totDayNormal=0,totDaySaturday=0,totDaySunday=0,totDayPh=0;


        for (let i in this.state.ClockBasicReport) {

            let row = this.state.ClockBasicReport[i];
            const emp = this.state.EmployeesMap[row.EmployeeCode];
            row.AccountNumber = emp.AccountNumber;
            row.Bank = emp.Bank;
            row.BranchCode = emp.BranchCode;

            const arr = row.Name.split(" ");
            const fullname =  arr[0][1].toUpperCase()+" "+arr[1];

            if(row.WeekDay > 0){//todo this is normal day
                let dayRate  = findRate(row.RateNormal,row.RateType,row.DefaultWorkingHours,this);

                if(row.EmployeeCode ==="FF-1006"){
                    console.log("-=-=-=>>> ",row.EmployeeCode," > ",row.RateType," > ",row.RateNormal," > " ,row.DefaultWorkingHours," > ",row.Designation);
                }

                totDayNormal = totDayNormal + row.WeekDay;

                lsNormal.push({
                    EmployeeCode: row.EmployeeCode,
                    Employee: fullname,
                    TransactionCode:"NORM",
                    JobCode:'',
                    CostCentre:"",
                    Quantity: row.WeekDay,
                    Rate: dayRate.toFixed(2),
                    Amount:"",
                    Override:"N"
                });

                /*lsNormalCsv.push([
                    `'`+row.EmployeeCode+`'`, 'NORM', '', '',`'`+row.WeekDay,dayRate.toFixed(1)+`'`,'','N'
                ]);*/

                lsNormalCsv.push({
                    Code: row.EmployeeCode,
                    Tans: 'NORM',
                    Blanc1:'',
                    Blanc2:'',
                    Days: row.WeekDay,
                    Rate: dayRate.toFixed(1),
                    Blanc3:'',
                    Override: 'N'
                });

            }

            if(row.SaturdayDays > 0){//todo this is saturday day
                let dayRate  = findRate(row.RateSaturday,row.RateType,row.DefaultWorkingHours,this);
                totDaySaturday = totDaySaturday + row.SaturdayDays;
                lsSaturday.push({
                    EmployeeCode: row.EmployeeCode,
                    Employee: fullname,
                    TransactionCode:"SATU",
                    JobCode:"",
                    CostCentre:"",
                    Quantity: row.SaturdayDays,
                    Rate: dayRate.toFixed(2),
                    Amount:"",
                    Override:"N"
                });
                /*
                lsSaturdayCsv.push([
                    row.EmployeeCode, "SATU", "", "",row.SaturdayDays,dayRate.toFixed(1),"","N"
                ])*/

                lsSaturdayCsv.push({
                    Code: row.EmployeeCode,
                    Tans: 'SATU',
                    Blanc1:'',
                    Blanc2:'',
                    Days: row.SaturdayDays,
                    Rate: dayRate.toFixed(1),
                    Blanc3:'',
                    Override: 'N'
                });
            }

            if(row.SundayDays > 0){//todo this is sunday day
                let dayRate  = findRate(row.RateSunday,row.RateType,row.DefaultWorkingHours,this);
                totDaySunday = totDaySunday + row.SundayDays;
                lsSunday.push({
                    EmployeeCode: row.EmployeeCode,
                    Employee: fullname,
                    TransactionCode:"SUND",
                    JobCode:"",
                    CostCentre:"",
                    Quantity: row.SundayDays,
                    Rate: dayRate.toFixed(2),
                    Amount:"",
                    Override:"N"
                });
                /*
                lsSundayCsv.push([
                    row.EmployeeCode, "SUND", "", "",row.SundayDays,dayRate.toFixed(1),"","N"
                ])*/

                lsSundayCsv.push({
                    Code: row.EmployeeCode,
                    Tans: 'SUND',
                    Blanc1:"",
                    Blanc2:"",
                    Days: row.SundayDays,
                    Rate: dayRate.toFixed(1),
                    Blanc3:"",
                    Override: 'N'
                });

            }

            if(row.PphDdays > 0){//todo this is public holiday day
                let dayRate  = findRate(row.RatePph,row.RateType,row.DefaultWorkingHours,this);
                totDayPh = totDayPh + row.PphDdays;
                lsPhh.push({
                    EmployeeCode: row.EmployeeCode,
                    Employee: fullname,
                    TransactionCode:"PUBL",
                    JobCode:"",
                    CostCentre:"",
                    Quantity: row.PphDdays,
                    Rate: dayRate.toFixed(2),
                    Amount:"",
                    Override:"N"
                });

                /*lsPhhCsv.push([
                    row.EmployeeCode, "PUBL", "", "",row.PphDdays,dayRate.toFixed(1),"","N"
                ])*/

                lsPhhCsv.push({
                    Code: row.EmployeeCode,
                    Tans: 'PUBL',
                    Blanc1:"",
                    Blanc2:"",
                    Days: row.PphDdays,
                    Rate: dayRate.toFixed(1),
                    Blanc3:"",
                    Override: 'N'
                });
            }

        }


       // console.log("-=-=-=>>>> ", lsNormalCsv);


        return(
            <div>
                <h3>&nbsp;</h3>
            <Tabs>
                <Tab label="Normal Day" className="tab-header">
                <BuildTable data={lsNormal} dataCsv={lsNormalCsv} daytype={"Normal"} totalDay={totDayNormal}/>
                </Tab>
                <Tab label="Saturday">
                    <BuildTable data={lsSaturday} dataCsv={lsSaturdayCsv} daytype={"Saturday"}  totalDay={totDaySaturday}/>
                </Tab>
                <Tab label="Sunday">
                    <BuildTable data={lsSunday} dataCsv={lsSundayCsv} daytype={"Sunday"}  totalDay={totDaySunday}/>
                </Tab>
                <Tab label="Public Holiday">
                    <BuildTable data={lsPhh} dataCsv={lsPhhCsv} daytype={"Public Holiday"}  totalDay={totDayPh}/>
                </Tab>
            </Tabs>
            </div>
        )



    }

    render() {
        return (
            <MuiThemeProvider>
                <div className="">
                    <TopMenu page="CLocking" menu={this.props.location.pathname}/>

                    <div className="row">

                        <div className="col col-lg-3">
                            {/*LET BUILD OUR MENU HERE*/}
                            <SideMenu/>
                        </div>

                        <div className="col col-lg-9">
                            <Paper style={style} zDepth={1}>

                                <div className="row">

                                    <div className="col-lg-9">
                                        <SelectField
                                            floatingLabelText="Pay Cycle"
                                            name="selectedSiteReport"
                                            onChange={this.handleChangePaycycle}
                                            value={this.state.selectedPaycycleReport}
                                            fullWidth={true}
                                        >
                                            {this.renderPaycycleOption()}
                                        </SelectField>
                                    </div>

                                    <div className="col-lg-3">
                                        <br/>
                                        <RaisedButton
                                            label="Search"
                                            default={true}
                                            style={style}
                                            onClick={() => {
                                                this.searchClock();
                                            }}

                                        />

                                    </div>

                                </div>

                                <div className="centerContent">


                                    <Tabs>
                                        <Tab label="PayAccSys">

                                            {
                                                this.state.csvDataPayaccsys.length > 0 && (
                                                    <CSVLink
                                                        style={{marginTop: '10px'}}
                                                        data={this.state.csvDataPayaccsys}
                                                        className="btn btn-lg btn-primary btn-bd-download d-none d-lg-inline-block mb-3 mb-md-0 ml-md-3">
                                                        <i className="fa fa-cloud-download"/>
                                                        Download CSV
                                                    </CSVLink>
                                                )
                                            }


                                            {this.renderDayClockReportPayAccSys()}

                                        </Tab>

                                        <Tab label="ProCard">

                                            {this.onDownloadExcel()}
                                            {this.renderDayClockReportProcard()}

                                        </Tab>

                                        <Tab label="Pastel">
                                            {this.renderPastel()}

                                        </Tab>

                                    </Tabs>


                                </div>

                            </Paper>

                        </div>

                    </div>

                </div>

            </MuiThemeProvider>
        )
    }


}

export default MyContainerReportPayrollPayment;


function convertDateFormat_DDMMYYYY(dateIn) {
    return moment(dateIn, "YYYY-MM-DD").format("DD MMM YYYY");
}
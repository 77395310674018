/**
 * Created by dev1 on 2017/09/27.
 */
import React from 'react';
import { Button,Popover ,Modal,OverlayTrigger,Tooltip} from 'react-bootstrap';


class MyComponentModalChangeStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            newStatus:"",
            date:"",
            Status:[],
        };

        this.close = this.props.close.bind(this);
        this.open =this.props.open.bind(this);
        this.handleInput=this.handleInput.bind(this);
        this.submitChangeStatus = this.submitChangeStatus.bind(this);
        this.loadStatus =this.loadStatus.bind(this);
        this.renderStatus = this.renderStatus.bind(this);

        this.loadStatus();


    }
    componentWillMount() {
        let tmp=this.state;
        tmp.showModal=false;
        this.setState(tmp);
    }
    handleInput(e){
        let key = e.target.name;
        let val = e.target.value;
        let tmp = this.state;
        tmp[key] = val;
        this.setState(tmp);

    }
    loadStatus(){
        let responseData = [
            {code:"100",reason:"Reason 100"},
            {code:"200",reason:"Reason 200"},
            {code:"300",reason:"Reason 300"},
            {code:"400",reason:"Reason 400"},
        ]

        let tmp=this.state;
        tmp.Status=responseData;
        this.setState(tmp);
    }
    submitChangeStatus(e){
        e.preventDefault()
        let text = window.confirm("are you that you want to terminate contract of this employee?")
        if(!text){
            return
        }

        //todo change status
        this.props.close();

    }



    close() {
        this.setState({ showModal: false });
    }

    open() {
        this.setState({ showModal: true });
    }

    openclose(boo){
        alert("OpenClose "+boo);
        if(boo){
            this.open()
        }else{
            this.close()
        }
    }

    componentWillUpdate(nextProps){
        //console.log("===> nextProps >> ",nextProps);
    }

    renderStatus(){
        return this.state.Status.map((row)=>{
            return(
                <option value={row.code+"##"+row.reason} key={row.code}>
                    {row.reason}
                </option>
            )
        })
    }

    render() {
        const popover = (
            <Popover id="modal-popover" title="popover">
                very popover. such engagement
            </Popover>
        );
        const tooltip = (
            <Tooltip id="modal-tooltip">
                wow.
            </Tooltip>
        );

        let data = [];
        for(var i in this.props.data){
            const key = i;
            const val = this.props.data[key];
            const row ={key:key,val:val};
            data.push(row);
        }

        return (
            <div>

                <Modal show={this.props.openclose} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terminate Employee contract</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row container">
                            <div className="col-lg-12">
                                <p>Employee: <b>{this.props.data.Name+" "+this.props.data.Surname}</b></p>
                            </div>
                        </div>
                        <div className="row container">
                            <div className={"col-lg-12"}>

                            <form className={""} onSubmit={this.submitChangeStatus}>
                                <div className="form-group">
                                    <label>Select Status:</label>
                                    <select className="form-control"
                                            onChange={this.handleInput}
                                            required={true}
                                            name="newStatus"
                                            id={"newStatus"}
                                    >
                                        <option value="">---select Reason---</option>
                                        { this.renderStatus()}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Termination Date:</label>
                                    <input className="form-control"
                                           type={"date"}
                                            onChange={this.handleInput}
                                            required={true}
                                            name="date"
                                            id={"date"}
                                           placeholder={"YYYY-MM-DD"}
                                    />

                                </div>

                                <button type="submit" className="btn btn-danger pull-right">Change</button>
                            </form>

                            </div>

                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default MyComponentModalChangeStatus;


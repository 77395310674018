'use strict';

import React, { Component } from 'react';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../common/handle-input';

export default class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            municipality:props.getStore().municipality,
            name:props.getStore().name,
            description:props.getStore().description,
            province:props.getStore().province,
        };

        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);
        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }


    // not required as this component has no forms or user entry
    // isValidated() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getStore().name !== userInput.name ||
                this.props.getStore().description !== userInput.description ||
                this.props.getStore().province !== userInput.province ||
                this.props.getStore().municipality !== userInput.municipality) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        //console.log("validationCheck > ",this.state);
        this.props.updateStore(userInput);
    }

    _validateData(data) {
        return  {
            nameVal:(data.name !==''),
            descriptioneVal:(data.description !==''),
            provinceVal:(data.province !==''),
            municipalityVal:(data.municipality !==''),
            /*genderVal: (data.gender !== 0), // required: anything besides N/A
            emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email),*/ // required: regex w3c uses in html5
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            nameValMsg: val.nameVal ? '' : 'A valid name of contract  is required',
            descriptioneValMsg: val.descriptioneVal ? '' : 'A valid description of contract  is required',
            provinceValMsg: val.provinceVal ? '' : 'A valid province of contract  is required',
            municipalityValMsg: val.municipalityVal ? '' : 'A valid municipality of contract  is required',
            /*emailValMsg: val.emailVal ? '' : 'A valid email is required'*/
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            name: this.refs.name.value,
            description: this.refs.description.value,
            province: this.refs.province.value,
            municipality: this.refs.municipality.value,
        };
    }


    render() {
        let _this = this;

        let notValidClasses = {};

        if (typeof this.state.nameVal === 'undefined' || this.state.nameVal) {
            notValidClasses.nameCls = 'no-error col-md-8';
        }
        else {
            notValidClasses.nameCls = 'has-error col-md-8';
            notValidClasses.nameValGrpCls = 'val-err-tooltip';
        }

        if (typeof this.state.descriptionVal == 'undefined' || this.state.descriptionVal) {
            notValidClasses.descriptionCls = 'no-error col-md-8';
        }
        else {
            notValidClasses.descriptionCls = 'has-error col-md-8';
            notValidClasses.descriptionValGrpCls = 'val-err-tooltip';
        }



        return (
            <div className="step step1">
                <div className="row">
                    <form id="Form" className="form-horizontal">
                        <div className="form-group">
                            <label className="col-md-12 control-label">
                                <h1 className={"companyList"}>Step 1: Info</h1>
                            </label>


                                <div className="form-row">
                                    <div className={"form-group col-md-6"}>
                                        <label className="">
                                            Name
                                        </label>
                                        <div>
                                            <input
                                                ref="name"
                                                autoComplete="off"
                                                type="text"
                                                placeholder="e.g Easipth"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.name}
                                                onBlur={this.validationCheck} />

                                        </div>
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className={"form-group col-md-6"}>
                                        <label>
                                            Description
                                        </label>
                                        <div >
                                            <input
                                                ref="description"
                                                autoComplete="off"
                                                type="text"
                                                placeholder="e.g Easipth"
                                                className="form-control"
                                                required
                                                defaultValue={this.state.description}
                                                onBlur={this.validationCheck} />

                                        </div>
                                    </div>
                                </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label>
                                        Province
                                    </label>
                                    <div >
                                        <select
                                            ref="province"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="e.g Easipth"
                                            className="form-control"
                                            required={true}
                                            defaultValue={this.state.province}
                                            onBlur={this.validationCheck} >
                                            <option value={""}> --Select {"Province"}--</option>
                                            {
                                                OptionProvince.map((row) => {
                                                    return ( <option value={row.value}>{row.label}</option>)
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label>
                                        Municipality
                                    </label>
                                    <div >
                                        <select
                                            ref="municipality"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="e.g Cape town"
                                            className="form-control"
                                            required={true}
                                            defaultValue={this.state.municipality}
                                            onBlur={this.validationCheck} >
                                            <option value={""}> --Select {"Municipality"}--</option>
                                            {
                                                OptionMunicipality.map((row) => {
                                                    return ( <option value={row.value}>{row.label}</option>)
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                            </div>



                                {/*<div className="col-md-12">

                                    <div className="col-md-12">


                                            {this._render2ColInput("Contract Name", "name", "text", "", true,
                                                "Description", "description", "text", "", true, _this)}

                                        {this._render2ColInput("Province", "province", "select", OptionProvince, true,
                                            "Municipality", "municipality", "select", OptionMunicipality, true, _this)}

                                    </div>

                                </div>*/}

                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const OptionProvince =[
    {label:"Western Cape",val:"western cape"},
    {label:"Guateng",val:"guateng"},
];

const OptionMunicipality =[
    {label:"City of cape town",val:"cape town"},
    {label:"Stellembosch",val:"stellembosch"},
];

/**
 * Created by dev1 on 2017/01/13.
 */
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../component/common/handle-input';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import React from 'react';
import TokenService from '../api/tokeninfo';
import Footer from "../component/common/footer";
import eConfig from '../epwp_config.json';
import '../asset/styles/dashboard-page.css';
import TopMenu from '../component/common/top-nav-new';
import ImageLady from '../asset/images/landing-page/assitance.png';

const serverEpwp = eConfig.serverEpwp;



var LineChart = require("react-chartjs").Bar;




class HomeContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            LoginUser: {},
            company: "",
            username: "",
            role: "",
            NewUsers: [],
            Organisation: {},
            contractName: "",
            Contracts: [],
        };


        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);

        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this.fetchUsersToActivate = this.fetchUsersToActivate.bind(this);
        this.renderNewUser = this.renderNewUser.bind(this);
        this.activateUser = this.activateUser.bind(this);
        this.fetchOrganisation = this.fetchOrganisation.bind(this);
        this.initChart = this.initChart.bind(this);
        this.fetchContracts = this.fetchContracts.bind(this);
        this.submitDashboardContract = this.submitDashboardContract.bind(this);
    }

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }

    submitDashboardContract(e) {
        e.preventDefault();
        if (this.state.contractName === "") {
            alert("Plase select your contract")
            return
        }
        window.localStorage.setItem("SELECTED-CONTACT", this.state.contractName);
        window.location.href = "#/dashboard-contract";
    }


    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchUsersToActivate();
        this.fetchOrganisation();
        this.initChart();
        this.fetchContracts();
    }

    fetchContracts() {
        let company = this.state.company;
        let endpoint = "/api/contracts/contract/information/list-company";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "Contracts", _this);
        console.log("--)Contracts(--> ", this.Contracts);
    }


    initChart() {


    }

    activateUser(row) {

        let text = window.confirm("Are you sure that you want to active this user [" + row.Fullname + "]");
        if (!text) {
            return;
        }

        let endpoint = "/api/user/activate";
        let url = serverEpwp + endpoint;
        let hub = row;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("User activated");
                console.log("activateUser response > ", responseData);
                window.location.reload();
            });


    }

    fetchOrganisation() {
        let company = this.state.company;
        let endpoint = "/api/organisation/find/owner";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {

                if(this.state.role !=="user"){
                    if (!responseData.STATUS) {
                        alert("We detect that you don't have any organisation setup, by clicking ok we will direct you to setup your organisation ");
                        window.location.href = "#/setting-organisation";
                    }
                }

                var tmp = this.state;
                tmp.Organisation = responseData.DATA;
                this.setState(tmp);
                console.log("+++++>fetchOrganisation response ", responseData);
            });

    }

    fetchUsersToActivate() {
        let company = this.state.company;
        let endpoint = "/api/user/verify";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.company = company;
        let _this = this;
        this.loadList(url, hub, "NewUsers", _this);
        //console.log("--)fetchUsersToActivate(--> ", this.state.NewUsers);
    }


    repTotEmployees() {
        let tot = 0;
        /*for(var i in this.props.Employees){
            if(this.props.Employees[i].Orgcode===this.state.OrgCode){
                tot++;
            }
        }*/
        return tot;
    }

    repTotOnlineEmployee() {
        let tot = 0;
        return tot;
    }

    repTotOfflineEmployee() {
        let tot = 0;
        return tot;
    }

    renderNewUser() {
        console.log("LoginInfo > ", this.state);

        if (this.state.role !== "super") {
            return "";
        }

        if (this.state.NewUsers.length === 0) {
            return "";
        }

        return (
            <div className="container">
                <br/>
                <h3 style={{color: "red"}}>List of New User to activate (super role only)</h3>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Company</th>
                        <th>Fullname</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Date</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.NewUsers.map((row) => {
                        return (
                            <tr>
                                <td>{row.Company}</td>
                                <td>{row.Fullname}</td>
                                <td>{row.Email}</td>
                                <td>{row.Phone}</td>
                                <td>{row.OrgDateTime}</td>
                                <td><a className="btn btn-default clickMe" onClick={() => {
                                    this.activateUser(row)
                                }}>Activate</a></td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {

        var chartData = {
            labels: ["January", "February", "March", "April", "May", "June", "July"],
            datasets: [
                {
                    label: "My First dataset",
                    fillColor: "rgba(220,220,220,0.5)",
                    strokeColor: "rgba(220,220,220,0.8)",
                    highlightFill: "rgba(220,220,220,0.75)",
                    highlightStroke: "rgba(220,220,220,1)",
                    data: [65, 59, 80, 81, 56, 55, 40]
                },
                {
                    label: "My Second dataset",
                    fillColor: "rgba(151,187,205,0.5)",
                    strokeColor: "rgba(151,187,205,0.8)",
                    highlightFill: "rgba(151,187,205,0.75)",
                    highlightStroke: "rgba(151,187,205,1)",
                    data: [28, 48, 40, 19, 86, 27, 90]
                }
            ]
        };

        let chartOptions = {
            //Boolean - Whether the scale should start at zero, or an order of magnitude down from the lowest value
            scaleBeginAtZero: true,

            //Boolean - Whether grid lines are shown across the chart
            scaleShowGridLines: true,

            //String - Colour of the grid lines
            scaleGridLineColor: "rgba(0,0,0,.05)",

            //Number - Width of the grid lines
            scaleGridLineWidth: 1,

            //Boolean - Whether to show horizontal lines (except X axis)
            scaleShowHorizontalLines: true,

            //Boolean - Whether to show vertical lines (except Y axis)
            scaleShowVerticalLines: true,

            //Boolean - If there is a stroke on each bar
            barShowStroke: true,

            //Number - Pixel width of the bar stroke
            barStrokeWidth: 2,

            //Number - Spacing between each of the X value sets
            barValueSpacing: 5,

            //Number - Spacing between data sets within X values
            barDatasetSpacing: 1,
        }
        return (
            <div>

                <TopMenu page="Dashboard" menu={this.props.location.pathname}/>

                <div className="myDashboardContent01 row scroll-y">

                    <div className="container myDashboardContent02">


                        {this.renderNewUser()}

                        <div className="row">
                            <div className="col col-lg-12">
                                <form className="form form-inline" onSubmit={this.submitDashboardContract}>

                                    <select type="text" className="col-lg-6 myCombo2" name="contractName"
                                            onChange={this._handleInput}>
                                        <option>-- Select your contract ({this.state.Contracts.length})---</option>
                                        {this.state.Contracts.map((row) => {
                                            return (
                                                <option value={row.Name}>{row.Name}</option>
                                            )
                                        })}
                                    </select>
                                    <button type="submit" className="btn btn-default form-control ">Go!</button>

                                </form>
                                <br/>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-6">
                                <div className="col col-lg-6 myColorB">

                                    <div className="row bockContent">
                                        <span className="pTitleHeader">Total Employment Opportunities </span> <span
                                        className="pTitleNumber pull-right circle">641</span>
                                    </div>
                                    <div className="row pContentBox">
                                        <div className="col col-lg-6">
                                            <p style={{textAlign: "center"}}><i className="fa fa-male"></i></p>
                                            <p style={{fontSize: "14", color: "white", textAlign: "center"}}>Male
                                                89%</p>
                                            <p style={{fontSize: "34", color: "white", textAlign: "center"}}>335</p>
                                        </div>
                                        <div className="col col-lg-6 makeBorderShort">
                                            <p style={{textAlign: "center"}}><i className="fa fa-female"></i></p>
                                            <p style={{fontSize: "14", color: "white", textAlign: "center"}}>Femal
                                                11%</p>
                                            <p style={{fontSize: "34", color: "white", textAlign: "center"}}>39</p>
                                        </div>
                                    </div>
                                    <div className="col col-lg-6 pBorderConter1">
                                        &nbsp;
                                    </div>

                                </div>
                                <div className="col col-lg-6 myColorB">
                                    <div className="row">
                                        <div className="bockContent">
                                            <span className="pTitleHeader">Total Active Employment </span> <span
                                            className="pTitleNumber pull-right circle">641</span>
                                        </div>
                                    </div>

                                    <div className="row pContentBox">
                                        <div className="col col-lg-6">
                                            <p style={{textAlign: "center"}}><i className="fa fa-users"></i></p>
                                            <p style={{fontSize: "14", color: "white", textAlign: "center"}}>
                                                Workers </p>
                                            <p style={{fontSize: "34", color: "white", textAlign: "center"}}>339</p>
                                        </div>
                                        <div className="col col-lg-6 makeBorderShort">
                                            <p style={{textAlign: "center"}}><i className="fa fa-users"></i></p>
                                            <p style={{fontSize: "14", color: "white", textAlign: "center"}}>
                                                Leaders</p>
                                            <p style={{fontSize: "34", color: "white", textAlign: "center"}}>39</p>
                                        </div>
                                    </div>

                                    <div className="col col-lg-6 pBorderConter1">
                                        &nbsp;
                                    </div>

                                </div>
                            </div>


                            <div className="col col-lg-6">

                                <div className="row">

                                    <div className="col-lg-5 blockContentColoum2">
                                        <div className="row">
                                            <div className="col-lg-12 bccHeader container">
                                                <span className="">Fortnight-1 March</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table">
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="alignMeLeft">Payees</td>
                                                        <td className="alignMeRight">259</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Gross Pay</td>
                                                        <td className="alignMeRight">R 350,300.20</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Net Pay</td>
                                                        <td className="alignMeRight">R 554,786.36</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 blockContentColoum2">
                                        <div className="row">
                                            <div className="col-lg-12 bccHeader container">
                                                <span className="">Fortnight-2 March</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table">
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="alignMeLeft">Payees</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorRed"> 259</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Gross Pay</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorYellow">R 350,300.20</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Net Pay</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorGreen">R 554,786.36</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-lg-5 blockContentColoum2">
                                        <div className="row">
                                            <div className="col-lg-12 bccHeader container">
                                                <span className="">Fortnight-3 March</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table">
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="alignMeLeft">Payees</td>
                                                        <td className="alignMeRight">259</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Gross Pay</td>
                                                        <td className="alignMeRight">R 350,300.20</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Net Pay</td>
                                                        <td className="alignMeRight">R 554,786.36</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 blockContentColoum2">
                                        <div className="row">
                                            <div className="col-lg-12 bccHeader container">
                                                <span className="">Fortnight-4 March</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <table className="table">
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="alignMeLeft">Payees</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorRed">259</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Gross Pay</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorGreen">R 350,300.20</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Net Pay</td>
                                                        <td className="alignMeRight"><span
                                                            className="badge badgeColorYellow">R 554,786.36</span></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>

                        <div className="row">
                            <div className="col col-lg-12">
                                <hr/>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col col-lg-6">
                                <div className="row">
                                    <div className="col-lg-12 bccHeader bccChartHearder container">
                                        <span className="">My chart</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 bccChartContent">
                                        <LineChart data={chartData} options={chartOptions}/>
                                    </div>
                                </div>
                            </div>

                            <div className="col col-lg-6">

                                <div className="row ">

                                    <div className="col-lg-10 blockContentColoum2 ">
                                        <div className="row">
                                            <div className="col-lg-12  container bccGlobal ">
                                                <span className="">Global Wage Statistica-1</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 " style={{minHeight: "300px"}}>
                                                <table className="table">
                                                    <thead>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="alignMeLeft">Employee with no Medical aid</td>
                                                        <td className="alignMeRight"><span
                                                            className="colorMeBrwon">12</span>&nbsp; <i
                                                            className="fa fa-hand-o-down glbRed"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Employee with no Training</td>
                                                        <td className="alignMeRight"><span
                                                            className="colorMeBrwon">108</span>&nbsp; <i
                                                            className="fa fa-hand-o-right glbYellow"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="alignMeLeft">Employee with no Uniform</td>
                                                        <td className="alignMeRight"><span
                                                            className="colorMeBrwon">13</span>&nbsp; <i
                                                            className="fa fa-hand-o-up glbGreen"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="alignMeLeft">Pay Act</td>
                                                        <td className="alignMeRight"><span
                                                            className="colorMeBrwon">300</span>&nbsp; <i
                                                            className="fa fa-hand-o-down glbRed"></i></td>
                                                    </tr>

                                                    <tr>
                                                        <td className="alignMeLeft">Procard Employee</td>
                                                        <td className="alignMeRight"><span
                                                            className="colorMeBrwon">13</span>&nbsp; <i
                                                            className="fa fa-hand-o-right glbYellow"></i></td>
                                                    </tr>


                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>

                </div>


            </div>
        );
    }
}


export default HomeContainer;

'use strict';

import React, { Component } from 'react';
import {
    handleInput,
    renderCheckbox,
    renderInput,
    renderTextArea,
    renderSelect,
    render2ColInput,
    loadList
} from '../common/handle-input';

export default class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startdate:props.getStore().startdate,
            enddate:props.getStore().enddate,
        };

        this._handleInput = this._handleInput.bind(this);
        this._render2ColInput = render2ColInput.bind(this);
        this.loadList = loadList.bind(this);
        this._renderCheckbox = renderCheckbox.bind(this);
        this._renderInput = renderInput.bind(this);
        this._renderTextArea = renderTextArea.bind(this);
        this._renderSelect = renderSelect.bind(this);

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    componentDidMount() {}

    componentWillUnmount() {}

    _handleInput(e) {
        let _this = this;
        handleInput(e, _this);
    }


    // not required as this component has no forms or user entry
    // isValidated() {}

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === true })) {
            if (this.props.getStore().startdate !== userInput.startdate ||
                this.props.getStore().enddate !== userInput.enddate ) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        }
        else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }

        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand)
            return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        this.props.updateStore(userInput);
    }

    _validateData(data) {
        return  {
            startdateVal:(data.startdate !==''),
            enddateVal:(data.enddate !==''),
            /*genderVal: (data.gender !== 0), // required: anything besides N/A
            emailVal: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email),*/ // required: regex w3c uses in html5
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            startdateValMsg: val.startdateVal ? '' : 'A valid start date of contract  is required',
            enddateValMsg: val.enddateVal ? '' : 'A valid description of contract  is required',
            /*emailValMsg: val.emailVal ? '' : 'A valid email is required'*/
        }
        return errMsgs;
    }

    _grabUserInput() {
        return {
            startdate: this.refs.startdate.value,
            enddate: this.refs.enddate.value,
        };
    }


    defaultDateTime=()=>{
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy+'-'+mm+'-'+dd
    }
    getStartDate=()=>{
        console.log(":> getStartDate > ",this.state.startDate)
        if(this.state.startDate !==""){
            return this.state.startDate
        }
        return this.defaultDateTime()
    }
    getEndDate=()=>{
        console.log(":> getEndDate > ",this.state.endDate," > ",(this.state.endDate !==""))
        if(this.state.endDate !==""){
            return this.state.endDate
        }
        return this.defaultDateTime()
    }

    render() {
        let _this = this;
        let notValidClasses = {};

        if (typeof this.state.startdateVal === 'undefined' || this.state.startdateVVal) {
            notValidClasses.startdateVCls = 'no-error col-md-8';
        }
        else {
            notValidClasses.startdateVCls = 'has-error col-md-8';
            notValidClasses.startdateVValGrpCls = 'val-err-tooltip';
        }

        if (typeof this.state.enddateVal == 'undefined' || this.state.enddateVal) {
            notValidClasses.enddateCls = 'no-error col-md-8';
        }
        else {
            notValidClasses.enddateCls = 'has-error col-md-8';
            notValidClasses.enddateValGrpCls = 'val-err-tooltip';
        }



        return (
            <div className="step step2">
                <div className="row">
                    <form id="Form" className="form-horizontal">
                        <div className="form-group">
                            <label className="col-md-12 control-label">
                                <h1 className={"companyList"}>Step 2: Period</h1>
                            </label>
                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label className="">
                                        Start Date
                                    </label>
                                    <div>
                                        <input
                                            ref="startdate"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="e.g 2018-02-19"
                                            className="form-control"
                                            required
                                            defaultValue={this.getStartDate()}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.startdateValGrpCls}>{this.state.startdateValMsg}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className={"form-group col-md-6"}>
                                    <label>
                                        End Date
                                    </label>
                                    <div >
                                        <input
                                            ref="enddate"
                                            autoComplete="off"
                                            type="text"
                                            placeholder="e.g 2018-12-19"
                                            className="form-control"
                                            required
                                            defaultValue={this.getEndDate()}
                                            onBlur={this.validationCheck} />
                                        <div className={notValidClasses.enddateValGrpCls}>{this.state.enddateValMsg}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const OptionProvince =[
    {label:"Western Cape",val:"western cape"},
    {label:"Guateng",val:"guateng"},
];

const OptionMunicipality =[
    {label:"City of cape town",val:"cape town"},
    {label:"Stellembosch",val:"stellembosch"},
];

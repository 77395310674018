import React from 'react';
import { Button ,Modal} from 'react-bootstrap';
import TopMenu from '../component/common/top-nav-new';
import '../asset/styles/global-main.css';

import TokenService from '../api/tokeninfo';
import eConfig from '../epwp_config.json';

const serverEpwp = eConfig.serverEpwp;

class SettingUsersPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            LoginUser: {},
            company: "",
            username: "",
            role: "",
            NewUsers: [],
            User: {
                Company :"",
                CompanyRef :"",
                Name:"",
                Surname:"",
                Fullname:"",
                Email:"",
                Phone:"",
                Role:"user",
                Password:"",
                ConfirmPassword:"",

            },
            Users:[],
            openclose:false,
        };

        this.fetchUsers = this.fetchUsers.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.submitUser = this.submitUser.bind(this);
        this.renderNewUserForm = this.renderNewUserForm.bind(this);
        this.renderUserList = this.renderUserList.bind(this);

        this.editUser = this.editUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.addUserClick = this.addUserClick.bind(this);
        this.openUser  = this.openUser.bind(this);
        this.closeUser  = this.closeUser.bind(this);

        this.renderPopupObject = this.renderPopupObject.bind(this);




    }

    componentWillMount() {
        var u = TokenService.GetTokenObject();
        let tmp = this.state;
        tmp.company = u.Company;
        tmp.username = u.Email;
        tmp.role = u.Role;
        tmp.LoginUser = u;
        tmp.User.Company = tmp.company;
        tmp.User.CompanyRef = tmp.company;
        this.setState(tmp);


        /**
         * Load companies from api
         */
        this.fetchUsers();
    }

    openUser(){
        let tmp = this.state;
        tmp.openclose = true;
        this.setState(tmp);
    }
    closeUser(){
        let tmp = this.state;
        tmp.openclose = false;
        this.setState(tmp);
    }

    fetchUsers() {
        let company = this.state.company;
        let endpoint = "/api/user/role/list/"+this.state.company+"/user";
        let url = serverEpwp + endpoint;
        let hub = {};
        hub.ownerid = this.state.username;
        hub.company = company;

        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                var tmp = this.state;
                tmp.Users = responseData.DATA;
                this.setState(tmp);
                console.log("+++++>fetchUsers response ", responseData);
            });

    }


    submitUser(e) {
        e.preventDefault();
        let hub = this.state.User;
        hub.Fullname = hub.Name+" "+hub.Surname;
        let text = window.confirm("Are you sure that you want to submit this form?");
        if (!text) {
            return
        }

        let endpoint = "/api/user/register";
        let url = serverEpwp + endpoint;



        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(hub)
        })
            .then((response) => response.json())
            .then((responseData) => {
                alert("Thank you record " + responseData.STATUS);

                console.log("+++++>submitOrganisation response ", responseData);
                this.closeUser();
                this.fetchUsers();
            });


    }

    handleInput(e) {
        let key = e.target.name;
        let val = e.target.value;
        if (key === "PhysicalSameAsPostal") {
            if (e.target.checked) {
                val = "yes";
            } else {
                val = "no";
            }
        }
        let tmp = this.state;
        tmp.User[key] = val;
        this.setState(tmp);
    }

    editUser(row){
        let tmp  =this.state;
        tmp.User = row;
        this.setState(tmp);
        this.addUserClick();
    }
    deleteUser(row){

        let text = window.confirm("Are you sure that you want to delete this user ? ["+row.Fullname+"]");
        if (!text) {
            return
        }
    }

    addUserClick(){
        this.openUser();
    }

    renderPopupObject() {
        return (
            <div className="container ">
            <Modal show={this.state.openclose} onHide={this.closeUser} className="">
                <Modal.Header closeButton>
                    <Modal.Title>Manage User</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-lg-12">
                            {this.renderNewUserForm()}
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeUser}>Close</Button>
                </Modal.Footer>
            </Modal>
            </div>
        )
    }

    renderNewUserForm() {
        let org  = this.state.User;
        return (
            <div className="container myModalPosition">
            <form onSubmit={this.submitUser}>
                <h3>Detail</h3>
                <table className="table">
                    <tr>
                        <td className="cOrg12">First Name</td>
                        <td>
                            <input name="Name" onChange={this.handleInput} value={org.Name}
                                   className="form-control" placeholder="First name..." required/>
                        </td>
                    </tr>
                    <tr>
                        <td className="cOrg12">Last Name</td>
                        <td className="">
                            <input name="Surname" onChange={this.handleInput}
                                   value={org.Surname} className="form-control"
                                   placeholder="Last name..." required/>
                        </td>
                    </tr>

                    <tr>
                        <td className="cOrg12">Phone Number</td>
                        <td className="">
                            <input name="Phone" onChange={this.handleInput}
                                   value={org.Phone} className="form-control"
                                   placeholder="Phone number..." required/>
                        </td>
                    </tr>

                    <tr>
                        <td className="cOrg12"><b>Email</b></td>
                        <td className="">
                            <input name="Email" type="email" onChange={this.handleInput} value={org.Email}
                                   className="form-control" placeholder="Email ..." required/>
                        </td>
                    </tr>

                    <tr>
                        <td className="cOrg12"><b>Password</b></td>
                        <td className="">
                            Will be send to the user email address provided above
                        </td>
                    </tr>


                </table>

                <hr/>

                <p className="mybuttonOrg">

                    <button type="submit" className="btn btn-success">Save</button>
                    &nbsp;&nbsp;
                    <button type="button"
                            className="btn btn-default"
                            onClick={()=>{this.closeUser()}}>Cancel</button>
                </p>

            </form>
            </div>
        )
    }

    renderUserList(){
        let ls = this.state.Users;
        if(ls.length ===0){
            return(
                <p className="alert alert-warning">No data find</p>
            )
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Fullname</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row)=>{
                    return(

                        <tr>
                            <td>{row.Fullname}</td>
                            <td>{row.Email}</td>
                            <td>{row.Phone}</td>
                            <td>{row.Phone}</td>
                            <td>{row.Status}</td>
                            <td>{row.OrgDateTime}</td>
                            <td>
                                <a className="clickMe" onClick={()=>{this.editUser(row)}} >Edit</a>&nbsp;|&nbsp;
                                <a className="clickMe" onClick={()=>{this.deleteUser(row)}}>Delete</a>
                            </td>
                        </tr>

                    )
                })}
                </tbody>
            </table>
        )
    }

    render() {


        return (
            <div className="">
                <TopMenu page="Users" menu={this.props.location.pathname}/>

                <div className="pageContentWhite">
                    <div className="container borderShadon">

                        <h2>
                            User List ({this.state.Users.length})
                            <button
                                className="btn btn-primary pull-right"
                                onClick={()=>{this.addUserClick()}}
                            >ADD</button></h2>

                        {this.renderUserList()}

                    </div>

                    {this.renderPopupObject()}

                </div>


            </div>

        )
    }
}


export default SettingUsersPage;